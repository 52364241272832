import { Box, IconButton, MenuItem, Select } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { provider, useInstances } from 'react-ioc';
import { useLocation, useNavigate } from 'react-router-dom';
import { Agency } from '../../app/api/mapper';
import DownArrow from '../../assets/down-arrow';
import { AgencyModel } from '../../assets/models/agencies/Agency.model';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { AgencyAdminStore } from '../../Stores/AgencyAdmin.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { MandateStore } from '../../Stores/Mandate.store';
import { UserConnectStore } from '../../Stores/UserConnect.store';
import RichTooltip, { useRichTooltipStyles } from '../../Style/MuiStyles/RichTooltip';
import { InputSelectBgBlack } from '../../Style/MuiStyles/Select/SelectBgBlack';
import { COLORS } from '../../Style/Style';
import BreadcrumbTopMenu from './BreadcurmbToMenu/BreadcrumbTopMenu';
import { useTopMenuStyles } from './TopMenuStyles';

export interface ITopMenuComponentProps {
  setMobileOpen: () => void;
}

type LocalStore = [AgencyAdminStore, AgenciesStore, UserConnectStore, MandateStore, AgencyInvoiceStore];

const TopMenuComponent = provider()(observer((props: ITopMenuComponentProps) => {
  const [
    agencyAdminStore,
    agenciesStore,
    userConnectStore,
    mandateStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(AgencyAdminStore, AgenciesStore, UserConnectStore, MandateStore, AgencyInvoiceStore);
  const [anchorEl, setAnchorEl] = useState<Element>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const richTooltipClasses = useRichTooltipStyles();
  const topMenuClasses = useTopMenuStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = async () => {
    await userConnectStore.userLogout();
    agencyAdminStore.resetAgencyAdmin();
    agenciesStore.reset();
    mandateStore.reset();
    agencyInvoiceStore.reset();
    navigate('/login');
  };

  const handleSelectAgency = (objSelected: string) => {
    const selectedAgency: AgencyModel | undefined = agenciesStore.agenciesList.find((agency: Agency) => agency.agencyName === objSelected);

    if (!selectedAgency) {
      return;
    }
    agencyInvoiceStore.reset();
    mandateStore.reset();
    agenciesStore.setCurrentAgency(selectedAgency);
    navigate('/dashboard');
  };

  return (
    <div className={topMenuClasses.root}>
      <div className={topMenuClasses.blockLeft}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.setMobileOpen}
          className={topMenuClasses.menuButton}
        >
          <svg viewBox="0 0 100 80" width="40" height="40">
            <rect width="100" height="20" fill={COLORS.primary}/>
            <rect y="30" width="100" height="20" fill={COLORS.primary}/>
            <rect y="60" width="100" height="20" fill={COLORS.primary}/>
          </svg>
        </IconButton>
        <div className={topMenuClasses.agency}>
          {agenciesStore.currentAgency && !!agenciesStore.agenciesList.length &&
            <Select
              value={agenciesStore.currentAgency?.agencyName}
              name="agency-select"
              style={{ width: 200, display: 'flex' }}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleSelectAgency(evt.target.value)}
              labelId="label"
              id="select"
              input={<InputSelectBgBlack title={agenciesStore.currentAgency?.agencyName}/>}
              IconComponent={(props) => <div
                style={{ marginBottom: '18px' }}>{DownArrow({ svgColor: COLORS.white, ...props })}</div>}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {agenciesStore.agenciesList?.map((agencyObj: Agency, key: number) => <MenuItem
                value={agencyObj.agencyName}
                key={key}>{agencyObj.agencyName}</MenuItem>)}
            </Select>
          }
        </div>
        {BreadcrumbTopMenu({ navigate: navigate, pathname: location.pathname })}
      </div>
      <div className={topMenuClasses.blockRight}>
        <div className={topMenuClasses.initial}>
          {agencyAdminStore.agencyAdminSelected
            ? `${agencyAdminStore.agencyAdminSelected?.firstName[0]}${agencyAdminStore.agencyAdminSelected?.lastName[0]}`
            : ''}
        </div>
        <div className={topMenuClasses.blockName}>
          <div className={topMenuClasses.name}>
            {`${agencyAdminStore.agencyAdminSelected?.firstName ?? ''} ${agencyAdminStore.agencyAdminSelected?.lastName.toLocaleUpperCase() ?? ''}`}
          </div>
          <div className={topMenuClasses.role}>
            <span>Administrateur</span>
          </div>
        </div>
        <div className={topMenuClasses.menu}>
          <RichTooltip
            content={<>
              <Box className={richTooltipClasses.content}
                   onClick={handleClickLogout}>
                <button autoFocus style={{ all: 'unset' }}>
                  Déconnexion
                </button>
              </Box>
            </>
            }
            open={Boolean(anchorEl)}
            placement="bottom-end"
            onClose={handleClose}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <button type="button" aria-roledescription="profile menu" style={{ all: 'unset' }} onClick={handleClick}>
                <DownArrow svgColor={COLORS.primary} className={topMenuClasses.icon} alt="profil"/>
              </button>
            </div>
          </RichTooltip>
        </div>
      </div>
    </div>
  );
}));

export default TopMenuComponent;
