import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import Button from '../../../../Style/MuiStyles/Button';
import { useMillesimeInformationStyles } from '../MillesimeInformationStyles';
import { COLORS } from '../../../../Style/Style';
import { RestitutionMethod, RightsReconductionType } from '../../../../assets/models/agencies/Agency.model';
import { useNavigate } from 'react-router';

interface RightsReconductionTypesExplorerPanelProps {
  endingMillesimeYear: number;
  nextMillesimeYear: number;
  restitutionMethod: RestitutionMethod;
  restitutionIban?: string;
  allowToUpdate: boolean;
  isUpdating: boolean;
  onSubmitButtonClicked: (type: RightsReconductionType) => void;
}

const RightsReconductionTypesExplorerPanel: FunctionComponent<RightsReconductionTypesExplorerPanelProps> = (props) => {
  const {
    endingMillesimeYear,
    nextMillesimeYear,
    restitutionMethod,
    restitutionIban,
    allowToUpdate,
    isUpdating,
    onSubmitButtonClicked,
  } = props;

  const classes = useMillesimeInformationStyles();
  const { t } = useTranslation('millesime');
  const navigate = useNavigate();

  const [selectedRightsReconductionType, setSelectedRightsReconductionType] = useState<RightsReconductionType>('DROP');

  const handleOnSelectedOptionChange = (event) => {
    setSelectedRightsReconductionType(event.target.value);
  };

  const handleOnSubmitRightsReconductionType = () => {
    onSubmitButtonClicked(selectedRightsReconductionType);
  };

  const navigateToPaymentParameters = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate('/agency/payment');
  }, []);

  return (
    <div className={classes.panelContainer}>
      <div className={classes.panelHeader}>
        <Typography>
          {t('rightsReconductionTypesExplorerPanel.title', { endingMillesimeYear })}
        </Typography>
      </div>
      {allowToUpdate
      ? <>
          <div className={classes.panelBody}>
            <FormControl>
              <RadioGroup
                aria-label="rightsReconductionType"
                name="rights-reconduction-type"
                value={selectedRightsReconductionType}
                onChange={handleOnSelectedOptionChange}
              >
                <div className={classes.radioItem}>
                  <FormControlLabel
                    value="DROP"
                    control={<Radio style={{ color: COLORS.textPrimary }}/>}
                    label={t('rightsReconductionTypesExplorerPanel.formControlLabel.DROP')}
                  />
                  <FormHelperText style={{ paddingLeft: '33px', fontSize: '14px', marginTop: '-6px' }}>
                    {t(`rightsReconductionTypesExplorerPanel.helperText.DROP.${restitutionMethod}`, { restitutionIban })}
                  </FormHelperText>
                  <FormHelperText style={{ paddingLeft: '33px', fontSize: '14px' }}>
                    {t('rightsReconductionTypesExplorerPanel.subHelperText.DROP')}
                    {' '}
                    <Link href="/agency/payment" underline="always" style={{ color: COLORS.textPrimary }}
                          onClick={navigateToPaymentParameters}>
                      {t('rightsReconductionTypesExplorerPanel.helperLinkLabel.DROP')}
                    </Link>
                  </FormHelperText>
                </div>

                <div className={classes.radioItem}>
                  <FormControlLabel
                    value="KEEP"
                    control={<Radio style={{ color: COLORS.textPrimary }}/>}
                    label={t('rightsReconductionTypesExplorerPanel.formControlLabel.KEEP')}
                  />
                  <FormHelperText style={{ paddingLeft: '33px', fontSize: '14px', marginTop: '-6px' }}>
                    {t('rightsReconductionTypesExplorerPanel.helperText.KEEP', { nextMillesimeYear })}
                  </FormHelperText>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
          <div className={classes.panelFooter}>
            <Button
              onClick={handleOnSubmitRightsReconductionType}
              type="button"
              disabled={isUpdating}
              isFetching={isUpdating}
              variant="contained"
              color="primary"
            >
              {t('rightsReconductionTypesExplorerPanel.submitButtonLabel')}
            </Button>
          </div>
        </>
        : <div className={classes.panelBody}>
          <div className={classes.defaultReconductionContent}>
            <p className={classes.infoMessage}>
              {t('rightsReconductionTypesExplorerPanel.defaultReconductionTypeText')}
            </p>
          </div>
        </div>
      }
    </div>
  );
};

export default RightsReconductionTypesExplorerPanel;
