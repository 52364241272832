import { makeStyles } from '@material-ui/core';
import { COLORS, FONT_WEIGHT } from '../../Style/Style';

export const addBeneficiariesByUploadStyles = makeStyles(() => ({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px'
  },
  imgClose: {
    cursor: 'pointer'
  },
  input: {
    marginBottom: '25px'
  },
  select: {
    marginBottom: '25px',
    placeContent: 'center'
  },
  title: {
    margin: '0',
    letterSpacing: '2px',
    padding: '10px 50px 40px 0px',
    color: COLORS.textPrimary
  },
  tab: {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.semiBold
  },
  appBar: {
    borderBottom: `1px solid ${COLORS.borderBlock}`
  },
  dropzoneBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  downloadFile: {
    color: COLORS.primary,
    padding: '30px 0'
  },
  uploadButtonBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '20px',
  },
  errorContainer: {
    margin: '0 auto',
    marginTop: '1rem',
    width: '683px',
    display: 'flex',
    flexDirection: 'column',
  },
  uploadStartedWrapper: {
    padding: '2rem 0',
  },
  caption: {
    marginBottom: '1rem'
  },
  uploadStartedIconWrapper: {
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));
