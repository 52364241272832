import { makeAutoObservable, runInAction } from 'mobx';
import { getFlagValueFromConfig } from '../assets/utils/configuration/featureFlags';
import { getFeatureFlags } from '../Services/appConfig.service';
import { FeatureFlagsConfig, FeatureFlagsConfigValue } from '../assets/models/configuration/featureFlags.model';

const REFRESH_RATE: number = 5 * 60 * 1000; // 5 minutes
const APP_RELOAD_MAX_RETRIES: number = 3;
const APP_RELOAD_RETRIES_KEY: string = 'APP_RELOAD_RETRIES_COUNT';

export class AppConfigStore {
  private featureFlags: FeatureFlagsConfig = {};
  private lastUpdate: number = 0;
  private intervalId: number | null = null;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    void this.fetchLastConfig();
  }

  public getFlagValue<T extends FeatureFlagsConfigValue = FeatureFlagsConfigValue>(flagName: string, defaultValue: boolean = false): T {
    return getFlagValueFromConfig<T>(this.featureFlags, flagName, defaultValue);
  }

  public getLastUpdateTime(): number {
    return this.lastUpdate;
  }

  public isReady(): boolean {
    return this.lastUpdate > 0;
  }

  public startPolling(interval: number = REFRESH_RATE): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      void this.fetchLastConfig();
    }, interval) as unknown as number;
  }

  public stopPolling(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  public getReloadRetriesCount(): number {
    const appReloadRetriesCount = Number(localStorage.getItem(APP_RELOAD_RETRIES_KEY));

    if (isNaN(appReloadRetriesCount)) {
      return 0;
    }

    return appReloadRetriesCount;
  }

  public shouldRetryReloadNow(): boolean {
    const appReloadRetriesCount = this.getReloadRetriesCount();

    return appReloadRetriesCount < APP_RELOAD_MAX_RETRIES;
  }

  public incrementReloadRetriesCount(): void {
    const appReloadRetriesCount = this.getReloadRetriesCount();

    localStorage.setItem(APP_RELOAD_RETRIES_KEY, (appReloadRetriesCount + 1).toString());
  }

  public resetReloadRetriesCount(): void {
    localStorage.setItem(APP_RELOAD_RETRIES_KEY, '0');
  }


  private async fetchLastConfig() {
    const requestTimestamp = Date.now();
    const featureFlags = await getFeatureFlags();
    runInAction(() => {
      this.featureFlags = featureFlags;
      this.lastUpdate = requestTimestamp;
    });
  }
}
