import { makeStyles } from '@material-ui/core';
import { COLORS, FONT } from '../../Style/Style';

export const SnackBarErrorStyles = makeStyles(() => ({
  snackbar: {
    background: `linear-gradient(to right, ${COLORS.error} 4%, white 4%)`,
    borderRadius: '20px',
  },
  snackbarMessage: {
    width: '370px',
    backgroundColor: 'white',
    color: COLORS.textPrimary,
    padding: '1.5rem',
    paddingTop: 0,
    fontFamily: FONT.primary,
  },
  close: {
    display: 'flex',
    justifyContent: 'end',
  },
  closeArrow: {
    width: 10,
  },
}));