import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { useMillesimeInformationStyles } from './MillesimeInformationStyles';
import { AgenciesStore, AgencyDetailsStore } from '../../../Stores/Agencies.store';
import { AgencyModel, RightsReconductionType } from '../../../assets/models/agencies/Agency.model';
import RightsReconductionTypesExplorerPanel from './RightsReconductionTypesPanel/RightsReconductionTypesExplorerPanel';
import DefinedRightsReconductionTypePanel from './RightsReconductionTypesPanel/DefinedRightsReconductionTypePanel';
import { MillesimesStore } from '../../../Stores/Millesimes.store';
import EndOfMillesimeReports from './EndOfMillesimeReports/EndOfMillesimeReports';
import { MillesimeReportsViewStore } from '../../../Stores/viewStore/MillesimeReportsView.store';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

type LocalStore = [AgenciesStore, AgencyDetailsStore, MillesimesStore];

const MillesimeInformationComponent: FunctionComponent = observer(() => {
  const [
    agenciesStore,
    agencyDetailsStore,
    millesimesStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, AgencyDetailsStore, MillesimesStore);
  const classes = useMillesimeInformationStyles();

  const [foodVoucherViewStore, setFoodVoucherViewStore] = useState<MillesimeReportsViewStore<'FOOD_VOUCHER'> | null>(null);

  const agency: AgencyModel = agencyDetailsStore.agencyDetails;
  const { restitutionMethod, restitutionIban } = agency;
  const isSaving = millesimesStore.isSavingRightsReconductionType;

  useEffect(() => {
    const agencyId: string | undefined = agenciesStore.currentAgency?.uid;
    millesimesStore.setSelectedAgency(agencyId);

    if (agencyId) {
      setFoodVoucherViewStore(new MillesimeReportsViewStore('FOOD_VOUCHER', agencyId));
    } else {
      setFoodVoucherViewStore(null);
    }
  }, [agenciesStore.currentAgency]);

  if (!isSaving && (millesimesStore.isLoadingCurrentMillesimeStatus || !millesimesStore.foodVoucherCurrentMillesimeStatus)) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.panelContainer}>
          <div className={classes.panelHeader}>
            <Typography>
              <Skeleton animation="wave" variant="text" width={'128px'} />
            </Typography>
          </div>
          <div className={classes.panelHeader}>
            <Skeleton animation="wave" variant="text" width={'50%'}/>
          </div>
        </div>
      </div>
    );
  }

  const endingMillesimeYear: number = millesimesStore.foodVoucherCurrentMillesimeStatus.year;
  const nextMillesimeYear: number = endingMillesimeYear + 1;
  const selectedRightsReconductionType: RightsReconductionType = millesimesStore.foodVoucherCurrentMillesimeStatus.rightsReconductionType;
  const alreadyDefinedRightsReconductionType: boolean = !!selectedRightsReconductionType;
  const allowToUpdate: boolean = millesimesStore.allowToModifyRightsReconductionType;
  const deadlineDate: Date = new Date(millesimesStore.foodVoucherCurrentMillesimeStatus.rightsReconductionUpdateLimit);

  const shouldShowEndOfMillesimeReports = !!foodVoucherViewStore;

  const handleOnSubmitRightsReconductionType = (selectedType: RightsReconductionType) => {
    millesimesStore.setSelectedRightsReconductionType('FOOD_VOUCHER', endingMillesimeYear, selectedType);
  };

  const handleOnUnselectRightsReconductionType = () => {
    millesimesStore.resetSelectedRightsReconductionType('FOOD_VOUCHER', endingMillesimeYear);
  };

  return (
    <div className={classes.wrapper}>
      {!alreadyDefinedRightsReconductionType
        ? <RightsReconductionTypesExplorerPanel
          endingMillesimeYear={endingMillesimeYear}
          nextMillesimeYear={nextMillesimeYear}
          restitutionMethod={restitutionMethod}
          restitutionIban={restitutionIban}
          allowToUpdate={allowToUpdate}
          isUpdating={isSaving}
          onSubmitButtonClicked={handleOnSubmitRightsReconductionType}
        />
        : <DefinedRightsReconductionTypePanel
          endingMillesimeYear={endingMillesimeYear}
          selectedRightsReconductionType={selectedRightsReconductionType}
          restitutionMethod={restitutionMethod}
          restitutionIban={restitutionIban}
          deadlineDate={deadlineDate}
          allowToUpdate={allowToUpdate}
          isUpdating={isSaving}
          onModifyButtonClicked={handleOnUnselectRightsReconductionType}
        />
      }

      {shouldShowEndOfMillesimeReports
        && <EndOfMillesimeReports reportsStore={foodVoucherViewStore} />
      }
    </div>
  );
});

export default MillesimeInformationComponent;
