import React, { FunctionComponent, useCallback } from 'react';
import { useYourInformationStyles } from './YourInformation.styles';
import { Button } from '@material-ui/core';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import StyledTextField from '../../Style/MuiStyles/TextField';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

type LocalStore = [AgenciesStore, StepperRechargingStore, OrderPaymentViewStore];
type LocalStyles = 'block' | 'input' | 'title' | 'wrapper' | 'buttons';

const YourInformation: FunctionComponent = observer(() => {
  const [
    agenciesStore,
    stepperRechargingStore,
    orderPaymentViewStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, StepperRechargingStore, OrderPaymentViewStore);

  const {
    hasMarketAndCommitmentNumberArea,
    hasInternalOrderNumberArea,
    hasCustomerReferenceArea,
    isDissociatedPayment,
    hasFeesCustomerReferenceArea,
  } = agenciesStore;

  const {
    customerReferenceValue,
    internalOrderNumberValue,
    marketNumberValue,
    commitmentNumberValue,
    feesCustomerReferenceValue,
  } = orderPaymentViewStore.invoiceCustomMetadata;

  const styles: ClassNameMap<LocalStyles> = useYourInformationStyles();
  const { t } = useTranslation('reloading');

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.block}>
          <h3 className={styles.title}>{t('yourInformation.title')}</h3>

          {hasFeesCustomerReferenceArea
            && <StyledTextField
                  className={styles.input}
                  id={'feesCustomerReferenceArea'}
                  name={'feesCustomerReferenceArea'}
                  label={t('yourInformation.feesCustomerReferenceArea')}
                  value={feesCustomerReferenceValue || ''}
                  onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('feesCustomerReferenceValue', event.currentTarget.value)}
                  fullWidth
              />}

          {hasCustomerReferenceArea
            && <StyledTextField
                  className={styles.input}
                  id={'customerReferenceArea'}
                  name={'customerReferenceArea'}
                  label={t(`yourInformation.customerReferenceArea${isDissociatedPayment ? '_DISSOCIATED_PAYMENT' : ''}`)}
                  value={customerReferenceValue || ''}
                  onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('customerReferenceValue', event.currentTarget.value)}
                  fullWidth
              />}

          {hasInternalOrderNumberArea
            && <StyledTextField
                  className={styles.input}
                  id={'internalOrderNumberArea'}
                  name={'internalOrderNumberArea'}
                  label={t('yourInformation.internalOrderNumberArea')}
                  value={internalOrderNumberValue || ''}
                  onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('internalOrderNumberValue', event.currentTarget.value)}
                  fullWidth
              />}
          {hasMarketAndCommitmentNumberArea
            && <>
                  <StyledTextField
                      className={styles.input}
                      id={'marketNumberArea'}
                      name={'marketNumberArea'}
                      label={t('yourInformation.marketNumberArea')}
                      value={marketNumberValue || ''}
                      onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('marketNumberValue', event.currentTarget.value)}
                      fullWidth
                  />
                  <StyledTextField
                      className={styles.input}
                      id={'commitmentNumberArea'}
                      name={'commitmentNumberArea'}
                      label={t('yourInformation.commitmentNumberArea')}
                      value={commitmentNumberValue || ''}
                      onChange={(event) => orderPaymentViewStore.setInvoiceCustomMetadataItem('commitmentNumberValue', event.currentTarget.value)}
                      fullWidth
                  />
              </>}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => stepperRechargingStore.goToPreviousStep()}
          type="button">
          {t('cancel')}
        </Button>
        <Button
          style={{ marginLeft: '2rem' }}
          variant="contained"
          color="primary"
          onClick={() => stepperRechargingStore.goToNextStep()}
          type="button">
          {t('nextStep')}
        </Button>
      </div>
    </div>
  );
});

export default YourInformation;