import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../Style/Style';

export const useMillesimeBannerStyles = makeStyles((theme) => ({
  bannerContainer: {
    margin: '20px',
    padding: '20px',
    border: 'solid 1px #FFC78A',
    borderRadius: '20px',
    backgroundColor: '#FFF9F3',
  },
  bannerHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },
  bannerTitleIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFC78A',
    borderRadius: '50%',
    height: '50px',
    width: '50px',
    flexShrink: 0,
  },
  bannerTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: COLORS.textPrimary,
  },
  bannerBody: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2em',
    flexWrap: 'wrap',
    padding: '10px',
  },
  optionCard: {
    backgroundColor: 'white',
    border: 'solid 1px #D8D8D8',
    borderRadius: '20px',
    minHeight: '150px',
    width: '100%',
    maxWidth: '450px',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  optionCardTitle: {
    marginBottom: '0.5em',
    color: COLORS.textPrimary,
  },
  optionCardText: {
    color: COLORS.textMuted,
  },
  optionCardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  optionCardButton: {
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    minWidth: 'initial',
    padding: 0,
  },
  bannerDescription: {
    textAlign: 'left',
    color: COLORS.textPrimary,
    padding: '10px',
    marginLeft: 'calc(40px + 1em)',
  },
  bannerFooter: {
    color: COLORS.textMuted,
    textAlign: 'center',
  },
  bannerLinkLabel: {
    color: COLORS.textPrimary,
  },
}));
