import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  useBeneficiaryAccountInformationsStyles
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@material-ui/core';
import { InputSelect } from '../../../Style/MuiStyles/Select/Select';
import Downarrow from '../../../assets/down-arrow';
import { COLORS } from '../../../Style/Style';
import BeneficiaryTextField from './BeneficiaryTextFieldComponent';
import { SelectCountryCodeModel } from '@assets/models/specials/selectListData.model';
import { ListOfCountryCode } from '../../../assets/models/specials/listOfCountryCode';
import { FunctionFormComponent } from './FunctionFormComponent.model';

export const STREET_ADDRESS = 'streetAddress';
export const ADDITIONAL_ADDRESS = 'additionalAddress';
export const POSTAL_CODE = 'postalCode';
export const CITY = 'city';
export const COUNTRY_CODE = 'countryCode';

export interface FormValues {
  [STREET_ADDRESS]: string,
  [ADDITIONAL_ADDRESS]: string | undefined,
  [POSTAL_CODE]: string,
  [CITY]: string,
  [COUNTRY_CODE]: string,
}

const AddressFormComponent: FunctionFormComponent = observer(({ formik, beneficiary, isEditMode = false, disableModification = false }) => {
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });
  const { t } = useTranslation('beneficiaries');

  const listOfCountryCode: SelectCountryCodeModel[] = ListOfCountryCode;

  return <>
    <p className={beneficiariesAccountInformationsClasses.title}>
      ADRESSE
    </p>

    <div className={beneficiariesAccountInformationsClasses.select}
         style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}>
      <Select
        value={formik.values[COUNTRY_CODE]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(COUNTRY_CODE, evt.target.value)}
        labelId="label"
        id="select"
        input={<InputSelect title="Pays de résidence"/>}
        disabled={disableModification}
        IconComponent={(props) => <div
          style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
        </div>}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {
          listOfCountryCode.map((countryCode, index) =>
            <MenuItem key={index} value={countryCode.value}>{t(countryCode.label)}</MenuItem>,
          )
        }
      </Select>
    </div>
    <BeneficiaryTextField id={STREET_ADDRESS} disabled={disableModification} formik={formik}/>
    <BeneficiaryTextField id={ADDITIONAL_ADDRESS} disabled={disableModification} formik={formik}/>
    <div style={{ display: 'inline-flex', width: '100%' }}>
      <div style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}>
        <BeneficiaryTextField id={POSTAL_CODE} disabled={disableModification} required formik={formik}/>
      </div>
      <div style={{ width: 'calc(50% - 10px)' }}>
        <BeneficiaryTextField id={CITY} disabled={disableModification} required formik={formik}/>
      </div>
    </div>
  </>
}) as FunctionFormComponent;

AddressFormComponent.getValidationSchema = () => {
  return Yup.object().shape({
    [STREET_ADDRESS]: Yup.string(),
    [POSTAL_CODE]: Yup.string()
      .min(5, 'Ce champ doit être renseigné avec 5 caractères obligatoirement.')
      .max(5, 'Ce champ doit être renseigné avec 5 caractères obligatoirement.')
      .required(),
    [CITY]: Yup.string().required(),
    [COUNTRY_CODE]: Yup.string(),
  });
}

AddressFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [STREET_ADDRESS]: beneficiary?.streetAddress ?? '',
    [ADDITIONAL_ADDRESS]: beneficiary?.additionalAddress ?? '',
    [POSTAL_CODE]: beneficiary?.postalCode ?? '',
    [CITY]: beneficiary?.city ?? '',
    [COUNTRY_CODE]: beneficiary?.countryCode ?? 'FR',
  }
}

export default AddressFormComponent;