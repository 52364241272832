import { Button, makeStyles } from '@material-ui/core';
import moment from 'moment/moment';
import React, { FunctionComponent } from 'react';
import calendarIcon from '../../../assets/calendar.svg';
import DialogBase from '../DialogBase';

interface RechargingOrderExistDialogProps {
  isActive: boolean;
  onClose: () => void;
  takeNewOrder: () => void;
  orderMonth: Date | null;
}

const RechargingOrderExistDialog: FunctionComponent<RechargingOrderExistDialogProps> = (props) => {
  const { isActive, orderMonth, onClose, takeNewOrder } = props;
  const styles = useRechargingOrderExistDialogStyles();
  return (
    <DialogBase isActive={isActive} onClose={onClose}>
      <p className={styles.title}>{`Commande du mois ${moment(orderMonth).format('MMMM YYYY')}`}</p>
      <div className={styles.content}>
        <div>
          <img width={50} alt={'Chargement de fichier'} src={calendarIcon}/>
        </div>
        <p className={styles.bold}>
          {`Vous avez déjà passé une commande pour ${moment(orderMonth).format('MMMM YYYY')}`}
        </p>
        <p>Etes-vous certain de vouloir commander à nouveau pour ce mois?</p>
      </div>
      <div className={styles.action}>
        <Button
          variant="contained"
          color="secondary"
          onClick={takeNewOrder}
          type="button">
          Oui, commander à nouveau pour ce mois
        </Button>
        <Button
          style={{ marginLeft: '2rem' }}
          variant="contained"
          color="primary"
          onClick={onClose}
          type="button">
          Annuler
        </Button>
      </div>
    </DialogBase>
  );
};

export default RechargingOrderExistDialog;

export const useRechargingOrderExistDialogStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    textAlign: 'center',
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  bold: {
    fontWeight: 'bold',
    padding: '1rem 0',
  },
}));