import Yup from '../../../i18n/validation';
import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  useBeneficiaryAccountInformationsStyles,
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { useInstances } from 'react-ioc';
import { OrganizationInformationsModel } from '@assets/models/organizationInformations/OrganizationInformations.model';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { SelectElement } from 'Component/SelectComponent';

export const MINISTRY = 'organizationMinistryId';
export const ADMINISTRATION = 'organizationAdministrationId';
export const BOP = 'organizationBopId';

export interface FormValues {
  [MINISTRY]: string;
  [ADMINISTRATION]: string;
  [BOP]: string;
}

const OrganizationFormComponent: FunctionFormComponent = observer(({
                                                                     formik, beneficiary, isEditMode = false,
                                                                     disableModification = false,
                                                                   }) => {
  const [agenciesStore]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });

  const agencyOrganizationInformations: OrganizationInformationsModel[] = agenciesStore.currentAgencyOrganizationInformations;
  const agencyMinistries: OrganizationInformationsModel[] = useMemo(() => agencyOrganizationInformations.filter(orga => orga.type === 'ministry'), [agencyOrganizationInformations]);
  const agencyAdministrations: OrganizationInformationsModel[] = useMemo(() => agencyOrganizationInformations.filter(orga => orga.type === 'administration'), [agencyOrganizationInformations]);
  // @todo : filter agencyBops with current selected agency admnistration children
  const agencyBops: OrganizationInformationsModel[] = useMemo(() => agencyOrganizationInformations.filter(orga => orga.type === 'bop'), [agencyOrganizationInformations]);

  // SETUP INITIAL FORM VALUES
  useEffect(() => {
    if (!!agencyMinistries.length && !beneficiary?.organizationMinistryId) {
      formik.setFieldValue(MINISTRY, agencyMinistries[0].uid);
    }
    if (!!agencyAdministrations.length && !beneficiary?.organizationAdministrationId) {
      formik.setFieldValue(ADMINISTRATION, agencyAdministrations[0].uid);
    }
    if (!!agencyBops.length && !beneficiary?.organizationBopId) {
      formik.setFieldValue(BOP, agencyBops[0].uid);
    }
  }, [agencyMinistries, agencyAdministrations, agencyBops, beneficiary]);

  return <div style={{ width: 'calc(100% - 10px)' }}>
    <p className={beneficiariesAccountInformationsClasses.title}>
      ORGANISATION
    </p>

    <div className={beneficiariesAccountInformationsClasses.select}>
      <SelectElement
        value={formik.values[ADMINISTRATION]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ADMINISTRATION, evt.target.value)}
        id={ADMINISTRATION}
        title="Administration"
        disabled={disableModification}
        list={agencyAdministrations}
      />
    </div>

    <div className={beneficiariesAccountInformationsClasses.select}>
      <SelectElement
        value={formik.values[MINISTRY]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(MINISTRY, evt.target.value)}
        id={MINISTRY}
        title="Ministère"
        disabled={disableModification}
        list={agencyMinistries}
      />
    </div>
    <div className={beneficiariesAccountInformationsClasses.select}>
      <SelectElement
        value={formik.values[BOP]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(BOP, evt.target.value)}
        id={BOP}
        title="BOP"
        disabled={disableModification}
        list={agencyBops}
      />
    </div>
  </div>;
}) as FunctionFormComponent;

OrganizationFormComponent.getValidationSchema = () => {
  return Yup.object().shape({
    [MINISTRY]: Yup.string().required(),
    [ADMINISTRATION]: Yup.string().required(),
    [BOP]: Yup.string().required(),
  });
};

OrganizationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [MINISTRY]: beneficiary?.organizationMinistryId ?? '',
    [ADMINISTRATION]: beneficiary?.organizationAdministrationId ?? '',
    [BOP]: beneficiary?.organizationBopId ?? '',
  };
};

export default OrganizationFormComponent;