import { makeStyles } from '@material-ui/core';
import { COLORS, FONT } from '../../../Style/Style';
import { IFormErrors } from './BeneficiaryTakeOut';

interface useBeneficiariesTakeOutStylesProps {
  disabled: boolean,
  hasRestitutionFailed: boolean,
  formErrors: IFormErrors,
}

export const useBeneficiariesTakeOutStyles = makeStyles(() => ({
  containerFistBlock: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  containerWarning: {
    display: 'flex',
    alignItems: 'center',
    height: '57px',
    backgroundColor: COLORS.failed,
    borderRadius: '10px',
  },
  imgWarning: {
    marginRight: '20px',
    marginLeft: '15px',
    width: '17px',
    height: '17px',
  },
  textWarning: {
    fontSize: '14px',
    color: COLORS.textPrimary,
  },
  leaveContainer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '60px',
  },
  titleSecondBlock: {
    letterSpacing: '2px',
    padding: '40px 50px 0px 0px',
    color: COLORS.textPrimary,
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  radioButtonWithDate: {
    display: 'flex',
    minHeight: '140px',
  },
  paragrapheRadio: {
    fontSize: '14px',
    color: COLORS.grey,
    fontWeight: 400,
    marginLeft: '41px',
  },
  containerRadioButton: {
    width: '55%',
    minHeight: '100px',
    paddingTop: '30px',

  },
  label: {
    fontSize: '14px',
    color: COLORS.textPrimary,
    marginLeft: '10px',
  },
  paragrapheRefund: {
    fontSize: '14px',
    color: COLORS.textSecondary,
    fontWeight: 400,
    marginLeft: '38px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '20px',
  },
  customRadio: {
    cursor: 'pointer',
  },
  inputLabelRadio: {
    color: COLORS.textPrimary,
    fontSize: '14px',
    marginLeft: '19px',
    cursor: 'pointer',
  },
  errorSelectedDate: {
    fontSize: '14px',
    color: COLORS.error,
    fontWeight: 400,
    marginLeft: '40px',
    marginTop: '10px',
  },
  containerDateKeepRights: {
    position: 'relative',
    width: '45%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    alignContent: 'space-around',
    paddingTop: '30px',
  },
  containerRestitutionStatement: ({ hasRestitutionFailed }: useBeneficiariesTakeOutStylesProps) => ({
    minHeight: '48px',
    maxHeight: '80px',
    backgroundColor: hasRestitutionFailed ? COLORS.error : COLORS.backgroundBlock,
    borderRadius: '10px',
    display: 'flex',
  }),
  imgWalletContainer: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
  },
  restitutionStatement: {
    marginTop: '12px',
    fontSize: '14px',
    lineHeight: '20px',
    color: COLORS.textPrimary,
    fontWeight: 500,
  },
  imgWallet: {
    width: '22px',
    height: '16px',
  },
  imgBlackWarning: {
    color: 'cyan',
    width: '22px',
    height: '22px',
    transform: 'rotate(180deg)',
  },
  restitutionDetail: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  switchOpacity: ({ disabled }: useBeneficiariesTakeOutStylesProps) => ({
    opacity: disabled ? '.5' : '1',
  }),
  deactivationDateInput: ({ formErrors }: useBeneficiariesTakeOutStylesProps) => ({
    width: '358px',
    border: formErrors?.deactivationDate ? `1px solid ${COLORS.error}` : '',
  }),
  consumptionRightLimitDateInput: ({ formErrors }: useBeneficiariesTakeOutStylesProps) => ({
    width: '358px',
    border: formErrors?.consumptionRightLimitDate ? `1px solid ${COLORS.error}` : '',
  }),
  buttonsBlock: ({ disabled }: useBeneficiariesTakeOutStylesProps) => ({
    justifyContent: 'right',
    marginTop: '3rem',
    right: '20px',
    display: disabled ? 'none' : 'flex',

    '& > button + button': {
      marginLeft: '30px',
    },
  }),
  snackbarContent: {
    background: `linear-gradient(to right, ${COLORS.validated} 4%, white 4%)`,
    borderRadius: '20px',
  },
  snackbarMessage: {
    width: '370px',
    backgroundColor: 'white',
    color: COLORS.textPrimary,
    padding: '1.5rem',
    fontFamily: FONT.primary,
  },
}));