import * as React from 'react';
import SidebarMenuComponent from '../MenuComponent/SidebarMenuComponent';
import TopMenuComponent from '../MenuComponent/TopMenuComponents/TopMenuComponent';
import FooterComponent from '../MenuComponent/FooterComponents/FooterComponent';
import { Drawer, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ConnectedStyles';
import { provider, useInstances } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { ConnectedViewStore } from '../Stores/viewStore/ConnectedView.store';
import CookiesComponent from '../Cookies/CookiesComponent';

export interface IConnectedComponentProps {
  children: any;
}

type LocalStores = [ConnectedViewStore];

const ConnectedComponent = provider(
  ConnectedViewStore,
)(observer((props: IConnectedComponentProps) => {
  const [connectedViewStore]: [ConnectedViewStore] = useInstances<LocalStores>(ConnectedViewStore);
  const classes = useStyles();
  const { t } = useTranslation('dashboard');
  const expirationDate: Date = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 6);

  return (
    <div className={classes.root}>
      <a className={classes.skip} href="#main-content">Aller au contenu principal</a>
      <div className={classes.nav}>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="js">
            <Drawer
              variant="temporary"
              open={connectedViewStore.mobileOpen}
              onClose={() => connectedViewStore.setMobileOpen(false)}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <SidebarMenuComponent/>
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="js">
            <Drawer anchor="left" PaperProps={{ style: { height: 'inherit', position: 'inherit' } }} variant="permanent"
                    open>
              <SidebarMenuComponent/>
            </Drawer>
          </Hidden>
        </nav>
        <div className={classes.topMenu}>
          <TopMenuComponent
            setMobileOpen={() => connectedViewStore.setMobileOpen(!connectedViewStore.mobileOpen)} {...props}/>
          <main id="main-content" className={classes.children}>
            {props.children}
          </main>
        </div>
      </div>
      <FooterComponent/>
      <CookiesComponent></CookiesComponent>
    </div>
  );
}));

export default ConnectedComponent;
