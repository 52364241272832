import { makeStyles } from '@material-ui/core';
import { COLORS, HOVER } from '../../Style/Style';

export const useSelectMethodRechargingStyles = makeStyles(() => ({
  title: {
    padding: '1rem .8rem',
    display:'flex',
    justifyContent:'start',
  },
  subTitle: {
    textAlign: 'center',
    padding: '5.5rem 0',
    fontSize: '20px',
  },
  blockContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  block: {
    backgroundColor: COLORS.backgroundBlock,
    border: `1px solid ${COLORS.borderBlock}`,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: HOVER.blockShadow
    },
    borderRadius: '10px',
    height: '250px',
    width: '254px',
    boxSizing: 'border-box',
    marginRight: '3rem',
    padding: '2.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));
