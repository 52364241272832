import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import downArrowIcon from '../../../assets/downArrow.svg';
import warningIcon from '../../../assets/warningGreen.svg';
import checked from './../../../assets/checked.svg';
import success from './../../../assets/mandateSignedSuccess.svg';
import { useUploadManagerStyles } from './AddBeneficiariesUpload.styles';
import warningFailed from './../../../assets/warning-failed.png';
import successWithFailed from './../../../assets/success-with-failed.png';
import {
  SerializedMinimalBeneficiaryUploadErrorModel,
  SerializedMinimalBeneficiaryUploadModel,
} from '@assets/models/beneficiaries/BeneficiariesFileUpload.model';
import { observer } from 'mobx-react-lite';

interface AddBeneficiariesUploadSuccessProps {
  beneficiariesCreated: SerializedMinimalBeneficiaryUploadModel[];
  beneficiariesUpdated: SerializedMinimalBeneficiaryUploadModel[];
  beneficiariesFailed: SerializedMinimalBeneficiaryUploadErrorModel[];
}

const AddBeneficiariesUploadSuccess: FunctionComponent<AddBeneficiariesUploadSuccessProps> = observer((props) => {
  const {
    beneficiariesCreated = [],
    beneficiariesUpdated = [],
    beneficiariesFailed = []
  } = props;

  const uploadManagerStyles: ClassNameMap<string> = useUploadManagerStyles();
  const { t } = useTranslation('beneficiariesfromcsv');

  return (
    <div>
      <div className={uploadManagerStyles.uploadResultBlock}>
        <h3>{t('subtitleResultSuccess')}</h3>
        {beneficiariesFailed.length === 0
          ? <>
            <div style={{ padding: '2rem' }}>
              <img src={success} alt={'success'} className={uploadManagerStyles.successIcon}/>
            </div>
            <p>{t('subtitleResultSuccessSecond')}</p>
          </>
          : <>
            <div style={{ padding: '2rem' }}>
              <img src={successWithFailed} alt={'success with failed'} className={uploadManagerStyles.successIcon}/>
            </div>
            <p>{t('subtitleResultSuccessWithFailed', { count: beneficiariesFailed.length })}</p>
          </>
        }

      </div>

      {beneficiariesFailed.length > 0 &&
          <Accordion className={uploadManagerStyles.accordionBlockFailed}>
              <AccordionSummary
                  expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={uploadManagerStyles.accordion}
              >
                  <Typography className={uploadManagerStyles.typography}>
                      <img src={warningFailed} alt="failed" className={uploadManagerStyles.warningIcon}/>
                      <span
                          className={uploadManagerStyles.titleText}>{t('titleFailed', { count: beneficiariesFailed.length })}</span>
                  </Typography>
              </AccordionSummary>
            {
              beneficiariesFailed?.map((beneficiaryUpload, index) => {
                return (
                  <AccordionDetails key={index} className={uploadManagerStyles.accordion}>
                    <Typography className={uploadManagerStyles.typography}>
                      {beneficiaryUpload.codeError === 'already-exists'
                        ? <span className={uploadManagerStyles.text}>
                            {beneficiaryUpload.firstName} {beneficiaryUpload.lastName} {beneficiaryUpload.email}, l'utilisateur existe déjà.
                          </span>
                        : <span className={uploadManagerStyles.text}>
                            {beneficiaryUpload.firstName} {beneficiaryUpload.lastName} {beneficiaryUpload.email}, erreur interne.
                          </span>}
                    </Typography>
                  </AccordionDetails>
                );
              })
            }
          </Accordion>}

      {beneficiariesCreated.length === 0
        ? <div className={uploadManagerStyles.accordionBlock}>
          <div className={uploadManagerStyles.accordionEmpty}>
            <Typography className={uploadManagerStyles.typographyEmpty}>
              <img src={warningIcon} alt="warning"/>
              <span
                className={uploadManagerStyles.titleText}>{t('titleAdded', { count: beneficiariesCreated.length })}</span>
            </Typography>
          </div>
        </div>
        : <Accordion className={uploadManagerStyles.accordionBlock}>
          <AccordionSummary
            expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={uploadManagerStyles.accordion}
          >
            <Typography className={uploadManagerStyles.typography}>
              <img src={checked} alt="checked"/>
              <span
                className={uploadManagerStyles.titleText}>{t('titleAdded', { count: beneficiariesCreated.length })}</span>
            </Typography>
          </AccordionSummary>
          {
            beneficiariesCreated?.map((row, index) => {
              return (
                <AccordionDetails key={index} className={uploadManagerStyles.accordion}>
                  <Typography className={uploadManagerStyles.typography}>
                          <span className={uploadManagerStyles.text}>
                            {row.firstName} {row.lastName}
                          </span>
                  </Typography>
                </AccordionDetails>
              );
            })
          }
        </Accordion>}
      {beneficiariesUpdated.length === 0
        ? <div className={uploadManagerStyles.accordionBlock}>
          <div className={uploadManagerStyles.accordionEmpty}>
            <Typography className={uploadManagerStyles.typographyEmpty}>
              <img src={warningIcon} alt="warning"/>
              <span
                className={uploadManagerStyles.titleText}>{t('titleUpdate', { count: beneficiariesUpdated.length })}</span>
            </Typography>
          </div>
        </div>
        : <Accordion className={uploadManagerStyles.accordionBlock}>
          <AccordionSummary
            expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={uploadManagerStyles.accordion}
          >
            <Typography className={uploadManagerStyles.typography}>
              <img src={checked} alt="checked"/>
              <span
                className={uploadManagerStyles.titleText}>{t('titleUpdate', { count: beneficiariesUpdated.length })}</span>
            </Typography>
          </AccordionSummary>
          {
            beneficiariesUpdated?.map((row, index) => {
              return (
                <AccordionDetails key={index} className={uploadManagerStyles.accordion}>
                  <Typography className={uploadManagerStyles.typography}>
                        <span className={uploadManagerStyles.text}>
                          {row.firstName} {row.lastName}
                        </span>
                  </Typography>
                </AccordionDetails>
              );
            })
          }
        </Accordion>}
    </div>
  );
});

export default AddBeneficiariesUploadSuccess;