import { ListItem, styled } from '@material-ui/core';
import React from 'react';
import { FONT, COLORS } from '../Style';
import { keyframes } from 'typestyle';

interface StyledSideBarMenuListItemProps {
  isCurrentPage: boolean;
}

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1
  }
});

const StyledSideBarMenuListItem = styled(
  ({ isCurrentPage, ...rest }: StyledSideBarMenuListItemProps & any) => <ListItem {...rest} />
)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'end',
  gap: '7px',
  width: '100%',
  padding: '12px 0 17px',
  cursor: 'pointer',
  fontFamily: FONT.primary,
  fontSize: '14px',
  textAlign: 'center',
  wordWrap:'break-word',
  color: COLORS.textPrimary,
  backgroundColor: ({ isCurrentPage }: StyledSideBarMenuListItemProps) =>
    isCurrentPage ? COLORS.backgroundBlock : '',
  borderRight: ({ isCurrentPage }: StyledSideBarMenuListItemProps) =>
    isCurrentPage ? `3px solid ${COLORS.darkGrey}` : '',

  animation: `${fadeIn} 0.5s`,
});

export default StyledSideBarMenuListItem;
