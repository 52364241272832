import { useEffect, useMemo } from 'react';
import { useInstance } from 'react-ioc';
import { AppConfigStore } from '../Stores/AppConfig.store';
import { FeatureFlagsConfigValue } from '../assets/models/configuration/featureFlags.model';

export function useFeatureFlag<T extends FeatureFlagsConfigValue = FeatureFlagsConfigValue>(flagName: string, defaultValue: boolean = false): T {
  const appConfigStore = useInstance<AppConfigStore>(AppConfigStore);

  useEffect(() => {
    appConfigStore.startPolling();
    return () => {
      appConfigStore.stopPolling();
    };
  }, [appConfigStore]);


  return useMemo(
    () => appConfigStore.getFlagValue<T>(flagName, defaultValue),
    [appConfigStore.getLastUpdateTime()]
  );
}