import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { InvoiceModel } from '../../../../assets/models/invoices/invoice.model';
import { useDocumentDetailsStyles } from '../../DocumentDetailsStyles';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { getMainReferenceFromProvisionReference } from '../../../../assets/utils/invoices/invoice.utils';
import classnames from 'classnames';

export interface IInvoiceProvisionsSummaryProps {
  invoice: InvoiceModel;
  currencyFormat: Intl.NumberFormat;
  dateOptions: Intl.DateTimeFormatOptions;
}

const InvoiceProvisionsSummary: FunctionComponent<IInvoiceProvisionsSummaryProps> = observer((props: IInvoiceProvisionsSummaryProps) => {
  const { invoice, currencyFormat, dateOptions } = props;
  const { t } = useTranslation('accounting');
  const invoiceDetailsClasses = useDocumentDetailsStyles({ document: props.invoice });
  const subTotalClasses = classnames({
    [invoiceDetailsClasses.subTotal]: true,
    [invoiceDetailsClasses.inheritFontWeight]: props.invoice.invoiceType !== 'STANDARD',
  });

  const provisionAmount: number = invoice.provisionsAmount;
  const invoiceDate: Date = new Date(invoice.invoiceDate);

  const orderReference: string = invoice.invoiceType === 'DISSOCIATED_PROVISIONS'
    ? getMainReferenceFromProvisionReference(invoice.invoiceReference)
    : invoice.invoiceReference;

  return (
    <>
      <div className={invoiceDetailsClasses.secondBlock}>
        <div className={invoiceDetailsClasses.title2}>
          {t('invoiceDetails.provisionTitle',
            { month: invoiceDate.toLocaleDateString('fr-FR', dateOptions) })}
        </div>
        <p className={invoiceDetailsClasses.amount}>
          {currencyFormat.format(provisionAmount)} HT
        </p>
      </div>
      <p className={invoiceDetailsClasses.transaction}>
        {t('invoiceDetails.reloading', {
          reference: orderReference,
          date: moment(invoice.creationDate).format('DD/MM/YYYY'),
        })}
      </p>
      <hr/>
      <div className={invoiceDetailsClasses.paymentBlock}>
        <div className={subTotalClasses}>
          <p>{t('invoiceDetails.totalProvisions')}</p>
          <p className={invoiceDetailsClasses.amount}>
            {currencyFormat.format(provisionAmount)} HT
          </p>
        </div>
      </div>
    </>
  );
});

export default InvoiceProvisionsSummary;