import Button from '../../../Style/MuiStyles/Button';
import { GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import trash from '../../../assets/trash.png';
import accept from '../../../assets/accept.png';
import cancel from '../../../assets/cancel.png';
import React from 'react';
import { useBeneficiaryActivityStyles } from './BeneficiaryActivity.styles';
import { EditableGridMonthYear } from '../../../Component/DatePicker/EditableGridMonthYear';

interface DataGridColumnsProps {
  handleCancelAdd: (event: React.MouseEvent) => void;
  handleSaveNewRow: (params: any) => void;
  handleRemoveRow: (params: any) => void;
  uniqueId: string | null;
  dateOptions: Intl.DateTimeFormatOptions;
  disabled: boolean,
}

export const getDataGridColumn = ({
                                    handleCancelAdd,
                                    handleSaveNewRow,
                                    handleRemoveRow,
                                    uniqueId,
                                    dateOptions,
                                    disabled,
                                  }: DataGridColumnsProps): GridColDef[] => {
  const beneficiaryActivityStyles = useBeneficiaryActivityStyles();

  return [
    {
      field: 'startDate',
      headerName: 'Début',
      flex: 1,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value instanceof Date
          ? params.value.toLocaleDateString('fr-FR', dateOptions)
          : '',
      renderEditCell: EditableGridMonthYear,
    },
    {
      field: 'nbOfMonth',
      headerName: 'Nb de mois',
      flex: 1,
      type: 'number',
    },
    {
      field: 'nbOfMealReductionPerMonth',
      headerName: 'Nb repas en moins / mois',
      flex: 1,
      type: 'number',
    },
    {
      field: 'totalOfMealReduction',
      headerName: 'Total de repas en moins',
      flex: 1,
      type: 'number',
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const edit: boolean = params.id === uniqueId;
        return edit
          ? (<>
            <Button
              onClick={handleCancelAdd}
              type="button"
              variant="contained"
              color={'default'}
              disabled={disabled}
              className={beneficiaryActivityStyles.buttonRow}
            >
              <img src={cancel} alt={'icon annuler'} width={30} height={30}/>
            </Button>
            <Button
              onClick={() => handleSaveNewRow(params)}
              type="button"
              variant="contained"
              disabled={disabled}
              className={beneficiaryActivityStyles.buttonRow}
            >
              <img src={accept} alt={'icon valider'} width={30} height={30}/>
            </Button>
          </>)
          : (<>
            <Button
              onClick={() => handleRemoveRow(params)}
              type="button"
              variant="contained"
              disabled={false}
              className={beneficiaryActivityStyles.buttonRow}
              style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            >
              <img src={trash} alt={'icon supprimer'} width={25} height={25}/>
            </Button>
          </>);
      },
    },
  ];
};
