import { Dialog } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { MandateStatus } from '../../assets/models/mandates/mandate.model';
import { PaymentMethodType } from '../../assets/types/PaymentMethod.type';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { MandateStore } from '../../Stores/Mandate.store';
import { PayingServiceStore } from '../../Stores/PayingService.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import ButtonComponent from '../../Style/MuiStyles/Button';
import MandateSignatureDialog from './MandateSignatureDialog/MandateSignatureDialog';
import MethodPaymentManager from './MethodPaymentManager/MethodPaymentManager';
import OrderDetails from './OrderDetails';
import { useOrderPaymentStyles } from './OrderPaymentStyles';
import { AgencyModel } from '../../assets/models/agencies/Agency.model';

type LocalStore = [OrderPaymentViewStore, MandateStore, AgenciesStore, PayingServiceStore, StepperRechargingStore];

type LocalStyles =
  'container'
  | 'orderBlockTotalLine'
  | 'OrderDetails'
  | 'paymentOrder'
  | 'orderBlock'
  | 'paymentMethod'
  | 'text'
  | 'title'
  | 'totalHT'
  | 'orderButtonSubmit'
  | 'dialogPaper';

/**
 * Tab: Rechargement des droits
 * Step: Paiement (PAYMENT)
 */
const OrderPayment: FunctionComponent = observer(() => {
  const [
    orderPaymentViewStore,
    mandateStore,
    agenciesStore,
    payingServiceStore,
    stepperRechargingStore,
  ]: LocalStore = useInstances<LocalStore>(OrderPaymentViewStore, MandateStore, AgenciesStore, PayingServiceStore, StepperRechargingStore);
  const { t } = useTranslation('payment');
  const orderPaymentStyles: ClassNameMap<LocalStyles> = useOrderPaymentStyles();
  // TODO : autonomyMode.
  // const autonomyMode: boolean = agenciesStore.currentAgency?.isManagedPaymentMode;
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpenMandateDialog }: OrderPaymentViewStore = orderPaymentViewStore;
  const { mandateStatus }: { mandateStatus: MandateStatus } = mandateStore;
  const { currentAgency }: { currentAgency: AgencyModel } = agenciesStore;
  const agencyPaymentMethod: PaymentMethodType = agenciesStore.currentAgency?.paymentMethod;

  useEffect(() => {
    if (!currentAgency) {
      return;
    }

    orderPaymentViewStore.setPaymentMethod(agencyPaymentMethod);
    switch (agencyPaymentMethod) {
      case 'DIRECT_DEBIT':
        mandateStore.reset();
        void mandateStore.findMandateByAgencyId(currentAgency.uid);
        break;
      case 'BANK_TRANSFER':
      case 'DISSOCIATED_BANK_TRANSFER':
        void agenciesStore.getAgencyBankAccountAlias();
        break;
    }
  }, [currentAgency, agencyPaymentMethod]);

  useEffect(() => {
    if (agencyPaymentMethod === 'DIRECT_DEBIT') {
      setIsConfirmButtonDisabled(mandateStatus !== 'ACTIVE');
    }
  }, [agencyPaymentMethod, mandateStatus]);

  const createOrder: () => Promise<void> = useCallback(async () => {
    const agencyId: string = currentAgency?.uid;
    const { agencyOrderCreationRequest }: OrderPaymentViewStore = orderPaymentViewStore;

    try {
      setIsLoading(true);
      await payingServiceStore.createOrder({ ...agencyOrderCreationRequest, agencyId });
      stepperRechargingStore.goToNextStep();
      setIsLoading(false);

    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [currentAgency]);

  return (
    <>
      <div className={orderPaymentStyles.container}>
        <div className={orderPaymentStyles.paymentMethod}>
          <MethodPaymentManager/>
        </div>
        <div className={orderPaymentStyles.OrderDetails}>

          {orderPaymentViewStore.orderDetails && <OrderDetails/>}

          <ButtonComponent
            variant="contained"
            color="primary"
            className={orderPaymentStyles.orderButtonSubmit}
            disabled={isConfirmButtonDisabled || isLoading}
            isFetching={isLoading}
            onClick={createOrder}
            type="button">
            {agencyPaymentMethod === 'DIRECT_DEBIT'
              ? t('directDebit.btnSubmit')
              : t('bankTransfer.btnSubmit')
            }
          </ButtonComponent>
        </div>
      </div>

      <Dialog
        className={orderPaymentStyles.dialogPaper}
        fullWidth maxWidth="md"
        open={isOpenMandateDialog}
        onClose={() => orderPaymentViewStore.setOpenMandateDialog(false)}
      >
        <MandateSignatureDialog
          isLoading={mandateStore.isFindingMandate}
          onClose={() => orderPaymentViewStore.setOpenMandateDialog(false)}
        />
      </Dialog>
    </>
  );
});

export default OrderPayment;
