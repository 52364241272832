import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useCSVReader } from 'react-papaparse';
import { COLORS, FONT } from '../../../Style/Style';
import { makeStyles } from '@material-ui/core';
import Button from '../../../Style/MuiStyles/Button';
import { FileTestSet } from '../../../Utils/Tester/BaseTester.service';
import uploadIcon from '../../../assets/upload.svg';

const useDropzoneStyles = makeStyles(() => ({
  dropzoneArea: (props: any) => ({
    backgroundColor: COLORS.white,
    border: props.zoneHover ? `2px dashed ${COLORS.validated}` : `2px dashed ${COLORS.borderBlock}`,
    cursor: 'pointer',
    borderRadius: '10px',
    minHeight: '242px',
    width: '683px',
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '40px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%'
  },
  icon: {
    width: '70px'
  },
  textField: {
    color: COLORS.textPrimary,
    fontFamily: FONT.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '24px',
    paddingBottom: 20,
  },
  link: {
    color: COLORS.textSecondary,
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingBottom: 20,
  },
  uploadIcon: {
    paddingBottom: 20,
  },
  button: {
    marginTop: 20,
  }
}));

interface IFileUploadedManager<T> {
  file: any;
  setFile: (value: File) => void;
  testSet: FileTestSet<T>;
  analyseUploadingFile: (csvData: string[][], testSet: FileTestSet<T>) => void;
  fileTypeAccepted?: string;
  isLoading?: boolean;
  setIsLoading?: (value: boolean) => void;
  showNewTestResult?: boolean;
  setShowNewTestResult?: (value: boolean) => void;
  className?: string;
}

export default function FileUploadManager <T, >(props: IFileUploadedManager<T>) {
  const { t } = useTranslation('common');
  const [zoneHover, setZoneHover] = useState<boolean>(false);
  const dropzoneStyles = useDropzoneStyles({ ...props, zoneHover });
  const { CSVReader } = useCSVReader();
  const {
    setFile,
    setShowNewTestResult,
    setIsLoading,
    analyseUploadingFile,
    isLoading,
    file,
    testSet,
    showNewTestResult,
  }: IFileUploadedManager<T> = props;

  const launchFileAnalyse = useCallback((e) => {
    e.stopPropagation();
    setIsLoading(true);
    analyseUploadingFile(file, testSet);
    setShowNewTestResult(false);
  }, [setIsLoading, analyseUploadingFile, setShowNewTestResult]);

  const onUploadAccepted = useCallback((results: any) => {
    setFile(results.data);
    setZoneHover(false);
    setShowNewTestResult(true);
  },[]);

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    setZoneHover(true);
  },[]);

  const onDragLeave = useCallback((event: DragEvent) => {
    event.preventDefault();
    setZoneHover(false);
  },[]);

  return (
    <CSVReader
      onUploadAccepted={onUploadAccepted}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      {({getRootProps, acceptedFile}: any) => (<>
        <div {...getRootProps()} className={dropzoneStyles.dropzoneArea}>
          <div className={dropzoneStyles.content}>
            {acceptedFile
              ? (<>
                <img className={dropzoneStyles.uploadIcon} src={uploadIcon} alt="icône de téléchargement d'un fichier"/>
                <p className={dropzoneStyles.textField}>
                  {acceptedFile.name}
                </p>
                <span>
                  <span className={dropzoneStyles.link}>{t('dropzone.link.search')}</span>
                  {t('dropzone.link.anotherFile')}
                </span>
                {showNewTestResult &&
                  <Button
                      variant='contained'
                      className={dropzoneStyles.button}
                      color='primary'
                      type='button'
                      isFetching={isLoading}
                      disabled={isLoading}
                      onClick={launchFileAnalyse}
                  >
                    {t('dropzone.readFile')}
                  </Button>
                }
              </>)
              : (<>
                <img className={dropzoneStyles.uploadIcon} src={uploadIcon} alt="icône de téléchargement d'un fichier"/>
                <p className={dropzoneStyles.textField}>
                  {t('dropzone.titleEmpty')}
                </p>
                <span>
                  {t('dropzone.link.where')}
                  <span className={dropzoneStyles.link}>{t('dropzone.link.search')}</span>
                  {t('dropzone.link.yourFile')}
                </span>
              </>)
            }
          </div>
        </div>
      </>)}
    </CSVReader>
  );
};
