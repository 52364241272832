import { makeStyles } from '@material-ui/core';
import { COLORS, FONT_WEIGHT } from '../../../Style/Style';

export const useBeneficiariesDetailsStyles = makeStyles(() => ({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px'
  },
  imgClose: {
    cursor: 'pointer'
  },
  input: {
    marginBottom: '25px'
  },
  select: {
    marginBottom: '25px',
    placeContent: 'center'
  },
  title: {
    margin: '0',
    letterSpacing: '2px',
    padding: '10px 50px 40px 0px',
    color: COLORS.textPrimary
  },
  tab: {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.semiBold
  },
  appBar: {
    borderBottom: `1px solid ${COLORS.borderBlock}`
  }
}));
