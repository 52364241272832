import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../Style/Style';

export const useStyles = makeStyles({
  lineWrapper: {
    marginTop: '50px',
  },
  identityWrapper: {
    display: 'flex',
    gap: '15px',
  },
  subTitle: {
    fontWeight: 400,
    letterSpacing: '2px',
    fontSize: '16px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: (props: { isUpdateMode: boolean }) =>
      props.isUpdateMode ? 'space-between' : 'flex-end',
  },
  saveButtonContainer: {
    display: 'flex',
    gap: '15px',
  },
  formContainer: {
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectedItemClass: {
    backgroundColor: '#2E4052',
    color: 'white',
    marginRight: '5px',
    '& .MuiChip-labelSmall': {
      lineHeight: 'initial',
      textIndent: 'initial',
    },
  },
  checkedSelectUnit: {
    '&.Mui-checked': {
      color: COLORS.primary,
    },
  },
  buttonDelete: {
    backgroundColor: COLORS.failedStatus,
    color: COLORS.white,
  },
});