import React, { FunctionComponent, useEffect } from 'react';
import ButtonComponent from '../../Style/MuiStyles/Button';
import { useTranslation } from 'react-i18next';
import { PaymentMethodType } from '../../assets/types/PaymentMethod.type';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useCreateOrderResponseStyles } from './OrderSummaryStyles';
import congratulations from '../../assets/congratulations.svg';
import exclamationOrange from '../../assets/exclamation-orange.svg';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { PayingServiceStore } from '../../Stores/PayingService.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { useInstances } from 'react-ioc';
import CopyButton from '../../Component/CopyButton/CopyButton';
import { getFrenchMoneyFormat } from '../../assets/utils/money/currencyFormat.util';

type LocalStore = [PayingServiceStore, OrderPaymentViewStore];
type LocalStyles =
  | 'main'
  | 'header'
  | 'importantText'
  | 'image'
  | 'bankTransferSection'
  | 'descriptionBlock'
  | 'description'
  | 'icon'
  | 'footer'
  | 'dissociatedDescription'
  | 'descriptionDissBlock'
  | 'dissociatedDescriptionText'
  | 'dissociatedDescriptionTextLine'
  | 'dissociatedBankTransferSection'
  ;

const OrderSummary: FunctionComponent = () => {
  const [
    payingServiceStore,
    orderPaymentViewStore,
  ]: LocalStore = useInstances<LocalStore>(PayingServiceStore, OrderPaymentViewStore);

  const navigate: NavigateFunction = useNavigate();
  const orderResponseStyles: ClassNameMap<LocalStyles> = useCreateOrderResponseStyles();
  const { t } = useTranslation('payment');

  const expectedBankWirePaymentDescriptor: string = payingServiceStore.bankWireReference?.expectedBankWirePaymentDescriptor;
  const paymentMethod: PaymentMethodType = orderPaymentViewStore.paymentMethod;
  const amount = getFrenchMoneyFormat(orderPaymentViewStore.orderDetails.netAmountPayable, false);

  useEffect(() => sessionStorage.removeItem('reloadingDateKey'), []);

  return (
    <>
      <div className={orderResponseStyles.main}>
        <div className={orderResponseStyles.header}>
          <p className={orderResponseStyles.importantText}>{t('success.felicitations')}</p>
        </div>
        <div className={orderResponseStyles.image}>
          <img alt="congratulations logo" src={congratulations}/>
        </div>
        <div>
          <p className={orderResponseStyles.importantText}>{t('success.paymentDescription', { total: amount })}</p>
          <p className={orderResponseStyles.importantText}>{t('success.paymentDescription2')}</p>
        </div>
        {paymentMethod === 'BANK_TRANSFER' &&
            <div className={orderResponseStyles.bankTransferSection}>
                <div className={orderResponseStyles.descriptionBlock}>
                    <img className={orderResponseStyles.icon} alt="exclamation logo" src={exclamationOrange}/>
                    <span className={orderResponseStyles.description}>{t('success.bankTransferDescription')}</span>
                </div>
                <div className={orderResponseStyles.descriptionBlock}>
                    <span className={orderResponseStyles.importantText}>{expectedBankWirePaymentDescriptor}</span>
                    <CopyButton value={expectedBankWirePaymentDescriptor} />
                </div>
            </div>
        }
        {paymentMethod === 'DISSOCIATED_BANK_TRANSFER' &&
            <div className={orderResponseStyles.dissociatedBankTransferSection}>
                <div>
                    <img className={orderResponseStyles.icon} alt="exclamation logo" src={exclamationOrange}/>
                </div>
                <div className={orderResponseStyles.dissociatedDescription}>
                    <p className={orderResponseStyles.dissociatedDescriptionText}>{t('success.dissociatedBankTransferDescription')}</p>
                    <p className={orderResponseStyles.dissociatedDescriptionText}>
                      {t('success.dissociatedBankTransferDescriptionTransfer')} <span className={orderResponseStyles.dissociatedDescriptionTextLine}>{t('success.dissociatedBankTransferDescriptionFees')}</span></p>
                    <p className={orderResponseStyles.dissociatedDescriptionText}>{t('success.dissociatedBankTransferDescriptionAnd')}</p>
                    <p className={orderResponseStyles.dissociatedDescriptionText}>
                      {t('success.dissociatedBankTransferDescriptionTransfer')} <span className={orderResponseStyles.dissociatedDescriptionTextLine}>{t('success.dissociatedBankTransferDescriptionMain')}</span></p>
                    <div className={orderResponseStyles.descriptionDissBlock}>
                        <span className={orderResponseStyles.importantText}>{expectedBankWirePaymentDescriptor}</span>
                        <CopyButton value={expectedBankWirePaymentDescriptor} />
                    </div>
                </div>
            </div>
        }
      </div>
      <div className={orderResponseStyles.footer}>
        <ButtonComponent
          variant="contained"
          color="primary"
          onClick={() => navigate('/reloading')}
          type="button">
          {t('success.btnSubmit')}
        </ButtonComponent>
      </div>
    </>
  );
};

export default OrderSummary;
