import { makeStyles } from '@material-ui/core';
import { COLORS, HOVER } from '../../Style/Style';

export const useOrderParamsStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column' ,
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem 0',
  },
  classificationWrapper: {
    display: 'flex',
    gap: '2rem'
  },
  classificationCard: ({ isMultiClass }: any) => ({
    backgroundColor: COLORS.backgroundBlock,
    borderRadius: '10px',
    maxWidth: isMultiClass ? '226px' : '328px',
    boxSizing: 'border-box',
    margin: '1rem 0',
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    color: COLORS.textPrimary,
  }),
  contentMonth: {
    position: 'relative',
    border: `1px solid ${COLORS.borderBlock}`,
    '&:hover': {
      boxShadow: HOVER.blockShadow
    },
    borderRadius: '10px',
    width: '600px',
    boxSizing: 'border-box',
    margin: '1rem 0',
    padding: '3rem 2rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  block: {
    boxSizing: 'border-box',
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'initial',
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 600,
    padding: '0 1rem',
  },
  cardRow: {
    display: 'flex',
    padding: '0 1rem',
  },
  icon: ({ isMultiClass }: any) => ({
    display: isMultiClass ? 'none' : 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 1.2rem'
  }),
  iconSelect: { textAlign: 'center', paddingRight: '1rem' },
  unit: { fontSize: '16px', fontWeight: 'bold', marginTop: '0.3rem' },
  description: { fontSize: '14px' },
  buttons: { display: 'flex', justifyContent: 'right', marginBottom: '40px' },
  selectOnError: {
    borderColor: COLORS.error,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
  },
  errorMessage: {
    position: 'absolute',
    bottom: -30,
    left: 0,
    color: COLORS.error,
    fontSize: 14
  }
}));
