import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, CircularProgress, IconButton,
  Link,
  Paper, SvgIcon, Tooltip,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import paperClipSvg from '../../../../assets/paper-clip.svg';
import { useMillesimeInformationStyles } from '../MillesimeInformationStyles';
import { RestitutionMethod, RightsReconductionType } from '../../../../assets/models/agencies/Agency.model';
import { formatCurrencyToEuro } from '../../../../Utils/format.utils';
import { AgencyMillesimeReport, BeneficiaryMillesimeReport } from '@assets/models/millesime/MillesimeReports.model';
import { observer } from 'mobx-react-lite';
import { ProductType } from '@assets/models/products/Products.model';
import {
  BeneficiariesReportsData,
  MillesimeReportsViewStore,
} from '../../../../Stores/viewStore/MillesimeReportsView.store';
import {
  AsyncPaginatedTable,
  AsyncPaginatedTableProps,
  TableColumn,
} from '../../../../Component/AsyncPaginatedTable/AsyncPaginatedTable';
import { Skeleton } from '@material-ui/lab';
import downloadIcon from '../../../../assets/download.svg';
import useAccordionStyles from '../../../../Style/MuiStyles/Accordion';
import classnames from 'classnames';

interface EndOfMillesimeReportPanelProps {
  agencyReport: AgencyMillesimeReport<any, 'DONE'>;
  beneficiariesReportsData?: BeneficiariesReportsData;
  onCollapsedStateChange?: (collapsed: boolean) => void;
  onBeneficiariesReportsPageChange?: (page: number) => void;
  onBeneficiariesReportsPageSizeChange?: (pageSize: number) => void;
  onDownloadReceipt?: () => void;
  isDownloadingReceipt?: boolean;
  onDownloadBeneficiariesReportsCsv?: () => void;
  isPreparingBeneficiariesReportsCsv?: boolean;
}

interface RestitutionDetailsTableProps extends Omit<AsyncPaginatedTableProps<BeneficiaryMillesimeReport<ProductType, 'DONE'>, 'uid'>, 'columns' | 'classes' | 'rowKey'> {

}

const EndOfMillesimeReports: FunctionComponent<any> = observer(({ reportsStore }: {reportsStore: MillesimeReportsViewStore<ProductType>}) => {
  const classes = useMillesimeInformationStyles();

  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    reportsStore.fetchAgencyReports('DONE');
  }, []);

  const handleCollapsedStateChange = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>, collapsed: boolean) => {
    if (collapsed) {
      reportsStore.clearBeneficiariesReports(report.uid);
    } else {
      reportsStore.fetchBeneficiariesReports(report.uid, { requiredPage: 0, pageSize: pageSize });
    }
  }, [pageSize]);

  const handlePageChange = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>, page: number) => {
    reportsStore.fetchBeneficiariesReports(report.uid, { requiredPage: page, pageSize: pageSize });
  }, [pageSize]);

  const handlePageSizeChange = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>, newPageSize: number) => {
    setPageSize(newPageSize);
    reportsStore.fetchBeneficiariesReports(report.uid, { requiredPage: 0, pageSize: newPageSize });
  }, []);

  const handleDownloadReceipt = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>) => {
    reportsStore.downloadReceipt(report.uid);
  }, []);

  const handleDownloadCsv = useCallback((report: AgencyMillesimeReport<ProductType, 'DONE'>) => {
    reportsStore.downloadBeneficiariesReportsCsv(report.uid);
  }, []);

  return (
    <div className={classes.reportListsContainer}>
      {
        reportsStore.isLoadingAgencyReports
        ? (
            <div className={classes.reportsListsLoaderWrapper}>
              <CircularProgress color="primary" size={32} />
            </div>
          )
        : (
            reportsStore.agencyReports.map((report: AgencyMillesimeReport<ProductType, 'DONE'>) =>
              <EndOfMillesimeReportPanel key={report.uid}
                                         agencyReport={report}
                                         beneficiariesReportsData={reportsStore.getBeneficiariesReportsData(report.uid)}
                                         onCollapsedStateChange={(collapsed: boolean) => handleCollapsedStateChange(report, collapsed)}
                                         onBeneficiariesReportsPageChange={(page: number) => handlePageChange(report, page)}
                                         onBeneficiariesReportsPageSizeChange={(pageSize: number) => handlePageSizeChange(report, pageSize)}
                                         onDownloadReceipt={() => handleDownloadReceipt(report)}
                                         isDownloadingReceipt={reportsStore.isDownloadingReceipt(report.uid)}
                                         onDownloadBeneficiariesReportsCsv={() => handleDownloadCsv(report)}
                                         isPreparingBeneficiariesReportsCsv={reportsStore.isPreparingBeneficiariesReportsCsv(report.uid)}
              />,
            )
          )
      }
    </div>
  );
});

const EndOfMillesimeReportPanel: FunctionComponent<EndOfMillesimeReportPanelProps> = ({
  agencyReport,
  beneficiariesReportsData,
  onCollapsedStateChange ,
  onBeneficiariesReportsPageChange,
  onBeneficiariesReportsPageSizeChange,
  onDownloadReceipt,
  isDownloadingReceipt,
  onDownloadBeneficiariesReportsCsv,
  isPreparingBeneficiariesReportsCsv,
}) => {
  const millesimeClasses = useMillesimeInformationStyles();
  const accordionClasses = useAccordionStyles();

  const customAccordionStyle = classnames({
    [millesimeClasses.millesimeAccordion]: true,
    [accordionClasses.accordion]: true,
  });
  const { t } = useTranslation('millesime');
  const {
    year, rightsReconductionType, restitutionMethod, restitutionIBAN, totalAmountOfUnusedCredits, receiptUrl
  } = agencyReport;

  const [reportsCount, setReportsCount] = useState<number | null>(null);

  useEffect(() => {
    if (!beneficiariesReportsData || beneficiariesReportsData?.count === -1) {
      setReportsCount(null);
    }
    if (beneficiariesReportsData?.count > -1) {
      setReportsCount(beneficiariesReportsData.count);
    }
  }, [beneficiariesReportsData?.count]);

  const shouldDisplayBeneficiariesReports = totalAmountOfUnusedCredits > 0;
  const shouldShowRestitutionIban = (rightsReconductionType === 'DROP' && restitutionMethod === 'RESTITUTION_IBAN_PAYOUT');
  const shouldShowRestitutionTotalAmount = typeof totalAmountOfUnusedCredits === 'number';
  const shouldShowInvoiceDownloadLink = typeof agencyReport.receiptId !== 'undefined';

  const getAccordionSummaryText = (selectedRightsReconductionType: RightsReconductionType, restitutionMethod: RestitutionMethod): string => {
    return selectedRightsReconductionType === 'KEEP'
      ? t('endOfMillesimeReportPanel.selectedTypeLabel.KEEP')
      : t(`endOfMillesimeReportPanel.selectedTypeLabel.DROP.${restitutionMethod}`);
  };

  const handleDownloadReceipt = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (isDownloadingReceipt) {
      return;
    }

    onDownloadReceipt?.();
  }, [isDownloadingReceipt]);

  return (
    <div className={millesimeClasses.panelContainer}>
      <div className={millesimeClasses.panelHeader}>
        <Typography variant="inherit" component="div">
          {t('endOfMillesimeReportPanel.title', { year })}
        </Typography>
        {shouldShowInvoiceDownloadLink &&
            <div className={millesimeClasses.downloadLinkContainer}>
              {
                isDownloadingReceipt
                  ? (<CircularProgress color="primary" size={24} />)
                  : (<img alt="paper clip icon" src={paperClipSvg} />)
              }
              <Link href={'#'} underline="always" className={millesimeClasses.downloadLinkLabel} onClick={handleDownloadReceipt}>
                {t('endOfMillesimeReportPanel.downloadReceiptLabel')}
              </Link>
            </div>
        }
      </div>

      <div className={millesimeClasses.panelBody}>
        <Accordion
          className={customAccordionStyle}
          onChange={(event, expanded: boolean) => onCollapsedStateChange?.(!expanded)}
          disabled={!shouldDisplayBeneficiariesReports}
        >
          <AccordionSummary
            className={accordionClasses.accordionSummary}
            expandIcon={shouldDisplayBeneficiariesReports && <ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={millesimeClasses.millesimeAccordionSummary}>
              <div>
                <Typography variant="inherit" component="div">
                  {getAccordionSummaryText(rightsReconductionType, restitutionMethod)}
                </Typography>
              </div>
              <div>
                {shouldShowRestitutionIban &&
                  <div className="restitutionIbanContainer">
                    <Typography variant="inherit" component="div" className={millesimeClasses.secondaryLabel}>
                      {t('endOfMillesimeReportPanel.restitutionIbanLabel')}
                    </Typography>
                    <Typography variant="inherit" component="div">
                      {restitutionIBAN || '-'}
                    </Typography>
                  </div>
                }
              </div>
              <div>
                {shouldShowRestitutionTotalAmount &&
                  <div className="restitutionAmountContainer">
                    <Typography variant="inherit" component="div" className={millesimeClasses.secondaryLabel}>
                      {t('endOfMillesimeReportPanel.restitutionTotalAmountLabel')}
                    </Typography>
                    <Typography variant="inherit" component="div">
                      {formatCurrencyToEuro(totalAmountOfUnusedCredits)}
                    </Typography>
                  </div>
                }
              </div>
            </div>

          </AccordionSummary>
          <AccordionDetails className={millesimeClasses.accordionDetails}>
            <div className={millesimeClasses.table}>
              <Paper className={millesimeClasses.paper}>
                <div className={millesimeClasses.tableTitleContainer}>
                  <Typography variant="inherit" component="div" className={millesimeClasses.tableTitleLabel}>
                    {
                      reportsCount === null
                      ? (<Skeleton animation="wave" variant="text"/>)
                      : t('endOfMillesimeReportPanel.restitutionDetailsTable.beneficiaryCount', { count: reportsCount })
                    }
                  </Typography>
                  <Tooltip title="exporter au format CSV">
                    <div>
                      <IconButton
                        onClick={onDownloadBeneficiariesReportsCsv}
                        disabled={reportsCount === null || isPreparingBeneficiariesReportsCsv}
                      >
                        {
                          isPreparingBeneficiariesReportsCsv
                            ? (<CircularProgress color="primary" size={20} />)
                            : (
                              <SvgIcon viewBox="0 0 18 18" fontSize="small" style={{color: 'black'}}>
                                <use href={`${downloadIcon}#download`}/>
                              </SvgIcon>
                            )
                        }
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
                <RestitutionDetailsTable paginatedData={beneficiariesReportsData}
                                         onPageChange={onBeneficiariesReportsPageChange}
                                         onPageSizeChange={onBeneficiariesReportsPageSizeChange}
                />
              </Paper>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={millesimeClasses.panelFooter}>
      </div>
    </div>
  );
};

const RestitutionDetailsTable: FunctionComponent<RestitutionDetailsTableProps> = ({
  paginatedData,
  onPageChange,
  onPageSizeChange
}) => {
  const classes = useMillesimeInformationStyles();

  const columns: TableColumn<BeneficiaryMillesimeReport<ProductType, 'DONE'>>[] = [
    {
      id: 'beneficiaryFullName',
      label: 'Collaborateur',
      align: 'left',
      minWidth: 170,
      render: (report: BeneficiaryMillesimeReport<ProductType, 'DONE'>) => `${report.firstName} ${report.lastName}`
    },
    {
      id: 'totalAmountOfUnusedCredits',
      label: 'Montant',
      align: 'left',
      minWidth: 170,

      valueKey: 'totalAmountOfUnusedCredits',
      format: (amount: number) => formatCurrencyToEuro(amount),
    },
  ];

  return <AsyncPaginatedTable
    rowKey={'uid'}
    columns={columns}
    paginatedData={paginatedData}
    onPageChange={onPageChange}
    onPageSizeChange={onPageSizeChange}
    classes={classes}
  />;
}

export default EndOfMillesimeReports;
