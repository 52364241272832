import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useMillesimeBannerStyles } from './MillesimeBannerStyles';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardContent, CircularProgress, Link, Typography } from '@material-ui/core';
import calendarSvg from '../../../assets/calendar-warning.svg';
import arrowSvg from '../../../assets/arrow-right.svg';
import { RightsReconductionType } from '../../../assets/models/agencies/Agency.model';
import ConfirmationModalComponent from '../../../Component/ConfirmationModal/ConfirmationModalComponent';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { MillesimesStore } from '../../../Stores/Millesimes.store';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

type LocalStore = [AgenciesStore, MillesimesStore];

const MillesimeBannerComponent: FunctionComponent<any> = observer(({}) => {
  const [
    agenciesStore,
    millesimesStore,
  ] = useInstances<LocalStore>(AgenciesStore, MillesimesStore);

  const [isSavingOption, setIsSavingOption] = useState<RightsReconductionType | false>(false);
  const shouldDisplayBanner: boolean = millesimesStore.shouldDisplayFoodVoucherCurrentMillesimeBanner;

  useEffect(() => {
    millesimesStore.setSelectedAgency(agenciesStore.currentAgency?.uid);
  }, [agenciesStore.currentAgency]);

  if (!shouldDisplayBanner || !isSavingOption && (millesimesStore.isLoadingCurrentMillesimeStatus || !millesimesStore.foodVoucherCurrentMillesimeStatus)) {
    return null;
  }

  const endingMillesimeYear = millesimesStore.foodVoucherCurrentMillesimeStatus.year;
  const nextMillesimeYear = endingMillesimeYear + 1;
  const selectedRightsReconductionType = millesimesStore.foodVoucherCurrentMillesimeStatus.rightsReconductionType;
  const alreadyDefinedRightsReconductionType = !!selectedRightsReconductionType;

  const handleOnSubmitRightsReconductionType = (selectedType: RightsReconductionType) => {
    setIsSavingOption(selectedType);
    millesimesStore.setSelectedRightsReconductionType('FOOD_VOUCHER', endingMillesimeYear, selectedType)
      .then(() => setIsSavingOption(false));
  };

  return (
    <>
      {(!alreadyDefinedRightsReconductionType)
        ? <RightsReconductionTypesExplorerBanner
          endingMillesimeYear={endingMillesimeYear}
          nextMillesimeYear={nextMillesimeYear}
          isSavingOption={isSavingOption}
          onSubmitButtonClicked={handleOnSubmitRightsReconductionType}
        />
        : <DefinedRightsReconductionTypeBanner
          endingMillesimeYear={endingMillesimeYear}
          nextMillesimeYear={nextMillesimeYear}
          rightsReconductionType={selectedRightsReconductionType}
        />
      }
    </>
  );
});

const RightsReconductionTypesExplorerBanner = ({ endingMillesimeYear, nextMillesimeYear, isSavingOption, onSubmitButtonClicked }) => {
  const style = useMillesimeBannerStyles();
  const { t } = useTranslation('millesime');
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedRightsReconductionType, setSelectedRightsReconductionType] = useState<RightsReconductionType | null>(null);

  const openModal = (rightsReconductionType) => {
    setSelectedRightsReconductionType(rightsReconductionType);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOnSubmitRightsReconductionType = () => {
    onSubmitButtonClicked(selectedRightsReconductionType);
    setModalOpen(false);
  };

  const navigateToMillesimeParameters = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate('/agency/millesime');
  }, []);

  return (
    <div className={style.bannerContainer}>
      <div className={style.bannerHeader}>
        <div className={style.bannerTitleIcon}>
          <img alt="calendar with waring sign icon" src={calendarSvg}/>
        </div>
        <Typography variant="h5" component="div" className={style.bannerTitle}>
          {t('rightsReconductionTypesExplorerBanner.title', { endingMillesimeYear })}
        </Typography>
      </div>

      <div className={style.bannerBody}>
        <RightsReconductionTypeCard
          title={t('rightsReconductionTypesExplorerBanner.optionCardTitle.DROP')}
          content={t('rightsReconductionTypesExplorerBanner.optionCardContent.DROP')}
          disabled={!!isSavingOption}
          loading={isSavingOption === 'DROP'}
          onClick={() => openModal('DROP')}
        />
        <RightsReconductionTypeCard
          title={t('rightsReconductionTypesExplorerBanner.optionCardTitle.KEEP')}
          content={t('rightsReconductionTypesExplorerBanner.optionCardContent.KEEP', { nextMillesimeYear })}
          disabled={!!isSavingOption}
          loading={isSavingOption === 'KEEP'}
          onClick={() => openModal('KEEP')}
        />
      </div>

      <div className={style.bannerFooter}>
        <Typography variant="inherit" component="div">
          {t('rightsReconductionTypesExplorerBanner.linkDescription')}
          {' '}
          <Link href="/agency/millesime" underline="always" className={style.bannerLinkLabel} onClick={navigateToMillesimeParameters}>
            {t('rightsReconductionTypesExplorerBanner.linkLabel')}
          </Link>
        </Typography>
      </div>

      <ConfirmationModalComponent
        title={t('selectedTypeConfirmationModal.title', {endingMillesimeYear})}
        description={t(`selectedTypeConfirmationModal.description.${selectedRightsReconductionType}`)}
        subDescription={t(`selectedTypeConfirmationModal.subDescription.${selectedRightsReconductionType}`)}
        cancelButtonLabel={t('selectedTypeConfirmationModal.cancelButtonLabel')}
        submitButtonLabel={t('selectedTypeConfirmationModal.submitButtonLabel')}
        isOpen={isModalOpen}
        onCancel={closeModal}
        onSubmit={handleOnSubmitRightsReconductionType}
      />
    </div>
  );
};

const RightsReconductionTypeCard = ({ title, content, disabled, loading, onClick }) => {
  const style = useMillesimeBannerStyles();

  return (
    <Card className={style.optionCard}>
      <CardContent>
        <Typography variant="inherit" component="div" className={style.optionCardTitle}>
          {title}
        </Typography>
        <Typography className={style.optionCardText}>
          {content}
        </Typography>
      </CardContent>
      <CardActions className={style.optionCardActions}>
        <Button
          onClick={onClick}
          variant="contained"
          color="primary"
          className={style.optionCardButton}
          disabled={disabled}
          type="button">
          {
            loading ?
              <CircularProgress
                color='secondary'
                style={{ color: 'white' }}
                size={20}
              />
              :
              <img alt="arrow icon" src={arrowSvg}/>
          }
        </Button>
      </CardActions>
    </Card>
  );
};

const DefinedRightsReconductionTypeBanner = ({ endingMillesimeYear, nextMillesimeYear, rightsReconductionType }) => {
  const style = useMillesimeBannerStyles();
  const { t } = useTranslation('millesime');
  const navigate = useNavigate();

  const navigateToMillesimeParameters = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate('/agency/millesime');
  }, []);

  return (
    <div className={style.bannerContainer}>
      <div className={style.bannerHeader}>
        <div className={style.bannerTitleIcon}>
          <img alt="calendar with warning sign icon" src={calendarSvg}/>
        </div>
        <Typography variant="h5" component="div" className={style.bannerTitle}>
          {t('definedRightsReconductionTypeBanner.title', { endingMillesimeYear })}
        </Typography>
      </div>

      <div className={style.bannerDescription}>
        <Typography variant="inherit" component="div">
          {rightsReconductionType === 'DROP'
            ? t('definedRightsReconductionTypeBanner.description.DROP', { endingMillesimeYear })
            : t('definedRightsReconductionTypeBanner.description.KEEP', { nextMillesimeYear })
          }
          {' '}
          <Link href="/agency/millesime" underline="always" className={style.bannerLinkLabel} onClick={navigateToMillesimeParameters}>
            {t('definedRightsReconductionTypeBanner.linkLabel')}
          </Link>
        </Typography>
      </div>
    </div>
  );
};


export default MillesimeBannerComponent;
