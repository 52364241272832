import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { BeneficiariesUploadStore, LocalUploadRef } from '../../Stores/BeneficiariesUpload.store';
import { CircularProgress, Dialog, Portal } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import iconSuccess from '../../assets/mandateSignedSuccess.svg';
import iconSuccessWithFailed from '../../assets/success-with-failed.png';
import iconError from '../../assets/mandateSignedError.svg';
import { useBeneficiariesUploadTrackerStyles } from './BeneficiariesUploadTrackerStyles';
import useDialogStyles from '../../Style/MuiStyles/Dialog';
import {
  AddBeneficiariesUploadSuccessDrawer
} from '../../Beneficiaries/addBeneficiariesByUpload/AddBeneficiariesUploadManager/AddBeneficiariesUploadSuccessDrawer';

const BeneficiariesUploadTrackerComponent = observer(() => {
  const [
    beneficiariesUploadStore,
  ] = useInstances<[BeneficiariesUploadStore]>(BeneficiariesUploadStore);

  const [selectedUploadRef, setSelectedUploadRef] = useState<LocalUploadRef | null>(null);
  const style = useBeneficiariesUploadTrackerStyles();
  const dialogClasses = useDialogStyles();

  const openResults = useCallback((ref) => {
    setSelectedUploadRef(ref);
    beneficiariesUploadStore.closeRef(ref);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setSelectedUploadRef(null);
  }, []);


  return (
    <>
      <Portal container={document.body}>
        <div className={style.wrapper}>
          <div className={style.toastsHost}>
            {beneficiariesUploadStore.activeRefs.filter(ref => !ref.hidden).map(ref => (
              <div key={ref.uid} className={style.toast}>
                {(ref.status === 'PENDING' || ref.status === 'PROCESSING') && (
                  <>
                    <CircularProgress size={32}/>
                    Import de {ref.beneficiaryCount} collaborateurs en cours
                    <div className={style.buttonsWrapper}>
                      <button className={style.button}
                              onClick={() => beneficiariesUploadStore.closeRef(ref)}>Cacher</button>
                    </div>
                  </>
                )}

                {(ref.status === 'DONE') && (
                  <>
                    {(ref.errorCount ?? 0) === 0
                      ? (
                        <>
                          <img src={iconSuccess} alt={'success'} className={style.icon}/>
                          Import terminé
                          <div className={style.buttonsWrapper}>
                            <button className={style.button}
                                    onClick={() => openResults(ref)}>Voir</button>
                            <button className={style.button}
                                    onClick={() => beneficiariesUploadStore.closeRef(ref)}>Fermer
                            </button>
                          </div>
                        </>
                      )
                      : (
                        <>
                          <img src={iconSuccessWithFailed} alt={'success with failed'} className={style.icon}/>
                          Import terminé ({ref.errorCount} erreur{ref.errorCount > 1 ? 's' : ''})
                          <div className={style.buttonsWrapper}>
                            <button className={style.button}
                                    onClick={() => openResults(ref)}>Voir</button>
                            <button className={style.button}
                                    onClick={() => beneficiariesUploadStore.closeRef(ref)}>Fermer
                            </button>
                          </div>
                        </>
                      )}

                  </>
                )}

                {(ref.status === 'FAILED') && (
                  <>
                    <img src={iconError} alt={'error'} className={style.icon}/>
                    L'import a échoué.
                    <div className={style.buttonsWrapper}>
                      <button className={style.button}
                              onClick={() => beneficiariesUploadStore.closeRef(ref)}>Fermer
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </Portal>
      <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth="md" open={!!selectedUploadRef} onClose={handleCloseDrawer}>
        <AddBeneficiariesUploadSuccessDrawer uploadRef={selectedUploadRef} onClose={handleCloseDrawer}/>
      </Dialog>
    </>
  );
});

export default BeneficiariesUploadTrackerComponent;