import React, { FunctionComponent } from 'react';
import FooterComponent from '../../MenuComponent/FooterComponents/FooterComponent';
import logo from '../../assets/logo-text.svg';
import { useNavigate } from 'react-router-dom';
import { useCGVStyles } from './CGVsStyles';

const CGVsComponent:FunctionComponent = () => {
  const cgvStyles = useCGVStyles();
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={cgvStyles.root}>
        <img className={cgvStyles.logo} width={198} alt='logo' src={logo} onClick={() => navigate('/dashboard')} />
        <p className={cgvStyles.title}>Conditions générales</p>
        <p className={cgvStyles.subtitle}>Préambule</p>
        <p className={cgvStyles.content}>
          OPEN! est une société par actions simplifiée immatriculée auprès du registre du commerce et des sociétés de
          Paris sous le numéro 891 446 668 et dont le siège social est sis 13, rue Descombes, 75017
          Paris (&quot;<span className={cgvStyles.textBold}>OPEN!</span>&quot;).
        </p>
        <p className={cgvStyles.content}>
          OPEN! fournit aux employeurs une solution (le &quot;<span className={cgvStyles.textBold}>Service</span>&quot;)
          leur permettant d&lsquo;assurer une prise en charge
          partielle des repas de leurs salariés, conformément aux articles L. 3262-1 et suivants du Code du
          travail relatifs au titre restaurant (la &quot;<span className={cgvStyles.textBold}>Participation Employeur</span>&quot;).
        </p>
        <p className={cgvStyles.content}>
          Les présentes Conditions générales régissent la relation contractuelle entre OPEN! et l’employeur
          (au sens de l’article L. 3262-1 du code du travail) qu&apos;il s&apos;agisse d&apos;une personne physique ou morale
          (l&apos;&quot;<span className={cgvStyles.textBold}>Employeur</span>&quot;).
          L&apos;Employeur déclare et reconnaît être un professionnel au sens du Code de la consommation.
        </p>
        <p className={cgvStyles.content}>
          L&apos;Employeur a fait le choix d&apos;émettre des titres-restaurants, comme le permet le 1° du deuxième alinéa de
          l&apos;article L. 3262-1 du code du travail, et a décidé de s&apos;adjoindre les services d&apos;OPEN! pour l&apos;assister dans
          la mise en œuvre logistique de cette émission.
        </p>
        <p className={cgvStyles.content}>
          Les présentes Conditions générales et leurs annexes prévalent sur tout autre document, sauf accord contraire
          des parties formulé de manière expresse et écrite.
        </p>
        <p className={cgvStyles.content}>
          OPEN! et l&lsquo;Employeur sont désignés collectivement sous le terme de
          &quot;<span className={cgvStyles.textBold}>Parties</span>&quot; et, individuellement, sous celui de
          &quot;<span className={cgvStyles.textBold}>Partie</span>&quot;.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 1. Description du Service</h4>
        <ul>
          <ul>
            <li className={cgvStyles.textList}>1.1 Service à destination des Employeurs</li>
          </ul>
        </ul>
        <p className={cgvStyles.content}>
          Au moins dix jours avant la date de mise à disposition du Service souhaitée par l&lsquo;Employeur, ce dernier communique par tout moyen à OPEN! :
        </p>
        <ul>
          <li className={cgvStyles.textList}>
            le numéro de SIRET de la société concernée ainsi que le nom, le prénom, la nationalité, l&lsquo;adresse et de la
            date de naissance de son mandataire social ;
          </li>
          <li className={cgvStyles.textList}>
            les informations nécessaires à la facturation du Service, en ce compris le siège social de l&lsquo;Employeur, son
            numéro d&lsquo;inscription auprès du registre du commerce et des sociétés et son numéro de TVA intracommunautaire.
          </li>
          <li className={cgvStyles.textList}>
            a quote-part de la Participation Employeur (entre 50 et 60 % du prix du repas) applicable chez l&lsquo;Employeur.
          </li>
          <li className={cgvStyles.textList}>
            le montant de la valeur nominale du titre restaurant applicable aux salariés et stagiaires qui bénéficieront
            du Service (les &quot;<span className={cgvStyles.textBold}>Salariés</span>&quot; ou individuellement,
            le &quot;<span className={cgvStyles.textBold}>Salarié</span>&quot;).
          </li>
          <li className={cgvStyles.textList}>
            le numéro d’IBAN du compte de l&apos;Employeur sur lequel seront opérés les versements par prélèvement
            au titre des présentes Conditions Générales.
          </li>
          <li className={cgvStyles.textList}>
            une adresse de courrier électronique sur laquelle il recevra les informations relatives à la facturation
            et à l’administration.
          </li>
        </ul>
        <p className={cgvStyles.content}>
          Dans les quarante-huit heures suivant la fourniture par l&apos;Employeur des renseignements visés
          au premier alinéa, OPEN! lui offrira un accès à un compte extranet (le &quot;<span className={cgvStyles.textBold}>Compte Extranet</span>&quot;).
        </p>
        <p className={cgvStyles.content}>
          L&apos;Employeur veille à conserver ses éléments d&apos;identification personnels et confidentiels et demeure
          seul responsable de l&apos;utilisation du Compte Extranet.
        </p>
        <p className={cgvStyles.content}>
          Ce Compte Extranet contient les informations permettant à l&apos;Employeur de suivre les données relatives à
          la Participation Employeur et d’extraire les informations nécessaires au remboursement par l’Employeur de
          sa participation au titre restaurant selon le calendrier choisi par lui. Il fera apparaître :
        </p>
        <ul>
          <li className={cgvStyles.textList}>
            la valeur théorique maximale de la Participation Employeur au titre du mois, de l’année en cours,
            du millésime. La valeur théorique maximale de la Participation Employeur s&apos;entend du montant de la
            Participation Employeur dans la situation dans laquelle, chaque jour travaillé, la valeur du repas pour
            chacun des Salariés correspond à la valeur nominale du titre restaurant applicable aux Salariés.
          </li>
          <li className={cgvStyles.textList}>
            la valeur consommée par les Salariés dans le mois, l’année en cours, le millésime.
          </li>
          <li className={cgvStyles.textList}>
            en fin de millésime, le solde entre la valeur théorique maximale de la Participation Employeur cumulée
            pendant le millésime et les consommations réelles cumulées pendant ce même millésime, permettant,
            le cas échéant, la reprise de provision nécessaire à l’ajustement des comptes annuels de l’employeur.
          </li>
        </ul>
        <p className={cgvStyles.content}>
          Il appartient à l&apos;Employeur de renseigner dans son Compte Extranet la liste de l&apos;ensemble des Salariés
          ses salariés et stagiaires qui bénéficieront du Service, leur matricule, date de naissance et le nombre
          de titres restaurant auxquels ils ont droit pour le mois à venir. L&apos;Employeur doit aussi renseigner sur
          son Compte Extranet les numéros IBAN et BIC de chaque Salarié pour qu&apos;il soit procédé au remboursement de
          la Participation Employeur si l’Employeur a choisi de déléguer cette action à Open!.
        </p>
        <p className={cgvStyles.content}>
          Les alinéas qui suivent s&apos;appliquent exclusivement aux Employeurs qui ont choisi de confier à OPEN!
          le remboursement direct de la Participation Employeur aux Salariés.
        </p>
        <p className={cgvStyles.content}>
          Pour les Employeurs qui ont choisi de confier à OPEN! le remboursement direct de la Participation Employeur
          aux Salariés, le Compte Extranet fera apparaitrele montant de l’abondement versé par l&apos;Employeur à la demande
          d&apos;OPEN! pour couvrir la Participation Employeur au titre du mois(«<span className={cgvStyles.textBold}>l’Abondementde
          la Participation Employeur</span>»), de l’année en cours, du millésime.
        </p>
        <p className={cgvStyles.content}>
          Dans ce cas, OPEN! procèdera à un appel de fonds au titre de l’Abondement de la Participation Employeur,
          dans les conditions ci-après définies:
        </p>
        <ul>
          <li className={cgvStyles.textList}>
            dans les trois premiers mois suivant la souscription du Service par l&apos;Employeur, l’appel au titre de
            l&apos;Abondement de la Participation Employeur correspond à la valeur théorique maximale de la Participation
            Employeur pour la totalité des Salariés dont les données sont renseignées sur le Compte Extranet.
          </li>
          <li className={cgvStyles.textList}>
            à partir du quatrième mois, l’appel au titre de l’Abondement de la participation Employeur sera calculé
            sur la base de la consommation réelle des deux premiers mois complets, pour la totalité des Salariés dont
            les données sont renseignées sur le Compte Extranet, sans que le montant de cette abondement ne soit
            inférieur à 90% de la valeur théorique maximale de la Participation Employeur.
          </li>
        </ul>
        <p className={cgvStyles.content}>
          Pour le chargement des droits du mois à venir, l&apos;Employeur verse sur un compte de paiement dédié
          (le &ldquo;<span className={cgvStyles.textBold}>Compte de paiement</span>&ldquo;) ouvert dans un établissement
          de crédit, un établissement de paiement ou un établissement
          de monnaie électronique (<span className={cgvStyles.textBold}>l&apos;&ldquo;Etablissement&ldquo;</span>),
          les fonds correspondant à l’Abondement de la Participation
          Employeur pour le mois à venir. Le versement se fera par prélèvement. <span className={cgvStyles.textBold}>
          A cette fin, l&apos;Employeur déclare expressément
          avoir pris connaissance et accepter les termes de l&apos;accord cadre qui s&apos;appliquera entre lui-même
          et l&apos;Etablissement (Annexe n°3).</span>
        </p>
        <p className={cgvStyles.content}>
          Ces fonds sont affectés au paiement de la Participation Employeur aux Salariés, dans les conditions posées
          par les articles L. 3262-1 et suivants et R. 3262-1 et suivants du Code du travail.
        </p>
        <p className={cgvStyles.content}>
          Si le montant total de la valeur des repas pris par les Salariés auprès des Commerçants s&apos;accroît
          de telle sorte que les fonds versés au titre de l’Abondement de la Participation Employeur risquent de
          ne pas couvrir le total de la Participation Employeur pour l&apos;ensemble des Salariés au titre du mois en cours,
          un appel de fonds complémentaire peut être formulé à tout moment (&ldquo;Appel de fonds complémentaire&ldquo;).
        </p>
        <ul>
          <ul>
            <li className={cgvStyles.textList}>
              1.2 Service à destination des Salariés
            </li>
          </ul>
        </ul>
        <p className={cgvStyles.content}>
          A partir des informations fournies par l&apos;Employeur conformément au premier alinéa de l&apos;Article 1.1 ainsi
          que par l&apos;intermédiaire du Compte Extranet, OPEN! met à la disposition de chacun des Salariés
          une application mobile (<span className={cgvStyles.textBold}>l&apos;&ldquo;Application&ldquo;</span>).
        </p>
        <p className={cgvStyles.content}>
          Les informations permettant le téléchargement de l&apos;Application sont envoyées sur l&apos;adresse électronique communiquée
          par l&apos;Employeur après la fourniture par l&apos;Employeur de l&apos;ensemble des informations visées au premier alinéa
          de l&apos;Article 1.1 ou, le cas échéant, après la notification par l&aposEmployeur de l&apos;arrivée d&apos;un nouveau
          Salarié (Article 2.5) avec les informations visées à l&apos;Article 1.1.
        </p>
        <p className={cgvStyles.content}>
          Lors de sa première connexion à l&apos;Application, le Salarié doit approuver les Conditions générales
          d&apos;utilisation d&apos;OPEN!, créer un compte utilisateur (le &ldquo;<span className={cgvStyles.textBold}>Compte Utilisateur</span>&ldquo;)
          et fournir les informations
          strictement nécessaires à la fourniture du Service. Dans la mesure où il choisit d&apos;opter pour une justification
          des Dépenses éligibles par un service d&apos;openbanking, il doit notamment fournir les coordonnées bancaires du
          compte sur lequel seront identifiées ses données d&apos;openbanking
          (le &ldquo;<span className={cgvStyles.textBold}>Compte bancaire de l&apos;Utilisateur&ldquo;</span>).
        </p>
        <p className={cgvStyles.content}>
          Le Salarié veille à conserver ses éléments d&apos;identification personnels et confidentiels et demeure seul
          responsable de l&apos;utilisation du Compte Utilisateur qu&apos;il aura ouvert sur l&apos;Application.
        </p>
        <p className={cgvStyles.content}>
          Dès l&apos;activation de son Compte Utilisateur et selon la date de démarrage choisie par l’Employeur,
          le Salarié pourra, sous les réserves qui suivent, commencer à bénéficier du remboursement de la Participation
          Employeur au titre des repas acquittés chez n&apos;importe quel commerçant autorisé (le <span className={cgvStyles.textBold}>&ldquo;Commerçant&ldquo;</span>),
          selon la règlementation en vigueur, à accepter le paiement en titres restaurant (la <span className={cgvStyles.textBold}>&ldquo;Dépense éligible&ldquo;</span>).
        </p>
        <p className={cgvStyles.content}>
          Le Salarié bénéficiera, sous réserve du respect des articles R. 3262-6 à R. 3262-10 du Code du travail,
          du remboursement de la Participation Employeur selon les conditions applicables chez l&apos;Employeur lorsqu&apos;il
          aura justifié avoir acquitté le prix d&apos;un repas auprès d&apos;un Commerçant. Cette justification peut intervenir :
        </p>
        <ul>
          <li className={cgvStyles.textList}>
            soit en chargeant sur l&apos;Application une photographie lisible du ticket de Carte bancaire issu du Terminal
            de Paiement Electronique où s’est effectué le règlement de la dépense, permettant de lire de façon distincte
            l&apos;identité du Commerçant (numéro SIRET), le jour du repas et le montant dépensé (TTC). La photographie du
            ticket de Carte bancaire ne pourra donner lieu à un remboursement de la Participation Employeur que pour
            autant qu&apos;elle a été chargée sur l&apos;Application avant la fin du mois de février suivant l&apos;année du repas.
          </li>
          <li className={cgvStyles.textList}>
            soit en optant pour l’identification de la Dépense éligible sur le Relevé d’opérations bancaires
            du compte relié à la Carte bancaire permise grâce au service d&apos;openbanking..
          </li>
        </ul>
        <p className={cgvStyles.content}>
          Le Compte Utilisateur accessible à partir de l&apos;Application fera apparaître, pour chaque Salarié
          le montant actualisé de la Participation Employeur restant à consommer (sous réserve des dépenses en cours d’analyse).
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 2. Obligations de l&apos;Employeur</h4>
        <ul>
          <ul>
            <li className={cgvStyles.textList}>
              2.1 Participation Employeur (cas du remboursement direct par l’Employeur de la Participation Employeur)
            </li>
          </ul>
        </ul>
        <p className={cgvStyles.content}>
          A partir des informations accessibles sur le Compte Extranet, il incombe à l’Employeur de prendre les mesures
          nécessaires pour rembourser la Participation Employeur à chaque Salarié dans des délais et selon
          des modalités conformes aux lois et règlements en vigueur.
        </p>
        <ul>
          <ul>
            <li className={cgvStyles.textList}>
              2.2 Versement des fonds au titre de l&apos;Abondementde la Participation Employeur
              (cas du mandat à Open! pour procéder au remboursements de la Participation Employeur)
            </li>
          </ul>
        </ul>
        <p className={cgvStyles.content}>
          Avant le 25 de chaque mois, l&apos;Employeur verse sur un compte de paiement ouvert auprès de l&apos;Etablissement,
          les fonds correspondant à l’Abondementde la Participation Employeur appelé pour les besoins du mois suivant.
          En cas d&apos;absence d&apos;Abondementde la Participation Employeur dans ce délai, le remboursement de la Participation
          Employeur aux Salariés sera suspendu et ne reprendra qu&apos;à compter du jour du versement de l&apos;Abondementde la Participation Employeur.
        </p>
        <p className={cgvStyles.content}>
          Ce versement est opéré par prélèvement bancaire. Lors de sa première connexion sur le Compte Extranet,
          un mandat de prélèvement est donné par l&apos;Employeur à l&apos;Etablissement pour les besoins du présent Article 2.
          Le prélèvement correspondra au montant de l&apos;Abondement de la Participation Employeur appelé apparaissant sur le Compte Extranet.
        </p>
        <p className={cgvStyles.content}>
          Plus généralement, l&apos;Employeur consent expressément à ce que les montants prélevés le soient à partir des informations apparaissant sur le Compte Extranet.
        </p>
        <p className={cgvStyles.content}>
          En cas d&apos;Appel de fonds complémentaire, l&apos;Employeur accepte que les fonds correspondants soient prélevés pour
          abonder le Compte de paiement dans un délai de deux jours ouvrés suivant la notification de l&apos;Appel de fonds
          complémentaire. En cas d&apos;absence d&apos;abondement conforme à l&apos;Appel de fonds complémentaire dans ce délai,
          le remboursement de la Participation Employeur aux Salariés sera suspendu et ne reprendra qu&apos;à compter du
          jour du versement de l&apos;abondement conforme à l&apos;Appel de fonds complémentaire.
        </p>
        <p className={cgvStyles.content}>
          OPEN! ne fera aucune avance de fonds pour le compte de l&apos;Employeur si les Fonds versés étaient insuffisants
          pour couvrir le total de la Participation Employeur. L&apos;Employeur reste seul responsable vis-à-vis des
          Salariés de l&apos;impossibilité d&apos;honorer la prise en charge de la Participation Employeur.
        </p>
        <p className={cgvStyles.content}>
          L&apos;attention de l&apos;Employeur est attirée sur le fait que l&apos;Abondement de la Participation Employeur et
          le respect des Appels de fonds complémentaires dans les délais stipulés dans les présentes Conditions
          générales constituent une condition indispensable au fonctionnement du Service, à défaut de
          laquelle OPEN! ne pourra pas fournir le Service.
        </p>
        <p className={cgvStyles.content}>
          A la fin du millésime, s&apos;il en existe, les sommes correspondant aux soldes entre l’Abondement de la participation
          Employeur cumulé au cours du millésime et les consommations réelles cumulées au cours du millésime seront
          remboursées par OPEN! à l’Employeur dans un délai de quatorze jours suivant la date à laquelle la demande
          de remboursement a été formulée ou, selon son choix, conservées par OPEN! comme avance pour le millésime suivant.
        </p>
        <ul>
          <ul>
            <li className={cgvStyles.textList}>
              2.3 Législation sur le titre restaurant
            </li>
          </ul>
        </ul>
        <p className={cgvStyles.content}>
          L&apos;Employeur déclare respecter et s&apos;engage à respecter la législation sur le titre restaurant
          (articles L. 3261-1 et suivants et R. 3262-1 et suivants du Code du travail), notamment en ce qui concerne
          les conditions d&apos;éligibilité de ses Salariés à ce dispositif, le taux de la Participation Employeur
          (compris entre 50 et 60 %), et le montant de l&apos;exonération (article 81 19° du Code général des impôts).
        </p>
        <p className={cgvStyles.content}>
          OPEN! ne pourra en aucun cas être tenue responsable de manquements de l&apos;Employeur à la législation relative
          au titre restaurant, notamment aux articles L. 3261-1 et suivants, R. 3262-1 et suivants du
          Code du travail et 81 19° du Code général des impôts.
        </p>
        <ul>
          <ul>
            <li className={cgvStyles.textList}>
              2.4 Paiement du prix
            </li>
          </ul>
        </ul>
        <p className={cgvStyles.content}>
          L&apos;Employeur s&apos;engage à payer à OPEN! le prix de sa prestation tel que défini en Annexe n°1, outre la taxe
          sur la valeur ajoutée en vigueur au moment de l&apos;émission de la facture. Le montant facturé est fonction
          du nombre de Salariés dont les données sont renseignées sur le Compte Extranet.
        </p>
        <p className={cgvStyles.content}>
          L&apos;Employeur accepte d&apos;ores et déjà de se voir facturer le prix de sa prestation par voie électronique,
          les factures électroniques tenant lieu de factures d&apos;origine au sens de la règlementation fiscale.
          Les factures électroniques seront disponibles sur le Compte Extranet.
        </p>
        <p className={cgvStyles.content}>
          Les factures sont payables uniquement dans la devise dans lesquelles elles ont été émises, soit, par défaut, l&apos;Euro.
        </p>
        <p className={cgvStyles.content}>
          Le paiement intervient par prélèvement dans un délai de quarante-huit (48) heures suivant l&apos;émission de la facture.
        </p>
        <p className={cgvStyles.content}>
          En cas de retard de paiement, l&apos;Employeur sera redevable envers OPEN! du paiement d&apos;une pénalité de
          retard égale à trois fois le taux d&apos;intérêt légal et du paiement d&apos;une indemnité forfaitaire de recouvrement de 40 euros par facture.
        </p>
        <ul>
          <ul>
            <li className={cgvStyles.textList}>
              2.5 Exactitude des information fournies
            </li>
          </ul>
        </ul>
        <p className={cgvStyles.content}>
          L&apos;Employeur adresse à OPEN! et sous sa propre responsabilité l&apos;ensemble des informations mentionnées à l&apos;Article 1.1.
        </p>
        <p className={cgvStyles.content}>
          L&apos;Employeur renseigne sur le Compte Extranet toute arrivée et tout départ de Salariés, dans un délai minimum
          de sept jours ouvrés avant ladite arrivée ou ledit départ. Toute entrée d’un Salarié nouveau doit être intégrée
          dans l’Extranet ainsi que la date d’ouverture des droits au titre restaurant décidée par l’Employeur.
          Toute sortie d’un Salarié doit être enregistrée dans l’Extranet ainsi que la date d’arrêt d’accès au
          Service afin que celui-ci soit rendu inactif pour ledit Salarié. Les droits au titre restaurant acquis
          mais non utilisés à cette date sont considérés comme perdus.
        </p>
        <p className={cgvStyles.content}>
          L&apos;Employeur assume seul les conséquences civiles, administratives, sociales et fiscales
          de la fourniture d&apos;informations erronées ou incomplètes à OPEN!.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 3. Responsabilité d&apos;OPEN!</h4>
        <p className={cgvStyles.content}>
          OPEN! fera ses meilleurs efforts pour s&apos;assurer que le Service est fourni à l&apos;Employeur dans les meilleures conditions.
        </p>
        <p className={cgvStyles.content}>
          La responsabilité d&apos;OPEN! ne peut pas être engagée :
        </p>
        <ul>
          <li className={cgvStyles.textList}>
            en cas de difficulté d&apos;installation du Compte Extranet ou de l&apos;Application ou d&apos;utilisation du Service
            par l&apos;Employeur ou par un Salarié qui ne serait pas conforme aux Conditions générales.
          </li>
          <li className={cgvStyles.textList}>
            en cas de mauvais paramétrage des équipements permettant d&apos;accéder au Compte Extranet ou à l&apos;Application.
          </li>
          <li className={cgvStyles.textList}>
            en cas de non-respect par l&apos;Employeur des obligations visées aux présentes Conditions ou par un Salarié,
            notamment la fourniture des informations visées à l&apos;Article 1 et les obligations visées à l&apos;Article 2 des présentes Conditions générales.
          </li>
          <li className={cgvStyles.textList}>
            en cas d&apos;absence de remboursement de la Participation Employeur au Salarié imputable à l&apos;Employeur.
          </li>
          <li className={cgvStyles.textList}>
            en cas de perturbation, d&apos;interruption ou de problème de connexion au Compte Extranet ou à l&apos;Application
            non directement imputable à OPEN!.
          </li>
          <li className={cgvStyles.textList}>
            en cas d&apos;utilisation frauduleuse du Compte bancaire de l&apos;Utilisateur ou d&apos;utilisation du Compte bancaire de
            l&apos;Utilisateur non voulue par ce dernier, dans le cas où ces circonstances n&apos;auraient pas été portées à la
            connaissance d&apos;OPEN! en temps utile, permettant que des mesures soient prises afin de bloquer l&apos;accès au Service.
          </li>
          <li className={cgvStyles.textList}>
            en cas d&apos;impossibilité pour OPEN! de fournir le Service en raison d&apos;un cas de force majeure.
          </li>
          <li className={cgvStyles.textList}>
            en cas d&apos;inexistence, d&apos;erreur ou d&apos;irrégularité du Mandat de prélèvement.
          </li>
          <li className={cgvStyles.textList}>
            en cas de survenance d&apos;un évènement visé à l&apos;Article 4.
          </li>
          <li className={cgvStyles.textList}>
            en cas de remboursement de la Participation Employeur au profit d&apos;un Salarié qui ne serait plus dans les effectifs,
            lorsqu&apos;un tel remboursement résulte d&apos;un manquement de l&apos;Employeur à ses obligations déclaratives
            au titre des Article 1.1 et 2 des présentes Conditions générales.
          </li>
          <li className={cgvStyles.textList}>
            en cas de non-remboursement de la Participation Employeur au profit d&apos;un Salarié, lorsqu&apos;une telle absence
            remboursement résulte d&apos;un manquement de l&apos;Employeur à ses obligations au titre de l&apos; des présentes Conditions générales.
          </li>
        </ul>
        <p className={cgvStyles.content}>
            Il appartient à l&apos;Employeur de vérifier l&apos;application des exonérations fiscales et sociales applicables
            lors de la mise en œuvre des articles L. 3262-1 et R. 3262-1 et suivants du Code du travail, la responsabilité
            d&apos;OPEN! ne pouvant être recherchée en cas de non-application ou d&apos;application partielle de ces exonérations.
        </p>
        <p className={cgvStyles.content}>
            De convention expresse, les Parties conviennent qu&apos;en dehors des cas de faute lourde, la responsabilité
            d&apos;OPEN! ne saurait excéder le montant des frais facturés au titre de la fourniture du Service.
        </p>
        <h4 className={cgvStyles.subtitleList}>ARTICLE 4. Suspension du Service</h4>
        <p className={cgvStyles.content}>
          OPEN! sera en droit de suspendre tout ou partie du Service, sans préavis ni mise en demeure,
          en cas de survenance d&apos;un ou plusieurs des cas suivants :
        </p>
        <ul>
          <li className={cgvStyles.textList}>
            les Abondements de la Participation Employeur n&apos;ont pas été versés par l&apos;Employeur sur le Compte de paiement
            au moment du chargement des droits nouveaux pour le mois.
          </li>
          <li className={cgvStyles.textList}>
            les fonds visés dans un Appel de fonds complémentaire n&apos;ont pas été versés par l&apos;Employeur sur le Compte de
            paiement dans un délai de deux jours ouvrés suivant sa notification.
          </li>
          <li className={cgvStyles.textList}>
            le prix mentionné à l&apos;article 2.3 n&apos;a pas été acquitté dans le délai contractuellement prévu.
          </li>
          <li className={cgvStyles.textList}>
            la notification par l&apos;Employeur d&apos;une perte de ses éléments d&apos;identification pour accéder à son Compte Extranet
            ou en cas de tentative de connexion frauduleuse au Compte Extranet.
          </li>
          <li className={cgvStyles.textList}>
            l&apos;utilisation frauduleuse du Compte bancaire de l&apos;Utilisateur ou une utilisation du Compte bancaire
            de l&apos;Utilisateur non voulue par ce dernier.
          </li>
          <li className={cgvStyles.textList}>
            la notification par un Salarié d&apos;une perte de ses éléments d&apos;identification pour accéder au Compte
            Utilisateur ou en cas de tentative de connexion frauduleuse au Compte Utilisateur.
          </li>
          <li className={cgvStyles.textList}>
            les informations visées à l&apos;alinéa 1er de l&apos;Article 1.1 ne seraient pas maintenues à jour par l&apos;Employeur,
            ne permettant pas d&apos;opérer le Service dans des conditions conformes aux articles L. 3262-1 et suivants et R. 3262-1 et suivants du Code du travail.
          </li>
          <li className={cgvStyles.textList}>
            une suspicion de fraude liée à une utilisation erratique, irrationnelle, incohérente ou dépourvue de
            justification du Service, ou en cas de signalement fait par une autorité publique ou tout tiers eu égard
            au caractère possiblement frauduleux de l&apos;utilisation du Service.
          </li>
          <li className={cgvStyles.textList}>
            une utilisation du Service non conforme aux règles d&apos;ordre public, notamment en ce qu&apos;elle contreviendrait
            aux règles posées par la directive UE 2015/849 et à la loi n°2016-1691 du 9 décembre 2016.
          </li>
        </ul>
        <p className={cgvStyles.content}>
          OPEN! veille, dans la mesure du possible, à ce que la suspension de Service soit limité à ce qui
          est nécessaire pour faire face et remédier à l&apos;incident constaté.
        </p>
        <p className={cgvStyles.content}>
          La responsabilité d&apos;OPEN! ne saurait être recherchée par l&apos;Employeur en cas de suspension du Service intervenue en application du présent Article.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 5. Durée</h4>
        <p className={cgvStyles.content}>
          Les présentes Conditions générales prennent effet à la date de la souscription du Service, et ce pour une
          durée indéterminée.
        </p>
        <p className={cgvStyles.content}>
          A tout moment, et pour quelque cause que ce soit, chaque Partie pourra mettre fin à la relation contractuelle
          par lettre recommandée avec accusé de réception moyennant le respect d’un préavis de trois mois.
        </p>
        <p className={cgvStyles.content}>
          Il pourra également être mis fin à la relation contractuelle, par l’une ou l’autre des Parties, en cas d’inexécution
          par l’autre Partie de l’une quelconque de ses obligations. Sauf disposition particulière expresse, la résiliation
          anticipée interviendra un mois après mise en demeure, signifiée à la Partie défaillante par l’autre Partie par
          lettre recommandée avec accusé de réception, indiquant son intention de faire application du présent article et
          indiquant de façon précise et circonstanciée les manquements contractuels en cause, restée sans effet.
        </p>
        <p className={cgvStyles.content}>
          Dans les sept jours ouvrés suivant la fin de la relation contractuelle, OPEN! remettra à l&apos;Employeur
          un solde de tout compte (le &ldquo;Solde de tout compte&ldquo;) présentant :
        </p>
        <ul>
          <li className={cgvStyles.textList}>
            le Solde, telle qu&apos;il apparaît le premier jour ouvré suivant la fin de la relation contractuelle.
          </li>
          <li className={cgvStyles.textList}>
            l&apos;ensemble des sommes dues par l&apos;Employeur à OPEN!, notamment au titre de l&apos;Article 2.3.
          </li>
        </ul>
        <p className={cgvStyles.content}>
          Le Solde de tout compte sera payé par OPEN! à l&apos;Employeur, ou, selon le cas, par l&apos;Employeur à OPEN!,
          dans un délai de quatorze jours ouvrés suivant la fin de la relation contractuelle.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 6. Données personnelles</h4>
        <p className={cgvStyles.content}>
          Pour les besoins de la fourniture du Service, l’Employeur met à disposition d’OPEN! les informations mentionnées à l&apos;alinéa 1er de l&apos;Article 1.
        </p>
        <p className={cgvStyles.content}>
          Les obligations des Parties relatives aux données personnelles sont stipulées dans le Data Protection Agreement (Annexe n°2).
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 7. Propriété intellectuelle</h4>
        <p className={cgvStyles.content}>
          Les créations relatives au Compte Extranet et de l&apos;Application ainsi que leurs contenus, en ce compris les logos,
          marques, noms de domaine, graphismes, images, photographies, animations, textes et tout autre élément visuel
          ou sonore, sont protégées par la législation relative à la propriété intellectuelle et/ou industrielle en
          vigueur en France, et ce pour le monde entier.
        </p>
        <p className={cgvStyles.content}>
          OPEN! et/ou ses prestataires sont titulaires de l’intégralité des droits y afférents. Ces créations et ces
          contenus ne peuvent être ni reproduits, ni utilisés, ni modifiés, ni altérés, ni transférés, totalement ou
          partiellement, sans l’autorisation expresse d&apos;OPEN! ou de ses partenaires.
        </p>
        <p className={cgvStyles.content}>
          L&apos;Employeur autorise expressément OPEN! à citer le nom de la société ou de la
          structure juridique de l&apos;Employeur à titre de référence commerciale.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 8. Cession ou transfert du contrat</h4>
        <p className={cgvStyles.content}>
          OPEN! pourra céder ou transférer le contrat à tout tiers de son choix, qui serait alors tenu des mêmes droits et obligations d&apos;OPEN!.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 9. Intégralité</h4>
        <p className={cgvStyles.content}>
          Les annexes forment partie intégrante des Conditions générales.
        </p>
        <p className={cgvStyles.content}>
          Si l&apos;une des clauses des Conditions générales était affectée de nullité, réputée non écrite,
          inapplicable ou inopposable pour quelque cause que ce soit, la validité des Conditions générales
          ne s&apos;en trouverait pas affectée et les autres clauses continueraient de s&apos;appliquer.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 10. Renonciation</h4>
        <p className={cgvStyles.content}>
          Le fait pour une Partie de ne pas solliciter l&apos;application d&apos;une clause des Conditions générales ou de
          tarder à solliciter l&apos;application d&apos;une clause ne saurait être considéré commune une renonciation aux droits découlant de ladite clause.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 11. Référence commerciale</h4>
        <p className={cgvStyles.content}>
          Les Parties conviennent qu&apos;OPEN! peut utiliser le nom de l&apos;Employeur à titre de référence commerciale.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 12. Evolution des Conditions générales</h4>
        <p className={cgvStyles.content}>
          Les Conditions générales sont accessibles sur le Compte Extranet. OPEN! se réserve le droit d&apos;apporter
          des modifications aux Conditions générales lorsqu&apos;elles répondent à des besoins d&apos;opérabilité,
          de bon fonctionnement du Service ou des raisons de sécurité.
        </p>
        <p className={cgvStyles.content}>
          La nouvelle version des Conditions générales sera accessible sur le Compte Extranet.
        </p>
        <h4 className={cgvStyles.subtitleList}>Article 13. Droit applicable</h4>
        <p className={cgvStyles.content}>
          Les Conditions générales sont régies par le droit français.
        </p>
        <p className={cgvStyles.content}>
          Si l&apos;une quelconque des stipulations des Conditions générales était déclarée nulle ou sans effet,
          une telle stipulation sera  interprétée de manière à refléter au mieux les intentions des parties
          et les autres stipulations resteront applicables.
        </p>
        <p className={cgvStyles.content}>
          Toute réclamation de l&apos;Employeur doit être adressée par écrit à OPEN! par courrier électronique
          à contact-pro@openeat.io ou par courrier recommandé avec accusé de réception à l&apos;adresse de son siège
          social accessible sur toute sa documentation papier et digitale. OPEN! fera ses meilleurs efforts
          pour traiter les réclamations qui lui sont soumises dans les meilleurs délais.
        </p>
        <p className={cgvStyles.content}>
          Tout litige relatif à l&apos;exécution, l&apos;inexécution, la validité, l&apos;interprétation, la résolution,
          la résiliation ou le terme des Conditions générales sera soumis aux tribunaux compétents du ressort de la Cour d&apos;appel de Paris.
        </p>
        <p className={cgvStyles.content}>
          De convention expresse, le délai de prescription applicable aux actions relatives à l&apos;exécution,
          l&apos;inexécution, la validité, l&apos;interprétation, la résolution, la résiliation ou le terme des Conditions générales est fixé à un an.
        </p>
        <div>
          <p className={cgvStyles.textBottom}>
            ANNEXE N°1 – CONDITIONS TARIFAIRES
          </p>
          <p className={cgvStyles.textBottom}>
            ANNEXE N°2 – DATA PROCESSING AGREEMENT
          </p>
          <p className={cgvStyles.textBottom}>
            ANNEXE N°3 – FRAMEWORK AGREEMENT
          </p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default CGVsComponent;
