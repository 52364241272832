import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StyledTextField from '../../../Style/MuiStyles/TextField';
import { IUser } from '../../../App';
import Button from '../../../Style/MuiStyles/Button';
import { CompanyUserService } from '../../../app/api/mapper';
import { FormikHelpers, useFormik } from 'formik';
import Yup from '../../../i18n/validation';
import { COLORS } from '../../../Style/Style';
import close from '../../../assets/close.svg';

const useFooterContactComponentStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    padding: '2rem',
    color: COLORS.textPrimary
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20px'
  },
  imgClose: {
    cursor: 'pointer'
  },
  subtitle: {
    paddingBottom: '70px'
  },
  input: {
    marginBottom: '26px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '4rem 0'
  },
  buttonClose: {
    marginRight: '20px'
  }

}));

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  subject: Yup.string().required(),
  content: Yup.string().required()
});

export interface IFooterContactComponentProps {
    user?: IUser;
    onClose: () => void;
}

interface FormValues {
    email: string;
    subject: string;
    content: string;
  }

function FooterContactComponent (props: IFooterContactComponentProps) {
  const footerContactClasses = useFooterContactComponentStyles();
  const { t } = useTranslation(['footer', 'common']);

  const sendMessage = (
    { email, subject, content }: FormValues,
    setSubmitting: (isSubmitting:boolean) => void
  ) => {
    setSubmitting(true);
    CompanyUserService.sendContactMessage(
      { email, subject, content }
    )
      .then(() => {
        props.onClose();
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik({
    initialValues: {
      subject: '',
      content: '',
      email: props.user?.email ?? ''
    },
    validationSchema: validationSchema,
    onSubmit: (
      values: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>
    ) => {
      sendMessage(values, setSubmitting);
    }
  });
  return (
    <div className={footerContactClasses.root}>
      <div className={footerContactClasses.header}>
        <h1>{t('title')}</h1>
        <img className={footerContactClasses.imgClose} alt='close' src={close} onClick={props.onClose} />
      </div>
      <div className={footerContactClasses.subtitle}>{t('subtitle')}</div>
      <form onSubmit={formik.handleSubmit} noValidate>
        <StyledTextField
          className={footerContactClasses.input}
          id='email'
          name='email'
          label={t('emailLabel')}
          type='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          required
          placeholder={t('emailPlaceholder')}
          error={Boolean(formik.errors.email) && formik.touched.email}
        />
        <StyledTextField
          className={footerContactClasses.input}
          id='subject'
          name='subject'
          label={t('subjectLabel')}
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.subject) && formik.touched.subject}
          fullWidth
          required
          placeholder={t('subjectPlaceholder')}
        />
        <StyledTextField
          id='content'
          name='content'
          label={t('contentLabel')}
          value={formik.values.content}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.content) && formik.touched.content}
          multiline
          rows={20}
          rowsMax={Infinity}
          fullWidth
          required
          placeholder={t('contentPlaceholder')}
        />
        <div className={footerContactClasses.footer}>
          <Button className={footerContactClasses.buttonClose} variant='contained' color='secondary' onClick={props.onClose}>
            {t('common:close')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            isFetching={formik.isSubmitting}
            disabled={formik.isSubmitting}>
            {t('common:send')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FooterContactComponent;
