import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../Style/Style';

export const useMillesimeInformationStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: '30px',
  },
  panelContainer: {
    padding: '20px',
    borderRadius: '20px',
    backgroundColor: COLORS.backgroundBlock,
  },
  panelHeader: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '2em',
    padding: '10px 16px',
  },
  downloadLinkContainer: {
    display: 'flex',
  },
  downloadLinkLabel: {
    fontSize: '14px',
    color: COLORS.textPrimary,
    marginLeft: '5px',
  },
  panelBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  defaultReconductionContent: {
    padding: '20px'
  },
  radioItem: {
    padding: '10px',
  },
  millesimeAccordion: {
    backgroundColor: 'inherit',
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  millesimeAccordionSummary: {
    display: 'grid',
    gap: '20px 100px',
    gridTemplateColumns: '600px 240px auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
    alignItems: 'end',
    width: '100%',
    color: COLORS.textPrimary,
    fontSize: '14px',
  },
  restitutionIbanContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryLabel: {
    color: COLORS.textMuted,
    fontSize: '14px',
    marginBottom: '10px',
  },
  restitutionAmountContainer: {},
  accordionDetails: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1em',
  },
  table: {
    backgroundColor: COLORS.white,
    borderRadius: '20px',
    width: '100%',
  },
  infoMessage: {
    color: COLORS.textMuted,
    fontSize: '14px',
  },
  panelFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  reportListsContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
    gap: '20px',
  },
  reportsListsLoaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '120px',
  },
  paper: {
    padding: '20px',
    border: 'none',
    borderRadius: '10px',
    boxShadow: 'none',
  },
  tableTitleContainer: {
    padding: '16px',
    paddingTop: '0',
    marginBottom: '10px',
    color: COLORS.textPrimary,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  tableTitleLabel: {
    flexGrow: 1,
  },
  tableCell: {
    color: COLORS.textPrimary,
    backgroundColor: `${COLORS.white} !important`,
    border: 'none !important',
  },
  tablePagination: {
    color: COLORS.textPrimary,
  },
}));
