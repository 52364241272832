import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageBlock, useAppBarStyles } from '../Style/Style';
import { useTranslation } from 'react-i18next';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { provider, useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { AccountingViewStore } from '../Stores/viewStore/AccountingView.store';
import { AgenciesStore } from '../Stores/Agencies.store';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import InvoicesComponent from './tabs/Invoices/InvoicesComponent';
import MillesimeReceiptsComponent from './tabs/MillesimeReceipts/MillesimeReceiptsComponent';
import DeactivationsReportComponent from './tabs/Deactivations/DeactivationsReportComponent';

const AccountingComponent: FunctionComponent = provider(AccountingViewStore)(observer<AgenciesStore>(() => {
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);

  const { t } = useTranslation('accounting');
  const location = useLocation();
  const classes = useAppBarStyles();

  const [accountingViewStore, setAccountingViewStore] = useState<AccountingViewStore | null>(null);

  useEffect(() => {
    const agency: AgencyModel | undefined = agenciesStore.currentAgency;
    if (agency) {
      setAccountingViewStore(new AccountingViewStore(agency));
    } else {
      setAccountingViewStore(null);
    }
  }, [agenciesStore.currentAgency]);

  return (
    <>
      {accountingViewStore &&
          <PageBlock>
              <h1>{t('pageTitle')}</h1>
              <AppBar
                  position="static"
                  elevation={0}
                  className={classes.appBar}
              >
                  <Tabs
                      value={location.pathname}
                      aria-label={t('pageSubtitle')}
                  >
                      <Tab className={classes.tab}
                           label={t('tabAgencyInvoice')}
                           component={Link}
                           to={'/accounting/invoices'}
                           value={'/accounting/invoices'}>

                      </Tab>
                      <Tab className={classes.tab}
                           label={t('tabMillesimeReceipt')}
                           component={Link}
                           to={'/accounting/millesime-receipts'}
                           value={'/accounting/millesime-receipts'}>

                      </Tab>
                      <Tab className={classes.tab}
                           label={t('tabDeactivations')}
                           component={Link}
                           to={'/accounting/deactivations'}
                           value={'/accounting/deactivations'}>

                      </Tab>
                  </Tabs>
              </AppBar>

            {location.pathname === '/accounting/invoices'
              && <InvoicesComponent accountingStore={accountingViewStore}/>
            }

            {location.pathname === '/accounting/millesime-receipts'
              && <MillesimeReceiptsComponent accountingStore={accountingViewStore}/>
            }

            {location.pathname === '/accounting/deactivations'
              && <DeactivationsReportComponent accountingStore={accountingViewStore} agenciesStore={agenciesStore}/>
            }
          </PageBlock>
      }
    </>
  );
}));

export default AccountingComponent;