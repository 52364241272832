import React, { FunctionComponent, useCallback } from 'react';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import copyToClipboard from '../../assets/copyToClipboard.svg';
import { useCopyButtonStyles } from './CopyButtonStyles';
import classNames from 'classnames';

interface ICopyButtonProps {
  value: string;
  className?: string;
}

const CopyButton: FunctionComponent<ICopyButtonProps> = (props: ICopyButtonProps) => {
  const copyButtonStyles = useCopyButtonStyles(props);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  async function copyLabelToClipboard() {
    handleTooltipOpen();
    await navigator.clipboard.writeText(props.value);
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copié !"
          placement="top-end"
        >
          <button className={classNames(copyButtonStyles.copyBtn, props.className)} onClick={() => copyLabelToClipboard()}>
            <img alt="copy to clipboard logo"
                 src={copyToClipboard}/>
          </button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export default CopyButton;