import { FunctionComponent, useEffect } from 'react';
import { axeptioCookiesIds, EnvCookiesIds } from '../Constants/axeptioCookiesIds';
import { CURRENT_ENV } from '../assets/api-mapping';

type availableEnvs = 'DEV' | 'DEMO' | 'UAT' | 'PROD'

const CookiesComponent: FunctionComponent = () => {
  const cookiesConfig: EnvCookiesIds = axeptioCookiesIds[CURRENT_ENV as availableEnvs];

  useEffect(() => {
    window['axeptioSettings'] = cookiesConfig;
    ((d: Document, s: 'script') => {
      const isScriptLoaded: boolean = d.getElementsByClassName('axeptio-script-selector').length > 0;
      if (isScriptLoaded) {
        return;
      }
      const t: HTMLScriptElement = d.getElementsByTagName(s)[0], e: HTMLScriptElement = d.createElement(s);
      e.async = true;
      e.src = '//static.axept.io/sdk.js';
      e.classList.add('axeptio-script-selector');
      t.parentNode.insertBefore(e, t);
    })(document, 'script');

  }, []);

  return (<>
  </>);
};

export default CookiesComponent;