import { Button } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import rockIcon from '../../assets/rock.svg';
import uploadIcon from '../../assets/upload.svg';
import { useFeatureFlag } from '../../Hooks/useFeatureFlag';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { useSelectMethodRechargingStyles } from './Styles';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { AgenciesStore } from '../../Stores/Agencies.store';

type LocalStore = [AgenciesStore, StepperRechargingStore, RechargingViewStore];

const SelectMethodRechargingComponent: FunctionComponent = observer(() => {
  const [agenciesStore, stepperRechargingStore, rechargingViewStore]: [AgenciesStore, StepperRechargingStore, RechargingViewStore] = useInstances<LocalStore>(AgenciesStore, StepperRechargingStore, RechargingViewStore);
  const { t } = useTranslation('reloading');
  const reloadChoiceClass: ClassNameMap<'block' | 'title' | 'subTitle' | 'blockContainer'> = useSelectMethodRechargingStyles();

  const { enabled: isUploadEnabled } = useFeatureFlag('uploadBeneficiariesRecharging');
  const { isCreateOrderLocked } = agenciesStore;

  useEffect(() => {
    if (!sessionStorage.getItem('reloadingDateKey')) {
      rechargingViewStore.setInitialData();
    }
  }, []);

  return (
    <>
      <h1 className={reloadChoiceClass.title}>{t('titleChoice')}</h1>
      <h2 className={reloadChoiceClass.subTitle}>{isCreateOrderLocked ? 'Un rechargement est déjà en cours, veuillez patienter...' : t('subTitleChoice')}</h2>
      <div className={reloadChoiceClass.blockContainer}>
        {isUploadEnabled && <div className={reloadChoiceClass.block}>
            <div style={{ textAlign: 'center' }}>
                <img width={70} alt={t('selectMethod.uploadMethod')} src={uploadIcon}/>
            </div>
            <Button
                variant="contained"
                disabled={isCreateOrderLocked}
                fullWidth
                onClick={() => {
                  stepperRechargingStore.goToStep('ADD_NEW_BENEFICIARIES_UPLOAD');
                }}
                color="primary">
              {t('selectMethod.uploadMethod')}
            </Button>
        </div>}
        <div className={reloadChoiceClass.block}>
          <div style={{ textAlign: 'center' }}>
            <img width={53} alt={t('selectMethod.manualMethod')} src={rockIcon}/>
          </div>
          <Button
            variant="contained"
            disabled={isCreateOrderLocked}
            fullWidth
            onClick={() => {
              stepperRechargingStore.goToStep('PARAMETERS');
            }}
            color="primary">
            {t('selectMethod.manualMethod')}
          </Button>
        </div>
      </div>
    </>
  );
});

export default SelectMethodRechargingComponent;
