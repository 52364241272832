import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMethodDissociatedStyles } from './MethodDissociatedBankTransfer.styles';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { CircularProgress } from '@material-ui/core';

type LocalStore = [AgenciesStore];

const MethodDissociatedBankTransfer = observer(() => {

  const styles = useMethodDissociatedStyles();
  const { t } = useTranslation('payment');

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {paymentBankAccountMain, paymentBankAccountFees, isBankAccountsLoading}: AgenciesStore = agenciesStore;

  return (
    <div>
      <div className={styles.container}>
        <h2 className={styles.title}>
          {t('dissociatedBankTransfer.mainTitle')}
        </h2>
        <div className={styles.iban}>
          <p>{t('dissociatedBankTransfer.mainLabel')}</p>
          <p className={styles.ibanNumber}>
            {
              isBankAccountsLoading
                ? <CircularProgress style={{ marginLeft: 15 }} size={25} color={'primary'}/>
                : t('bankTransfer.iban', { iban: paymentBankAccountMain?.iban ?? '-' })
            }
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <h2 className={styles.title}>
          {t('dissociatedBankTransfer.feesTitle')}
        </h2>
        <div className={styles.iban}>
          <p>{t('dissociatedBankTransfer.feesLabel')}</p>
          <p className={styles.ibanNumber}>
            {
              isBankAccountsLoading
                ? <CircularProgress style={{ marginLeft: 15 }} size={25} color={'primary'}/>
                : t('bankTransfer.iban', { iban: paymentBankAccountFees?.iban ?? '-' })
            }
          </p>
        </div>
      </div>
    </div>
  )
})

export default MethodDissociatedBankTransfer;