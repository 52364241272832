import { Snackbar, SnackbarContent } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router';
import closedEye from '../assets/closed-eye.svg';
import eye from '../assets/eye.svg';
import logoText from '../assets/logo-text-new.svg';
import { LoginRequest } from '../assets/requests/auth/Login.request';
import { setUserEmail } from '../Services/httpWrapper.service';
import { UserConnectStore } from '../Stores/UserConnect.store';
import { LoginViewStore } from '../Stores/viewStore/LoginView.store';
import Button from '../Style/MuiStyles/Button';
import StyledTextField from '../Style/MuiStyles/TextField';
import { regexPassword } from '../Utils/regexList';
import { useConnectionStyles } from './Login/Connection/ConnectionStyles';
import { useLoginComponentStyles } from './Login/LoginStyles';
import { FirebaseError } from '@firebase/util';

type LocalStore = [UserConnectStore, LoginViewStore];

interface LocalFormValue {
  password: string;
}

const LoginPassword = observer(() => {

  const [userConnectStore, loginViewStore]: LocalStore = useInstances<LocalStore>(UserConnectStore, LoginViewStore);
  const loginComponentClasses = useLoginComponentStyles();
  const connectionClasses = useConnectionStyles();
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const isPasswordHidden: boolean = loginViewStore.isPasswordHidden;
  const [loginErrorMessage, setLoginErrorMessage] = useState(null);

  useEffect(() => {
    if (!loginViewStore.email) {
      navigate('/login');
    }
  }, [loginViewStore.email]);

  async function loginPassword({ password }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) {
    setSubmitting(true);
    const request: LoginRequest = {
      userName: loginViewStore.email,
      password: password,
    };

    try {
      await userConnectStore.login(request);

      if (userConnectStore.loginStatus === 'SUCCESS') {
        setUserEmail(loginViewStore.email);
        navigate('/dashboard');
      }
    } catch (e: any) {
      let errorMessage: string = e.message ?? t('unknownLoginError');
      if (e instanceof FirebaseError) {
        // see list of firebaseError codes here https://firebase.google.com/docs/reference/js/auth#autherrorcodes
        switch (e.code) {
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            errorMessage = t('credentialsError');
            break;
          default:
            break;
        }
      }
      setLoginErrorMessage(errorMessage);
      loginViewStore.setShowErrorSnackbar(true);
    }
    setSubmitting(false);
  }

  function activeSubmit(pass: string) {
    return regexPassword.test(pass);
  }

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => loginPassword(values, setSubmitting),
  });

  function resetPassword() {
    navigate('/login/reinitialisation');
  }

  return (
    <>
      <div className={loginComponentClasses.root}>
        <div className={loginComponentClasses.leftBlock}>
          {t('hello')}<br/>
          {t('hello2')}
        </div>
        <div className={loginComponentClasses.rightBlock}>
          <div className={loginComponentClasses.content}>
            <div className={loginComponentClasses.textAlignCenter}>
              <img className={loginComponentClasses.logo} alt="logo" width="198px" src={logoText}/>
              <p style={{ fontWeight: 'bold' }}>{t('subtitle')}</p>
            </div>
            <form style={{ paddingTop: '3rem' }} onSubmit={formik.handleSubmit} noValidate>
              <StyledTextField
                className={connectionClasses.input}
                id="password"
                name="password"
                label={t('password')}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.password) && formik.touched.password}
                type={isPasswordHidden ? 'password' : 'text'}
                InputProps={{
                  endAdornment: <img
                    style={{ marginTop: '20px', cursor: 'pointer' }}
                    alt={'eye'}
                    width="20"
                    src={isPasswordHidden ? eye : closedEye}
                    onClick={() => loginViewStore.setIsPasswordHidden(!isPasswordHidden)}
                  />,
                }}
                fullWidth
                required
                placeholder={t('passwordPlaceholder')}
                errormessage={formik.errors.password}
                autoComplete="current-password"
              />
              <div className={connectionClasses.forgotPasswordText} onClick={() => resetPassword()}>
                {t('forgotPassword')}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  isFetching={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  type="button"
                  onClick={() => navigate('login')}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant="contained"
                  color={activeSubmit(formik.values.password) ? 'primary' : 'secondary'}
                  isFetching={formik.isSubmitting}
                  disabled={!activeSubmit(formik.values.password)}
                  type="submit"
                >
                  {t('connection')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={loginViewStore.showErrorSnackbar}
        autoHideDuration={3000}
        onClose={() => loginViewStore.setShowErrorSnackbar(false)}
      >
        <SnackbarContent
          className={connectionClasses.snackbarContent}
          message={
            <div className={connectionClasses.snackbarMessage}>
              {loginErrorMessage}
            </div>
          }/>
      </Snackbar>
    </>
  );
});

export default LoginPassword;