import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { PageBlock } from '../../Style/Style';
import BackButton from 'Component/BackButton/BackButtonComponnent';
import Button from '../../Style/MuiStyles/Button';
import AgencyAdminsList from '../Components/Lists/AgencyAdminsList';
import { useNavigate } from 'react-router';

const AgencyAdminsPageComponent = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const handleCreateEmpUser = () => {
    navigate('/settings/add-agencyadmin');
  };

  return (
    <PageBlock>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton
            linkTo={'/settings'}
          />
          <h1>{t('agencyAdmins')}</h1>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateEmpUser}
          type="button">
          {t('addAgencyAdminLabel')}
        </Button>
      </div>
      <AgencyAdminsList/>
    </PageBlock>
  );
});

export default AgencyAdminsPageComponent;