import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../Style/Style';

export const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },
  tableHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    padding: '12px',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.9',
    },
  },
  tableRow: {
    height: '50px',
    backgroundColor: '#F7F7F8',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.9',
    },
  },
  cell: {
    padding: '12px',
    textAlign: 'left',
    '&:first-child': {
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
    },
    '&:last-child': {
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    },
  },
  profileBadge: {
    backgroundColor: COLORS.primary,
    color: COLORS.textPrimary,
    padding: '4px 8px',
    borderRadius: '8px',
  },
  unitBadge: {
    backgroundColor: '#2E4052',
    color: 'white',
    padding: '4px 8px',
    borderRadius: '8px',
    marginRight: '8px',
  },
  arrowCell: {
    padding: '12px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  capabilityBadge: {
    backgroundColor: COLORS.primary,
    color: COLORS.textPrimary,
    padding: '4px 8px',
    borderRadius: '8px',
    marginRight: '8px',
  },
  capabilityReadOnlyBadge: {
    backgroundColor: COLORS.textPrimary,
    color: COLORS.primary,
  },
  sortIcon: {
    marginLeft: '8px',
    transition: 'transform 0.3s ease',
  },
  sortIconAsc: {
    transform: 'rotate(180deg)',
  },
  sortIconDesc: {
    transform: 'rotate(0deg)',
  },
});