import React from 'react';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { useMethodBankTransferStyles } from './MethodBankTransferStyles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { CircularProgress } from '@material-ui/core';

type LocalStore = [AgenciesStore];
type LocalStyles = 'container' | 'title'| 'iban' | 'ibanNumber' | 'subTitle';

const MethodBankTransfer = observer(() => {
  const methodBankStyles: ClassNameMap<LocalStyles> = useMethodBankTransferStyles();
  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const {paymentBankAccountMain, isBankAccountsLoading} = agenciesStore;
  const { t } = useTranslation('payment');

  return (
    <div className={methodBankStyles.container}>
      <h2 className={methodBankStyles.title}>
        {t('bankTransfer.title')}
      </h2>
      <p className={methodBankStyles.subTitle}>
        {t('bankTransfer.subTitle')}
      </p>
      <div className={methodBankStyles.iban}>
        <p>{t('bankTransfer.ibanTitle')}</p>
        <p className={methodBankStyles.ibanNumber}>
          {
            isBankAccountsLoading
              ? <CircularProgress style={{ marginLeft: 15 }} size={25} color={'primary'}/>
              : t('bankTransfer.iban', { iban: paymentBankAccountMain?.iban ?? '-' })
          }
        </p>
      </div>
      <p className={methodBankStyles.subTitle}>
        {t('bankTransfer.note')}
      </p>
    </div>
  );
});

export default MethodBankTransfer;
