import {
  ClickAwayListener,
  Fade,
  makeStyles,
  Paper,
  Popper,
  PopperPlacementType
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { COLORS } from '../Style';

  interface Props {
    content: ReactElement;
    children: ReactElement;
    open: boolean;
    onClose?: () => void;
    arrow?: boolean;
    placement?: PopperPlacementType;
    id?: string;
  }

export const useRichTooltipStyles = makeStyles((theme) => {
  const color = theme.palette.background.paper;
  return {
    popoverRoot: {
      backgroundColor: color,
      maxWidth: 1000,
      borderRadius: '20px',
      padding: '0.75rem',
      marginRight: '-15px',
      border: '1px solid rgba(46,64,82,0.15)',
      width: '232px'
    },
    content: {
      padding: '1rem',
      color: COLORS.error,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
    popper: {
      zIndex: 2000,
      '&[x-placement*="bottom"]': {
        marginTop: '1rem'
      },
      '&[x-placement*="top"]': {
        marginBottom: '1rem'
      },
      '&[x-placement*="right"]': {
        marginLeft: '1rem'
      },
      '&[x-placement*="left"]': {
        marginRight: '1rem'
      },
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-1.35em',
        marginLeft: 30,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%'
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-1.35em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0'
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-1.35em',
        height: '1em',
        width: '1.41rem',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%'
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-1.35em',
        height: '1em',
        width: '1.41rem',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0'
        }
      }
    },
    arrow: {
      zIndex: 2001,
      overflow: 'hidden',
      position: 'absolute',
      width: '2em',
      height: '1.41em' /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: 'border-box',
      color,
      '&::before': {
        border: '1px solid rgba(46,64,82,0.15)',
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)'
      }
    }
  };
});

const RichTooltip = ({
  placement = 'top',
  arrow = true,
  open,
  onClose = () => {},
  content,
  children
}: Props) => {
  const classes = useRichTooltipStyles();
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          cross: {
            enabled: arrow,
            element: arrowRef
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <ClickAwayListener onClickAway={onClose}>
              <Paper className={classes.popoverRoot} elevation={0}>
                {arrow
                  ? (
                    <span className={classes.arrow} ref={setArrowRef} />
                  )
                  : null}
                {content}
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default RichTooltip;
