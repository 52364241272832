import { makeStyles } from '@material-ui/core';
import { COLORS } from '../Style/Style';

const drawerWidth = 150;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    display: 'flex',
    minHeight: 'max(650px, calc(100vh - 210px))',
  },
  topMenu: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  children: {
    backgroundColor: COLORS.backgroundBlock,
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      // height: ' 210px'
    },
  },
  snackbarContent: {
    width: 400,
    height: 330,
    backgroundColor: COLORS.textPrimary,
    color: 'white',
    borderRadius: '20px',
    padding: '0px',
    alignItems: 'start',
  },
  snackbarBlock: {
    padding: '16px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '300px',
  },
  snackbarTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  snackbarText: {
    fontSize: '14px',
    textAlign: 'justify',
  },
  snackbarLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  skip: {
    '&:focus': {
      transform: 'translateY(0%)',
    },
    background: COLORS.primary,
    height: '30px',
    left: '50%',
    padding: '8px',
    position: 'absolute',
    transform: 'translateY(-100%)',
    transition: 'transform 0.3s',
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}));
