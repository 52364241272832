import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';
import { InvoiceModel, InvoiceType } from '../../assets/models/invoices/invoice.model';
import { MillesimeReceiptModel } from '../../assets/models/millesime/MillesimeReceipt.model';
import { AgencyRestitutionReceiptModel } from '@assets/models/accountingDocuments/AgencyAccountingDocument.model';
import { AcceptedRestitutionPaybackSubStatus } from '@assets/models/paybacks/PaybackSubStatus.model';

interface DocumentDetailsStylesProps {
  document?: InvoiceModel | MillesimeReceiptModel;
  invoiceType?: InvoiceType;
  receipt? : AgencyRestitutionReceiptModel;
}

export const useDocumentDetailsStyles = makeStyles(() => ({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px',
  },
  circle: (props: DocumentDetailsStylesProps) => {
    const statusColor = props.document?.status === 'SUCCEEDED'
      ? COLORS.succeededStatus
      : props.document?.status === 'CREATED'
        ? COLORS.pendingStatus
        : COLORS.failedStatus;
    return {
      width: '8px',
      height: '8px',
      backgroundColor: statusColor,
      borderRadius: '8px',
      alignSelf: 'center',
      marginRight: '5px',
    };
  },
  deactivationReportCircle: (props: DocumentDetailsStylesProps) => {
    const statusColor = getBackgroundColor(props.receipt?.paybackSubStatus);
    return {
      width: '8px',
      height: '8px',
      backgroundColor: statusColor,
      borderRadius: '8px',
      alignSelf: 'center',
      marginRight: '5px',
    };
  },
  amount: {
    margin: 0,
    color: COLORS.textPrimary,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px',
  },
  imgClose: {
    cursor: 'pointer',
  },
  subtitle: {
    marginBottom: '84px',
  },
  status: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '44px',
  },
  title2: {
    fontWeight: 'bold',
    marginBottom: '18px',
  },
  month: {
    textTransform: 'capitalize',
  },
  secondBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  provision: {
    marginBottom: '50px',
  },
  subscription: {
    marginBottom: '5px',
  },
  transaction: {
    color: COLORS.grey,
    marginBottom: '40px',
  },
  paymentBlock: {
    width: '100%',
    display: 'flex',
    placeItems: 'flex-end',
    flexDirection: 'column',
    margin: '34px 0px 76px 0px',
  },
  excl: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  vat: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  subTotal: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  total: {
    width: '620px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  buttonBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  download: {
    marginRight: '1rem',
    textDecoration: 'none',
  },
  expectedDescriptor: {
    marginLeft: '1rem',
    fontWeight: 'bold',
  },
  statusBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inheritFontWeight: {
    fontWeight: 'inherit',
  },
  statutDeactivation: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  blockRestitution : {
    marginTop : '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems : 'center',
    marginBottom: '30px'
  },
  restitutionInfoRight: {
    display: 'flex',
  },
  noConsumRestitution: {
    fontWeight: 600
  },
  deactivatedCollab : {
    padding : '8px 0px 8px 0px'
  },
  collabInfos : {
    opacity: '50%'
  },
  totalRestitution : {
    width: '300px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  }
}));

const getBackgroundColor = (status : AcceptedRestitutionPaybackSubStatus) => {
  switch (status) {
    case 'ACCEPTED_RESTITUTION_ON_WALLET':
      return COLORS.succeededStatus;
    case 'ACCEPTED_RESTITUTION_PAYOUT_CREATED':
      return COLORS.primary;
    case 'ACCEPTED_RESTITUTION_PAYOUT_SUCCESS':
      return COLORS.succeededStatus;
    case 'ACCEPTED_RESTITUTION_PAYOUT_FAILED':
      return COLORS.failedStatus;
    default:
      return COLORS.primary;
  }
};
