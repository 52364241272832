import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useUploadManagerStyles } from './AddBeneficiariesUpload.styles';
import AddBeneficiariesUploadSuccess from './AddBeneficiariesUploadSuccess';
import { useInstances } from 'react-ioc';
import { BeneficiariesUploadStore, LocalUploadRef } from '../../../Stores/BeneficiariesUpload.store';
import { BeneficiariesFileUploadModel } from '@assets/models/beneficiaries/BeneficiariesFileUpload.model';
import { CircularProgress } from '@material-ui/core';
import closeIcon from '../../../assets/close.svg';
import { useTranslation } from 'react-i18next';

interface AddBeneficiariesUploadManagerProps {
  uploadRef: LocalUploadRef;
  onClose: () => void;
}

export const AddBeneficiariesUploadSuccessDrawer: FunctionComponent<AddBeneficiariesUploadManagerProps> = (props) => {
  const [beneficiariesUploadStore] = useInstances(BeneficiariesUploadStore);
  const uploadManagerStyles: ClassNameMap<string> = useUploadManagerStyles();
  const { t } = useTranslation('beneficiaries');

  const [fileUploadDetails, setFileUploadDetails] = useState<BeneficiariesFileUploadModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasServerError, setHasServerError] = useState<boolean>(false);

  useEffect(() => {
    if (!props.uploadRef) {
      return;
    }

    setIsLoading(true);
    setHasServerError(false);

    beneficiariesUploadStore.getFileUploadDetails(props.uploadRef)
      .then((uploadDetails) => setFileUploadDetails(uploadDetails))
      .catch(() => setHasServerError(true))
      .finally(() => setIsLoading(false));
  }, [props.uploadRef]);

  const {
    beneficiariesCreated = [],
    beneficiariesUpdated = [],
    beneficiariesFailed = []
  } = fileUploadDetails ?? {};

  return (
    <div className={uploadManagerStyles.drawer}>
      <div className={uploadManagerStyles.drawerHeader}>
        <h1>{t('uploadBeneficiariesResultDrawer.title')}</h1>
        <img className={uploadManagerStyles.imgClose} alt="close" src={closeIcon} onClick={props.onClose}/>
      </div>

      <div className={uploadManagerStyles.block}>

        {isLoading && (
          <CircularProgress size={48}/>
        )}

        {!isLoading && hasServerError && (
          'Erreur'
        )}

        {!isLoading && !hasServerError && fileUploadDetails && (
          <AddBeneficiariesUploadSuccess
            beneficiariesCreated={beneficiariesCreated}
            beneficiariesUpdated={beneficiariesUpdated}
            beneficiariesFailed={beneficiariesFailed}
          />
        )}
      </div>
    </div>
  );
};