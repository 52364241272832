import { CircularProgress, Dialog, MenuItem, Select } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Downarrow from '../../../assets/down-arrow';
import magnifyingGlass from '../../../assets/magnifying-glass.svg';
import { InvoiceModel } from '../../../assets/models/invoices/invoice.model';
import InputComponent from '../../../Component/InputComponent';
import { AccountingViewStore } from '../../../Stores/viewStore/AccountingView.store';
import useDialogStyles from '../../../Style/MuiStyles/Dialog';
import { StyledSelect } from '../../../Style/MuiStyles/Select/Select';
import InvoiceCard from '../../../Component/InvoiceCard/InvoiceCard';
import InvoiceDetailsComponent from './InvoiceDetails/InvoiceDetailsComponent';
import { useDocumentsTabStyles } from '../DocumentsTabStyles';
import { COLORS } from '../../../Style/Style';

const InvoicesComponent: FunctionComponent<any> = observer(({ accountingStore }: {
  accountingStore: AccountingViewStore
}) => {

  const documentsTabClasses = useDocumentsTabStyles();
  const dialogClasses = useDialogStyles();
  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const { t } = useTranslation('accounting');

  const search: string = accountingStore.search;
  const [filteredInvoices, setFilteredInvoices] = useState<InvoiceModel[]>([]);
  const [currentInvoiceKey, setCurrentInvoiceKey] = useState<number | undefined>(undefined);

  const applySearch = (newSearch: string, newInvoices: InvoiceModel[]) => {
    accountingStore.setSearch(newSearch);
    const filterInvoices = newInvoices.filter((i) => {
      const invoiceDate: Date = new Date(i.invoiceDate);
      const statusDate: Date = new Date(i.statusDate);
      const excludingTaxesAmount: number = i.feesAmountHT;
      const includingTaxesAmount: number = i.feesAmountTTC;

      return (
        (t('toPay', { mode: i.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement' }).toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && i.statusDate) ||
        (`${i.paymentMethod === 'DIRECT_DEBIT' ? t('paidDirectDebit') : t('paidBankTransfer')}`.toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && !i.statusDate) ||
        (i.statusDate && statusDate.toLocaleDateString().search(newSearch) !== -1) ||
        i.invoiceReference.toString().search(newSearch) !== -1 ||
        invoiceDate.toLocaleDateString('fr-FR', dateOptions).toLocaleLowerCase().search(newSearch) !== -1 ||
        includingTaxesAmount.toString().search(newSearch) !== -1 ||
        excludingTaxesAmount.toString().search(newSearch) !== -1
      );
    });
    setFilteredInvoices(filterInvoices);
  };

  const getInvoices = (from: Date) => {
    accountingStore.fetchAgencyInvoices(from)
      .then(() => {
        setFilteredInvoices(accountingStore.agencyInvoices);
        applySearch(search, accountingStore.agencyInvoices);
      })
      .catch(e => console.error(e));
  };

  useEffect(() => {
    if (accountingStore.agency) {
      const from = new Date();
      from.setFullYear(from.getFullYear() - 1);

      getInvoices(from);
    }

  }, [accountingStore.agency]);

  const handleClickOpen = (key: number) => {
    setCurrentInvoiceKey(key);
    accountingStore.setOpenDialog(true);
  };

  const handleClose = () => {
    accountingStore.setOpenDialog(false);
  };

  return (
    <>
      {accountingStore.isLoadingAgencyInvoices
        ? <CircularProgress size={64} color={'primary'}/>
        : <>
          <div className={documentsTabClasses.block}>
            <Select
              value={accountingStore.sinceDate}
              onChange={(evt) => {
                let from = new Date();
                switch (evt.target.value) {
                  case '0':
                    from.setMonth(from.getMonth() - 6);
                    break;
                  case '1':
                    from.setFullYear(from.getFullYear() - 1);
                    break;
                  case '2':
                    from.setFullYear(from.getFullYear() - 2);
                    break;
                  default:
                    from = null;
                }
                getInvoices(from);
                accountingStore.setSinceDate(evt.target.value as string);
              }}
              labelId="label"
              id="select"
              input={<StyledSelect/>}
              IconComponent={(props) => Downarrow({ svgColor: COLORS.white, ...props, className: documentsTabClasses.icon })}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="0">{t('since6months')}</MenuItem>
              <MenuItem value="1">{t('since1year')}</MenuItem>
              <MenuItem value="2">{t('since2years')}</MenuItem>
              <MenuItem value="3">{t('sinceBeginning')}</MenuItem>
            </Select>
            <div className={documentsTabClasses.search}>
              <InputComponent
                placeholder={t('invoiceSearchPlaceholder')}
                icon={magnifyingGlass}
                onChange={(search) => applySearch(search, accountingStore.agencyInvoices)}
                value={search}
                iconLeft
              />
            </div>
          </div>
          {filteredInvoices &&
              <>
                  <div className={documentsTabClasses.documentList}>
                    {filteredInvoices?.map((invoice, i) =>
                      <InvoiceCard
                        key={i}
                        invoiceKey={i}
                        invoice={invoice}
                        openInvoice={handleClickOpen}/>,
                    )}
                  </div>
                  <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth="md"
                          open={accountingStore.openDialog}
                          onClose={handleClose}>
                      <InvoiceDetailsComponent invoice={filteredInvoices[currentInvoiceKey]} onClose={handleClose}/>
                  </Dialog>
              </>
          }
        </>
      }
    </>
  );
});

export default InvoicesComponent;
