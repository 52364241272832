import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';

export const fileUploadAnalysisErrorsStyles = makeStyles(() => ({
  errorContainer: {
    margin: '0 auto',
    marginTop: '1rem',
    width: '683px',
    display: 'flex',
    flexDirection: 'column',
  },
  accordionBlockError: {
    backgroundColor: COLORS.lightError,
    borderRadius: 5,
    boxShadow: 'none',
    marginTop: 5,
    '&:before': {
      height: 0,
    },
    paddingBottom: 20,
  },
  registrationNumberError: {
    padding: '.5rem',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  typographyError: {
    fontSize: '14px',
    display: 'flex',
  },
  titleTextError: {
    color: COLORS.error,
    paddingLeft: 10,
    fontWeight: 'bold',
  },
  textError: {
    color: COLORS.error,
    paddingLeft: 100,
    fontWeight: 'normal',
  },
  textErrorUtf8: {
    color: COLORS.error,
    paddingLeft: '1.8rem',
    paddingBottom: '1rem',
    fontWeight: 'normal',
  },
  typographyErrorUtf8: {
    fontSize: '14px',
  },
  textHelpUtf8: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    opacity: 1,
    backgroundColor: 'rgba(247,247,248, 1)',
    padding: '1rem 1.5rem',
  },
  helpUtf8BoxImg: {
    width: '400px',
    padding: '1rem 0',
    paddingLeft: '1.8rem',
  },
  helpUtf8Img: {
    width: '100%',
    objectFit: 'contain',
  },
  warningUtf8: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
  }
}));
