import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { useOrderPaymentStyles } from './OrderPaymentStyles';

type LocalStore = [OrderPaymentViewStore, AgenciesStore];
type LocalStyles =
  'container'
  | 'orderBlockTotalLine'
  | 'OrderDetails'
  | 'paymentOrder'
  | 'orderBlock'
  | 'paymentMethod'
  | 'text'
  | 'title'
  | 'totalHT'
  | 'orderButtonSubmit'
  | 'totalEnd';

const OrderDetails: FunctionComponent = observer(() => {
  const [orderPaymentViewStore, agenciesStore]: LocalStore = useInstances<LocalStore>(OrderPaymentViewStore, AgenciesStore);
  const orderPaymentStyles: ClassNameMap<LocalStyles> = useOrderPaymentStyles();
  const isMultiClass: boolean = agenciesStore.currentAgency?.useBeneficiaryClassification;
  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  const { contractAgencyPart, contractSubscriptionFee } = agenciesStore.currentAgencyDefaultContractPart;

  const {
    dateString,
    tva,
    totalHT,
    totalTTC,
    beneficiaryToRechargeCount,
    provisionTotalDays,
    provisionAmount,
    netAmountPayable,
  } = orderPaymentViewStore;

  const { t } = useTranslation('payment');

  return (
    <div className={orderPaymentStyles.paymentOrder}>

      <div className={orderPaymentStyles.orderBlock}>
        <h3 className={orderPaymentStyles.title}>
          {t('orderDetails.title', { count: beneficiaryToRechargeCount, month: dateString })}
        </h3>
        <p>{t('orderDetails.subscriptionTitle')}</p>
        { !isMultiClass && (<p>{t('orderDetails.subscription', { count: beneficiaryToRechargeCount, subComp: currencyFormat.format(contractSubscriptionFee) })}</p>) }
        { isMultiClass && (<p>{t('orderDetails.subscriptionMultiClass', { count: beneficiaryToRechargeCount })}</p>) }
        <p className={orderPaymentStyles.totalHT}>
          {totalHT}
        </p>
      </div>

      <div className={orderPaymentStyles.orderBlock}>
        <div className={orderPaymentStyles.orderBlockTotalLine}>
          <p>{t('orderDetails.ht')}</p>
          <p>{totalHT}</p>
        </div>
        <div className={orderPaymentStyles.orderBlockTotalLine}>
          <p>{agenciesStore.currentAgency?.vat.label}</p>
          <p>{tva}</p>
        </div>
        <div className={orderPaymentStyles.orderBlockTotalLine}>
          <p>{t('orderDetails.ttc')}</p>
          <p>{totalTTC}</p>
        </div>
      </div>

      <div className={orderPaymentStyles.orderBlock}>
        <h3 className={orderPaymentStyles.title}>
          {t('orderDetails.subtitle', { month: dateString })}
        </h3>
        <p>{t('orderDetails.provisionTitle')}</p>
        { !isMultiClass && (<p>{t('orderDetails.provision', { count: provisionTotalDays, subComp: currencyFormat.format(contractAgencyPart) })}</p>) }
        <p className={orderPaymentStyles.totalHT}>
          {provisionAmount}
        </p>
      </div>

      <div>
        <h3 className={orderPaymentStyles.title}>
          {t('orderDetails.totalTitle')}
        </h3>
        <div className={orderPaymentStyles.orderBlockTotalLine}>
          <p className={orderPaymentStyles.totalEnd}>
            {t('orderDetails.total')}
          </p>
          <p className={orderPaymentStyles.totalEnd}>
            {netAmountPayable}
          </p>
        </div>
      </div>
    </div>
  );
});

export default OrderDetails;
