import { Button, MenuItem, Select } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Downarrow from '../../../assets/down-arrow';
import magnifyingGlass from '../../../assets/magnifying-glass.svg';
import InputComponent from '../../../Component/InputComponent';
import StyledDataGrid from '../../../Style/MuiStyles/Datagrid';
import { StyledSelect } from '../../../Style/MuiStyles/Select/Select';
import { useBeneficiaryVouchersComponentStyles } from './BeneficiaryVouchersStyles';
import { COLORS } from '../../../Style/Style';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import { getDocsWhere } from '../../../Services/firebase.service';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { useInstances } from 'react-ioc';
import { DiscreteVoucherModel } from '@assets/models/discreteVouchers/DiscreteVoucher.model';
import { Timestamp } from 'firebase/firestore';
import { useDebounce } from '../../../Hooks/useDebounce';

const BeneficiaryVouchersComponent: FunctionComponent<IBeneficiaryDetailsProps> = observer((props: IBeneficiaryDetailsProps) => {
  const { beneficiary, onClose } = props;
  const currentDate: Date = new Date();
  const beneficiaryVouchersClasses = useBeneficiaryVouchersComponentStyles();
  const [
    agenciesStore,
  ] = useInstances(AgenciesStore);
  const { currentAgency } = agenciesStore;
  const { t } = useTranslation('beneficiaries');
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search);
  const [sinceDate, setSinceDate] = React.useState('0');
  const [fromDate, setFromDate] = useState(currentDate.setMonth(currentDate.getMonth() - 3));
  const [isFetching, setIsFetching] = useState(true);
  const [vouchers, setVouchers] = useState([]);

  const getVouchers = useCallback(async () => {
    setIsFetching(true);

    try {
      let vouchers: DiscreteVoucherModel[] = await getDocsWhere<DiscreteVoucherModel>(
        `DISCRETE_VOUCHERS/${currentAgency.uid}/CONTRACTED_BENEFICIARIES/${beneficiary.uid}/BENEFICIARY_VOUCHERS`,
        [{ fieldName: 'transactionDate', operator: '>=', value: Timestamp.fromMillis(fromDate) }]);
      setIsFetching(false);

      vouchers = vouchers.map(({ transactionDate, redemptionDate, agencyParticipationAmount, ...rest }) => ({
        ...rest,
        transactionDate: (transactionDate as unknown as Timestamp).toMillis(), // needed for table sorting
        redemptionDate: (redemptionDate as unknown as Timestamp).toMillis(),
        agencyParticipationAmount: agencyParticipationAmount / 100, // cent to euros
      }));

      if (!!debouncedSearch) {
        vouchers = vouchers.filter(voucher => {
          const { transactionDate, redemptionDate, redemptionBusinessName, agencyParticipationAmount } = voucher;
          return [
            new Date(transactionDate).toLocaleDateString(),
            new Date(redemptionDate).toLocaleDateString(),
            redemptionBusinessName,
            String(agencyParticipationAmount)]
            .map(value => value.toLowerCase())
            .some(value => value.includes(debouncedSearch.toLowerCase()));
        });
      }

      setVouchers(vouchers);
    } catch (error) {
      console.error('Failed to fetch vouchers:', error);
    } finally {
      setIsFetching(false);
    }
  }, [debouncedSearch, fromDate, beneficiary.uid, currentAgency.uid]);

  useEffect(() => {
    void getVouchers();
  }, [getVouchers]);

  const dataGridColumns: GridColDef[] = [
    {
      field: 'transactionDate',
      headerName: 'Date de transaction',
      flex: 2,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{new Date(params.value as number).toLocaleDateString('fr-FR')} </div>
        );
      },
    },
    {
      field: 'agencyParticipationAmount',
      headerName: 'Montant à rembourser',
      flex: 2,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(+params.value)}
          </div>
        );
      },
    },
    {
      field: 'redemptionDate',
      headerName: 'Date de validation',
      flex: 3,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            {new Date(params.value as number).toLocaleDateString('fr-FR')}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <p className={beneficiaryVouchersClasses.title}>
        ENSEMBLE DES REMBOURSEMENTS VERSÉS SUR LA PÉRIODE
      </p>
      <div className={beneficiaryVouchersClasses.block}>
        <Select
          value={sinceDate}
          onChange={(evt) => {
            let from: number;
            switch (evt.target.value) {
              case '1':
                from = currentDate.setMonth(currentDate.getMonth() - 6);
                break;
              case '2':
                from = currentDate.setFullYear(currentDate.getFullYear() - 1);
                break;
              case '3':
                from = new Date(0).getTime();
                break;
              case '0':
              default:
                from = currentDate.setMonth(currentDate.getMonth() - 3);
            }

            setSinceDate(evt.target.value as string);
            setFromDate(from);
          }}
          labelId="label"
          id="select"
          input={<StyledSelect/>}
          IconComponent={(props) => Downarrow({
            svgColor: COLORS.white, ...props,
            className: beneficiaryVouchersClasses.icon,
          })}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="0">{t('since3months')}</MenuItem>
          <MenuItem value="1">{t('since6months')}</MenuItem>
          <MenuItem value="2">{t('since1year')}</MenuItem>
          <MenuItem value="3">{t('sinceBeginning')}</MenuItem>
        </Select>
        <div className={beneficiaryVouchersClasses.search}>
          <InputComponent
            placeholder={t('paybackSearchPlaceholder')}
            icon={magnifyingGlass}
            onChange={setSearch}
            value={search}
            iconLeft
          />
        </div>
      </div>

      <StyledDataGrid
        rows={vouchers}
        columns={dataGridColumns}
        showColumnRightBorder={false}
        showCellRightBorder={false}
        disableSelectionOnClick
        loading={isFetching}
      />
      <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '40px' }}>
        <Button
          onClick={onClose}
          type="button"
          variant="contained"
          color="primary">
          {t('close')}
        </Button>
      </div>
    </>
  );
});

export default BeneficiaryVouchersComponent;
