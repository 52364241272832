import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { PageBlock, PageSubtitle, useAppBarStyles } from '../Style/Style';
import AgencyInformationComponent from './tabs/AgencyInformationComponent';
import OrderInformationComponent from './tabs/OrderInformationComponent';
import PaymentInformation from './tabs/PaymentInformation';
import MillesimeInformationComponent from './tabs/MillesimeInformation/MillesimeInformationComponent';
import { AppBar, CircularProgress, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { provider, useInstances } from 'react-ioc';
import { AgenciesStore, AgencyDetailsStore } from '../Stores/Agencies.store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AgencyModel } from '@assets/models/agencies/Agency.model';

type LocalStore = [AgenciesStore, AgencyDetailsStore];

interface TabDefinition {
  label: string;
  path: string;
  Component: FunctionComponent;
  isVisible: (agency?: AgencyModel) => boolean;
}

const tabs: TabDefinition[] = [
  {
    label: 'tabIdentity',
    path: '/agency/information',
    Component: AgencyInformationComponent,
    isVisible: (agency?: AgencyModel) => true
  },
  {
    label: 'tabContract',
    path: '/agency/contract',
    Component: OrderInformationComponent,
    isVisible: (agency?: AgencyModel) => true
  },
  {
    label: 'tabPayment',
    path: '/agency/payment',
    Component: PaymentInformation,
    isVisible: (agency?: AgencyModel) => agency?.isManagedPaymentMode
  },
  {
    label: 'tabMillesime',
    path: '/agency/millesime',
    Component: MillesimeInformationComponent,
    isVisible: (agency?: AgencyModel) => agency?.isManagedPaymentMode
  }
];

const AgencyComponent: FunctionComponent = provider(
  AgencyDetailsStore,
)(observer(() => {
  const [
    agenciesStore,
    agencyDetailsStore,
  ]: [AgenciesStore, AgencyDetailsStore] = useInstances<LocalStore>(AgenciesStore, AgencyDetailsStore);
  const agencyComponentClasses = useAppBarStyles();
  const { t } = useTranslation('agency');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (agenciesStore.currentAgency) {
      agencyDetailsStore.getOneAgency(agenciesStore.currentAgency.uid);
    }
  }, [agenciesStore.currentAgency]);

  const visibleTabs: TabDefinition[] = tabs.filter(tab => tab.isVisible(agenciesStore.currentAgency));
  const activeTab: TabDefinition | null = useMemo(() => {
    if (!agenciesStore.currentAgency) {
      return null;
    }

    const tab = visibleTabs.find(tab => tab.path === location.pathname);

    return tab ?? null;
  }, [location.pathname, agenciesStore.currentAgency, visibleTabs]);

  useEffect(() => {
    if (agenciesStore.currentAgency && !activeTab) {
      navigate('/dashboard');
    }
  }, [agenciesStore.currentAgency, activeTab]);

  return (
    <PageBlock>
      {agencyDetailsStore.isAgencyLoaded || !agencyDetailsStore.agencyDetails
        ? <CircularProgress color={'primary'}/>
        : <>
          <h1>{agencyDetailsStore.agencyDetails.agencyName}</h1>
          <PageSubtitle>
            {`${t('subtitle')} ${agencyDetailsStore.agencyDetails.agencyName}`}
          </PageSubtitle>
          <AppBar
            position='static'
            elevation={0}
            className={agencyComponentClasses.appBar}>
            <Tabs
              value={location.pathname}
              aria-label={t('subtitle')}>
              { visibleTabs.map(tab => (
                  <Tab label={t(tab.label)} className={agencyComponentClasses.tab} component={Link} to={tab.path} value={tab.path} key={tab.label}/>
              )) }
            </Tabs>
          </AppBar>
          { activeTab && (
            <activeTab.Component/>
          ) }
        </>
      }
    </PageBlock>
  );
}));

export default AgencyComponent;
