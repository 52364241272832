import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { COLORS, FONT, FONT_WEIGHT } from '../Style/Style';
const classNames = require('classnames');

const useInputStyles = makeStyles(() => ({
  input: (props: any) => ({
    border: 'none',
    width: '80%',
    backgroundColor: props.intent === 'primary' ? COLORS.backgroundBlock : 'white',
    color: COLORS.textPrimary,
    fontWeight: 'bold',
    fontFamily: FONT.primary,
    '&:focus': {
      outline: 'none'
    },
    '&::placeholder': {
      fontFamily: FONT.primary,
      fontWeight: FONT_WEIGHT.semiBold,
      fontSize: '14px',
      color: COLORS.grey
    }
  }),
  block: (props: any) => ({
    backgroundColor: props.intent === 'primary' ? COLORS.backgroundBlock : 'white',
    width: props.width,
    height: props.title ? '60px' : '50px',
    padding: '0.5rem 1rem',
    borderRadius: '20px',
    fontSize: '14px',
    border: props.intent === 'primary' ? 'none' : `solid 1px ${COLORS.footerBlock}`, //! props.isValid ? 'solid 1px red'
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '24px'
  },
  title: {
    fontWeight: FONT_WEIGHT.semiBold,
    color: COLORS.textPrimary
  },
  required: {
    textAlign: 'right',
    color: COLORS.grey
  },
  contentBlock: (props: IInputComponentProps) => ({
    display: 'flex',
    justifyContent: props.iconLeft ? '' : 'space-between',
    height: '20px'
  }),
  iconLeft: {
    marginRight: '1rem'
  }
}));

export interface IInputComponentProps {
  className?: string;
  pattern?: string;
  style?: React.CSSProperties;
  title?: string;
  placeholder: string;
  isRequired: boolean;
  icon?: string | ((props: any) => any);
  iconLeft: boolean;
  type: string;
  onChange: (value: string) => void;
  value: string;
  onClickIcon?: () => void;
  intent: string;
  width: string;
  required?: boolean;
  checkValidity?: (isValid: boolean) => void;
  disabled? : boolean;
}

function InputComponent (props: IInputComponentProps) {
  const [node, setNode] = React.useState<HTMLInputElement>(null);
  const [isValid, setIsValid] = React.useState(true);
  const inputClasses = useInputStyles({ isValid, ...props });
  const { t } = useTranslation('common');

  const setValidity = (isValid: boolean) => {
    setIsValid(isValid);
    if (props.checkValidity) props.checkValidity(isValid);
  };

  const InputIcon = (props: IInputComponentProps) => {
    return (
      typeof props.icon === 'string'
        ? <img
          alt={props.icon}
          width='20'
          src={props.icon}
          style={{ cursor: props.onClickIcon && 'pointer' }}
          onClick={props.onClickIcon}
        />
        : <>{props.icon({ with: '20px' })}</>
    );
  };

  return (
    <div
      className={classNames(props.className, inputClasses.block)}
      style={props.style}
    >
      {props.title &&
        <div onClick={() => node.focus()}
          className={inputClasses.header}>
          <label className={inputClasses.title}>{props.title}</label>
          {props.isRequired &&
            <span className={inputClasses.required}>{t('required')}</span>}
        </div>
      }
      <div className={inputClasses.contentBlock}>
        {(props.icon && props.iconLeft) &&
          <span className={inputClasses.iconLeft}><InputIcon {...props} /></span>
        }
        <input
          onFocus={() => {}}
          ref={setNode}
          onBlur={(e) => {
            setValidity(e.currentTarget.checkValidity());
          }}
          pattern={props.pattern}
          onChange={evt => {
            if (evt.target.checkValidity()) setValidity(true);
            props.onChange(evt.target.value);
          }}
          className={inputClasses.input}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          required={props.required}
          disabled={props.disabled}
        />
        {(props.icon && !props.iconLeft) &&
          <InputIcon {...props} />
        }
      </div>
    </div>
  );
}

InputComponent.defaultProps = {
  type: 'text',
  isRequired: false,
  placeholder: '',
  value: '',
  intent: 'primary',
  width: '100%',
  iconLeft: false
};

export default InputComponent;
