import React, { FunctionComponent } from 'react';
import { useStyles } from './SummaryCardStyles';
import arrowRight from '../../../assets/arrow-right.svg';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

interface SummaryCardProps {
  isLoading?: boolean;
  number: number;
  label: string;
  linkTo: string;
}

const SummaryCard: FunctionComponent<SummaryCardProps> = ({ number, label, linkTo, isLoading = false, }) => {
  const classes = useStyles();

  return (
    <Link to={linkTo}>
      <div className={classes.card}>
        <div className={classes.number}>
          { isLoading
            ? <Skeleton animation="wave" variant="text" width={'64px'} />
            : number
          }
        </div>
        <div className={classes.footerCard}>
          <div className={classes.label}>{label}</div>
          <div className={classes.icon}>
            <img src={arrowRight} alt="arrow right" className={classes.arrow}/>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SummaryCard;