import { MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { InputSelect } from '../../../Style/MuiStyles/Select/Select';
import Downarrow from '../../../assets/down-arrow';
import { COLORS } from '../../../Style/Style';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import {
  useBeneficiaryAccountInformationsStyles,
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import Yup from '../../../i18n/validation';
import { observer } from 'mobx-react-lite';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { ManagementUnitModel } from '../../../assets/models/agencies/ManagementUnit.model';
import { DEFAULT_MANAGEMENT_UNIT_ID } from '../../../assets/utils/agencies/managementUnit.util';
import { getDefaultAgencyManagementUnit } from '../../../../../../lambdas/shared/utils/agencies/managementUnit.util';

export const AGENCY_MANAGEMENT_UNIT = 'agencyManagementUnitId';

export interface FormValues {
  [AGENCY_MANAGEMENT_UNIT]: string;
}

const SectorizationFormComponent: FunctionFormComponent = observer(({
                                                                      formik, beneficiary, isEditMode = false,
                                                                      disableModification = false, showTitle = true,
                                                                    }) => {
  const [agenciesStore]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({ disabled: disableModification });
  const agencyManagementUnits: ManagementUnitModel[] = agenciesStore.currentAgencyAdminManagementUnits;
  const listOfAgencyManagementUnits = agenciesStore.currentAgency.useAgencyManagementUnit ? agencyManagementUnits : [];

  return (
    <>
      {showTitle && <p className={beneficiariesAccountInformationsClasses.title}>
          SECTORISATION
      </p>}
      {agenciesStore.currentAgency?.useAgencyManagementUnit && (
        <div className={beneficiariesAccountInformationsClasses.select} style={{ width: 'calc(100% - 10px)' }}>
          <Select
            value={formik.values[AGENCY_MANAGEMENT_UNIT]}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(AGENCY_MANAGEMENT_UNIT, evt.target.value)}
            id="managementUnit-select"
            disabled={disableModification}
            input={<InputSelect title="Unité de gestion" required/>}
            IconComponent={(props) => <div
              style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
            </div>}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {
              listOfAgencyManagementUnits?.map((unit) =>
                <MenuItem key={unit.uid} value={unit.uid}>{unit.name}</MenuItem>,
              )
            }
          </Select>
        </div>
      )}
    </>
  );
}) as FunctionFormComponent;

SectorizationFormComponent.getValidationSchema = () => {
  return Yup.object().shape({});
};

SectorizationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [AGENCY_MANAGEMENT_UNIT]: beneficiary?.agencyManagementUnitId ?? DEFAULT_MANAGEMENT_UNIT_ID,
  };
};

export default SectorizationFormComponent;