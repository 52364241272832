import React, { useState, useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import Button from '../../../Style/MuiStyles/Button';
import { makeStyles } from '@material-ui/core';
import PrinterSvg from './../../../assets/printer.svg';

const usePrintQRCodeStyles = makeStyles(() => ({
  wrapper: {
    display: 'none',
  },
  title: {},
  qrCodeContainer: {},
  qrCode: {},
}));

const PrintQRCodeButton = ({ title, qrCodeString, buttonLabel }) => {
  const qrRef = useRef<HTMLDivElement>();
  const styles = usePrintQRCodeStyles();

  const displayPrintPopup = (): void => {
    const div: HTMLDivElement = qrRef.current;
    const elementStr = div.outerHTML;
    const popup = window.open('', 'popup', 'width=512,height=512');

    if (!popup) {
      return;
    }

    popup.document.body.innerHTML = `
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
      .${styles.wrapper} {
        width: 3.5in;
        height: 2in;
        background-color: white;
        border: 1px solid black;
        border-radius: 5px;
        margin: 20px;
        padding: 0.2in;
        font-family: "Montserrat", sans-serif;
        font-size: 10pt;
        color: black;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .${styles.title} {
        font-weight: bold;
        margin: 0 5pt 0 0;
        overflow: hidden;
        text-align: left;
      }
      .${styles.qrCodeContainer} {
        display: flex;
        justify-content: flex-start;
        width: auto;
        height: auto;
      }
      .${styles.qrCode} {
        width: 40%;
        height: auto;
      }
    </style>
    ${elementStr}
    `;

    const script = popup.document.createElement('script');
    script.innerHTML = 'window.print();';
    popup.document.body.appendChild(script);
  };

  return (
    <div>
      <Button
        imgSrc={PrinterSvg}
        onClick={displayPrintPopup}
        type="button"
        variant="contained"
        color="secondary"
      >
        {buttonLabel}
      </Button>

      <div ref={qrRef} className={styles.wrapper}>
        {title && <p className={styles.title}>{title}</p>}
        <div className={styles.qrCodeContainer}>
          <QRCodeSVG value={qrCodeString} size={256} className={styles.qrCode}/>
        </div>
      </div>
    </div>
  );
};

export default PrintQRCodeButton;