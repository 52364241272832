import { makeStyles } from '@material-ui/core';

export const useCopyButtonStyles = makeStyles(() => ({
  copyBtn: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  }
}));
