import { Dialog, DialogContent } from '@material-ui/core';
import React, { FunctionComponent, ReactNode } from 'react';

export interface DialogBoxProps {
  isActive: boolean;
  onClose: (value: boolean) => void;
  children: ReactNode;
}

const DialogBase: FunctionComponent<DialogBoxProps> = (props) => {
  const {children, isActive, onClose} = props;
  return (
    <Dialog
      open={isActive}
      onClose={() => onClose(false)}
    >
      <DialogContent style={{
        padding: '3rem',
      }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default DialogBase;