import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import StyledTextField from '../../Style/MuiStyles/TextField';
import {
  agencyTitle,
  agencyInputContainer,
  agencyInputCol4,
  root,
  agencyClassificationBlock,
  agencyClassificationTitle,
} from '../AgencyStyle';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { getTotalOfAgencyParticipationAmount } from '../../assets/utils/agencies/beneficiaryClassification.util';

const OrderInformationComponent: FunctionComponent = observer(() => {
  const agenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const { t } = useTranslation('agency');
  const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const isMultiClass: boolean = agenciesStore.currentAgency?.useBeneficiaryClassification;

  return (
    <div className={root}>
      <div className={agencyTitle}>{t('contractRepresentative')}</div>
      <div className={agencyInputContainer}>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('firstName')}
            value={agenciesStore.currentAgency?.contractSignatoryFirstName}
            fullWidth
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('lastName')}
            value={agenciesStore.currentAgency?.contractSignatoryLastName}
            fullWidth
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('email')}
            value={agenciesStore.currentAgency?.contractSignatoryEmail}
            fullWidth
          />
        </div>
      </div>

      <div className={agencyTitle}>{t('orderTitle')}</div>
      <div className={agencyInputContainer}>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('startDate')}
            value={moment(agenciesStore.currentAgency?.contractStartDate)?.format('DD/MM/yyyy')}
            fullWidth
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('isManagedPaymentMode')}
            value={agenciesStore.currentAgency?.isManagedPaymentMode ? t('allInclusive') : t('autonomous')}
            fullWidth
          />
        </div>
      </div>

      {
        agenciesStore.currentAgencyBeneficiaryClassification?.map(classification => (
          <div className={agencyClassificationBlock} key={classification.uid}>
            <div className={agencyClassificationTitle}>{ isMultiClass ? classification.name : 'Modalités de l\'abonnement' }</div>
            <div className={agencyInputContainer}>
              <div className={agencyInputCol4}>
                <StyledTextField
                  color='secondary'
                  label={t('priceAmountPerBenificiary')}
                  fullWidth
                  value={
                    !isNaN(classification.contractSubscriptionFee)
                      ? t('priceAmountPerBenificiaryValue', {count: Number(classification.contractSubscriptionFee)})
                      : t('notSpecified')
                  }
                />
              </div>
              <div className={agencyInputCol4}>
                {
                  classification.creditRedemptionMethod === 'LINEAR' && (
                    <StyledTextField
                      color='secondary'
                      label={t('dailyAmount')}
                      fullWidth
                      value={
                        !isNaN(classification.contractFacialValue)
                          ? currencyFormat.format(classification.contractFacialValue)
                          : t('notSpecified')
                      }
                    />
                  )
                }
                {
                  classification.creditRedemptionMethod === 'DISCRETE' && (
                    <StyledTextField
                      color='secondary'
                      label={t('beneficiaryMinimumThreshold')}
                      fullWidth
                      value={
                        !isNaN(classification.beneficiaryMinimumThreshold)
                          ? currencyFormat.format(classification.beneficiaryMinimumThreshold)
                          : t('notSpecified')
                      }
                    />
                  )
                }
              </div>
              <div className={agencyInputCol4}>
                {
                  classification.creditRedemptionMethod === 'LINEAR' && (
                    <StyledTextField
                      color='secondary'
                      label={t('companyQuota')}
                      fullWidth
                      value={
                        !isNaN(classification.contractAgencyPart)
                          ? currencyFormat.format(classification.contractAgencyPart)
                          : t('notSpecified')
                      }
                    />
                  )
                }
                {
                  classification.creditRedemptionMethod === 'DISCRETE' && (
                    <StyledTextField
                      color='secondary'
                      label={t('agencyMaximumParticipation')}
                      fullWidth
                      value={
                        !isNaN(classification.subAmount)
                          ? currencyFormat.format(getTotalOfAgencyParticipationAmount(classification))
                          : t('notSpecified')
                      }
                    />
                  )
                }
              </div>
            </div>
          </div>
        ))
      }


    </div>
  );
});

export default OrderInformationComponent;
