import MailSvg from '../../../assets/mail';
import Button from '../../../Style/MuiStyles/Button';
import { MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { InputSelect } from '../../../Style/MuiStyles/Select/Select';
import Downarrow from '../../../assets/down-arrow';
import { COLORS } from '../../../Style/Style';
import BeneficiaryTextField from './BeneficiaryTextFieldComponent';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import {
  useBeneficiaryAccountInformationsStyles,
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { DEFAULT_BENEFICIARY_CLASSIFICATION_ID } from '../../../assets/utils/agencies/beneficiaryClassification.util';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import Yup from '../../../i18n/validation';
import { observer } from 'mobx-react-lite';
import { BeneficiaryClassificationModel } from '@assets/models/beneficiaries/BeneficiaryClassification.model';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { useFormLock } from '../../../Hooks/useFormLock';

export const REGISTRATION_NUMBER = 'registrationNumber';
export const EMAIL = 'email';
export const EMAIL_LOCK = 'isEmailLocked';
export const CLASSIFICATION = 'beneficiaryClassificationUid';

export interface FormValues {
  [REGISTRATION_NUMBER]: string;
  [EMAIL]: string;
  [EMAIL_LOCK]: boolean;
  [CLASSIFICATION]: string;
}

const timestampToDateString = (timestamp: number): string => {
  return new Date(timestamp).toLocaleDateString();
};

const AccountInformationFormComponent: FunctionFormComponent = observer((props) => {
  const {
    formik, beneficiary, isEditMode, disableModification, setFieldEnabled,
    setFieldDisabled,
  } = props;
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });

  const [isEmailLocked, setIsEmailLocked] = useFormLock(formik, EMAIL_LOCK);

  const [
    agenciesStore,
  ]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);
  const agencyBeneficiaryClassifications = agenciesStore.currentAgencyBeneficiaryClassification;
  const listOfClassifications = agenciesStore.currentAgency.useBeneficiaryClassification ? agencyBeneficiaryClassifications : [];

  useEffect(() => {
    if (isEmailLocked) {
      setFieldDisabled?.(EMAIL);
    } else {
      setFieldEnabled?.(EMAIL);
    }
  }, [isEmailLocked]);

  useEffect(() => {
    if (!beneficiary?.beneficiaryClassificationUid) {
      const baseClassifications: BeneficiaryClassificationModel[] = agenciesStore.currentAgencyBeneficiaryClassification
        ? [...agenciesStore.currentAgencyBeneficiaryClassification]
        : [];
      const sortedClassifications: BeneficiaryClassificationModel[] = baseClassifications
        .sort((a, b) => (a.beneficiaryCount ?? 0) - (b.beneficiaryCount ?? 0));

      const [defaultClassification] = sortedClassifications;
      const defaultUid: string = defaultClassification?.uid ?? '';

      formik.setFieldValue(CLASSIFICATION, defaultUid);
    }
  }, [beneficiary, agenciesStore.currentAgencyBeneficiaryClassification]);

  return (
    <>
      <p className={beneficiariesAccountInformationsClasses.title}>
        INFORMATIONS SUR LE COMPTE
      </p>

      {(beneficiary?.deactivationDate) &&
          <p className={beneficiariesAccountInformationsClasses.beneficiaryDeactivate}>Ce collaborateur est sorti du
              dispositif le : {timestampToDateString(beneficiary.deactivationDate)}</p>
      }

      <div style={{ display: 'inline-flex', width: '100%' }}>
        <div style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}>
          <BeneficiaryTextField
            id={REGISTRATION_NUMBER}
            required
            formik={formik}
            autoFocus
            disabled={isEditMode && !beneficiary?.isActive || disableModification}/>
        </div>
      </div>

      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center',
      }}>
        <div style={{ width: isEditMode ? 'calc(50% - 10px)' : '100%', marginRight: '20px', paddingTop: '20px' }}>
          <BeneficiaryTextField
            id={EMAIL}
            InputProps={{ endAdornment: <MailSvg style={{ marginTop: '20px' }}/> }}
            required
            formik={formik}
            disabled={isEditMode && isEmailLocked || disableModification}
          />
        </div>
        {isEditMode && isEmailLocked &&
            <Button
                onClick={() => setIsEmailLocked(false)}
                type="button"
                variant="contained"
                color={'secondary'}
                style={{
                  width: '30%',
                }}
                disabled={disableModification}
                className={beneficiariesAccountInformationsClasses.hiddenButton}
            >
                Déverrouiller pour modifier
            </Button>
        }
      </div>

      {agenciesStore.currentAgency?.useBeneficiaryClassification && (
        <div className={beneficiariesAccountInformationsClasses.select} style={{ width: 'calc(50% - 10px)' }}>
          <Select
            value={formik.values[CLASSIFICATION]}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(CLASSIFICATION, evt.target.value)}
            id="classification-select"
            disabled={disableModification}
            input={<InputSelect title="Classification" required/>}
            IconComponent={(props) => <div
              style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
            </div>}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {
              listOfClassifications?.map((classification) =>
                <MenuItem key={classification.uid} value={classification.uid}>{classification.name}</MenuItem>,
              )
            }
          </Select>
        </div>
      )}
    </>
  );
}) as FunctionFormComponent;

AccountInformationFormComponent.getValidationSchema = (disabledFields?: string[]) => {
  return Yup.object().shape({
    [EMAIL]: disabledFields && disabledFields.includes(EMAIL)
      ? Yup.string()
      : Yup.string().email().required(),
    [REGISTRATION_NUMBER]: Yup.string().required(),
  });
};

AccountInformationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [REGISTRATION_NUMBER]: beneficiary?.registrationNumber ?? '',
    [EMAIL]: beneficiary?.email ?? '',
    [EMAIL_LOCK]: true,
    [CLASSIFICATION]: beneficiary?.beneficiaryClassificationUid ?? DEFAULT_BENEFICIARY_CLASSIFICATION_ID,
  };
};

export default AccountInformationFormComponent;