import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../Style/Style';

export const useMethodDirectDebitStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.backgroundBlock,
    padding: '30px',
    fontSize: 14
  },
  title: {
    fontSize: 16
  },
  text: {
    fontWeight: 'bold',
    padding: '5px 0'
  },
  blockText: {
    paddingTop: 20
  },
  blockButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 30
  },
  confirmButton: {
    marginLeft: 20,
    marginRight: 20
  }
}));
