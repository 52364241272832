import { style } from 'typestyle';
import { COLORS } from '../Style/Style';

export const StyledStepper = style({
  $nest: {
    '&.stepper': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      padding: '0 0 2rem 0',
      width: '100%',
      $nest: {
        '.step-line:nth-of-type(1)': {
          borderRadius: '3.5px 0px 0px 3.5px'
        },
        '.step-line:nth-last-of-type(1)': {
          borderRadius: '0px 3.5px 3.5px 0px'
        },
        '.step-line-active': {
          backgroundColor: COLORS.textPrimary
        },
        '.step-line-disable': {
          backgroundColor: COLORS.footerBlock,
        },
        '.step-line': {
          display: 'flex',
          height: 8,
          flex: '1 1 auto',
          margin: '6px 0px'
        },
        '.step-circle-active': {
          backgroundColor: COLORS.primary,
        },
        '.step-circle-completed': {
          backgroundColor: COLORS.textPrimary,
          cursor: 'pointer'
        },
        '.step-circle-disable': {
          backgroundColor: COLORS.footerBlock,
        },
        '.step-circle': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '1.25rem',
          width: '1.25rem',
          flex: '0 0 auto',
          borderRadius: '50%',
          margin: '0px 8px'
        },
        '.step-text': {
          position: 'absolute',
          width: 'max-content',
          fontSize: '14px',
          lineHeight: '1rem',
          color: COLORS.textPrimary,
          top: '1.5rem',
          cursor: 'pointer',
          textAlign: 'center',
        },
        '&-completed': {
          $nest: {
            '.step-circle': {
              backgroundColor: '#F39200',
              boxShadow: 'none',
              fontSize: '0.8rem'
            }
          }
        },
        '&-clickable': {
          cursor: 'pointer'
        },
        '.step-text-current': {
          fontWeight: 600,
        }
      }
    }
  }
});
