import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React, { useCallback, useEffect } from 'react';
import {
  useBeneficiaryAccountInformationsStyles,
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import BeneficiaryTextField from './BeneficiaryTextFieldComponent';
import Button from '../../../Style/MuiStyles/Button';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import isValidIBANNumber from '../../../Function/IbanValidator';
import { useFormLock } from '../../../Hooks/useFormLock';

export const IBAN = 'iban';
export const IBAN_LOCK = 'isIbanLocked';

export interface FormValues {
  [IBAN]: string;
  [IBAN_LOCK]: boolean;
}

const BankInformationFormComponent: FunctionFormComponent = (props) => {
  const {
    formik, beneficiary, isEditMode, disableModification, setFieldEnabled, setFieldDisabled,
  } = props;
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });
  const { t } = useTranslation('beneficiaries');

  const [isIbanLocked, setIsIbanLocked] = useFormLock(formik, IBAN_LOCK);

  useEffect(() => {
    if (isIbanLocked) {
      setFieldDisabled?.(IBAN);
    } else {
      setFieldEnabled?.(IBAN);
    }
  }, [isIbanLocked]);

  const getFlag: (iban: string) => string = useCallback((iban: string) => {
    const sanitizedIban: string = iban.trim().toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (!sanitizedIban || sanitizedIban.length < 2) {
      return '';
    }
    const countryCode: string = iban.slice(0, 2);
    return countryCode.toLowerCase();
  }, []);

  return <>
    <p className={beneficiariesAccountInformationsClasses.title}>
      RÉFÉRENCE BANCAIRE
    </p>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ width: isEditMode ? 'calc(50% - 10px)' : '100%', marginRight: '20px', paddingTop: '20px' }}>
        <BeneficiaryTextField
          id={IBAN}
          formik={formik}
          required
          disabled={isEditMode && isIbanLocked || disableModification}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => formik
            .setFieldValue(IBAN, event.target.value.trim().replace(/\s+/g, ''))}
          countryFlag={getFlag(formik.values[IBAN])}
        />
      </div>
      {isEditMode && isIbanLocked &&
          <Button
              onClick={() => setIsIbanLocked(false)}
              type="button"
              variant="contained"
              color={'secondary'}
              style={{ width: '30%' }}
              disabled={disableModification}
              className={beneficiariesAccountInformationsClasses.hiddenButton}
          >
              Déverrouiller pour modifier
          </Button>
      }
    </div>
  </>;
};

BankInformationFormComponent.getValidationSchema = (disabledFields?: string[]) => {
  Yup.addMethod(Yup.string, 'isValidIBANNumber', isValidIBANNumber);

  return Yup.object().shape({
    [IBAN]: disabledFields && disabledFields.includes(IBAN)
      ? Yup.string()
      : (Yup.string() as any).required().isValidIBANNumber(),
  });
};

BankInformationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [IBAN]: beneficiary?.ibanLastNumbers ? `************${beneficiary?.ibanLastNumbers}` : '',
    [IBAN_LOCK]: true,
  };
};

export default BankInformationFormComponent;
