import StyledTextField, { StyledTextFieldProps } from '../../../Style/MuiStyles/TextField';
import {
  useBeneficiaryAccountInformationsStyles
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import React from 'react';

export interface BeneficiaryTextFieldProps extends StyledTextFieldProps {
  formik: any;
  countryFlag?: string;
}

const BeneficiaryTextField = ({ id, formik, countryFlag, ...props }: BeneficiaryTextFieldProps) => {
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({});
  const { t } = useTranslation('beneficiaries');
  return (
    <div style={{ position: 'relative' }}>
      <StyledTextField
        className={beneficiariesAccountInformationsClasses.input}
        id={id}
        name={id}
        label={t(id)}
        value={formik.values[id]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors[id]) && formik.touched[id]}
        errormessage={formik.errors[id]}
        placeholder={t(`${id}Placeholder`)}
        fullWidth
        {...props}
      />
      {countryFlag &&
          <span style={{
            position: 'absolute',
            top: 25,
            right: 100,
          }} className={`fi fi-${countryFlag}`}/>
      }
    </div>);
};

export default BeneficiaryTextField;