import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { GenderModel } from '../../../assets/models/specials/Gender.model';
import { SelectGenderModel } from '@assets/models/specials/selectListData.model';
import { MenuItem, Select } from '@material-ui/core';
import { InputSelect } from '../../../Style/MuiStyles/Select/Select';
import Downarrow from '../../../assets/down-arrow';
import { COLORS } from '../../../Style/Style';
import BeneficiaryTextField from './BeneficiaryTextFieldComponent';
import {
  useBeneficiaryAccountInformationsStyles
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import { FunctionFormComponent } from './FunctionFormComponent.model';

export const CIVILITY = 'title';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';

export interface FormValues {
  [CIVILITY]: GenderModel;
  [FIRST_NAME]: string;
  [LAST_NAME]: string;
}

const IdentityFormComponent: FunctionFormComponent = observer(({ formik, beneficiary, isEditMode = false, disableModification = false }) => {
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });
  const { t } = useTranslation('beneficiaries');

  const listOfTitleBeneficiary: SelectGenderModel[] = [
    { label: 'M.', value: 'Mr' },
    { label: 'Mme', value: 'Mrs' },
    { label: 'Aucun', value: 'Unknown' },
  ];

  return <>
    <p className={beneficiariesAccountInformationsClasses.title}>
      IDENTITÉ
    </p>

    <div className={beneficiariesAccountInformationsClasses.select} style={{ width: 'calc(50% - 10px)' }}>
      <Select
        value={formik.values[CIVILITY]}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(CIVILITY, evt.target.value)}
        id={CIVILITY}
        disabled={disableModification}
        input={<InputSelect title="Civilité" required/>}
        IconComponent={(props) => <div
          style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
        </div>}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {
          listOfTitleBeneficiary.map((title, index) =>
            <MenuItem key={index} value={title.value}>{t(title.label)}</MenuItem>,
          )
        }
      </Select>
    </div>

    <BeneficiaryTextField id={LAST_NAME} disabled={disableModification} formik={formik} required/>

    <BeneficiaryTextField id={FIRST_NAME} disabled={disableModification} formik={formik} required/>
  </>
}) as FunctionFormComponent;

IdentityFormComponent.getValidationSchema = () => {
  return Yup.object().shape({
    [FIRST_NAME]: Yup.string().required(),
    [LAST_NAME]: Yup.string().required(),
  });
}

IdentityFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [CIVILITY]: beneficiary?.title ?? 'Mrs',
    [FIRST_NAME]: beneficiary?.firstName ?? '',
    [LAST_NAME]: beneficiary?.lastName ?? '',
  }
}

export default IdentityFormComponent;