import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ProfilesStyles';
import { PageBlock } from '../../Style/Style';
import BackButton from 'Component/BackButton/BackButtonComponnent';
import Button from '../../Style/MuiStyles/Button';
import ProfilesList from '../Components/Lists/ProfilesList';

const ProfilesPage = observer(() => {
  const { t } = useTranslation('settings');
  const classes = useStyles();

  const handleCreateUser = () => {
    console.log('clicked');
  };

  return (
    <PageBlock>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton
            linkTo={'/settings'}
          />
          <h1>{t('profiles')}</h1>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateUser}
          type="button">
          {t('addProfileLabel')}
        </Button>
      </div>
      <ProfilesList/>
    </PageBlock>
  );
});

export default ProfilesPage;