import React, { FunctionComponent } from 'react';

interface SvgIconProps {
  icon: string;
  width?: number;
  height?: number;
};

const SvgIcon: FunctionComponent<SvgIconProps> = ({ icon, width, height }) => {
  const svgRef: string = icon.replace(/^.*\/([^/]+)\.[^/.]+\.svg$/, '$1');

  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           xmlnsXlink="http://www.w3.org/1999/xlink"
           width={width || 32}
           height={height || 32}
      >
        <use xlinkHref={`${icon}#${svgRef}`} href={`${icon}#${svgRef}`}/>
      </svg>
  );
};

export default SvgIcon;