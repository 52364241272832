import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { InvoiceModel } from '@assets/models/invoices/invoice.model';
import { useDocumentDetailsStyles } from '../../DocumentDetailsStyles';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export interface IInvoiceFeesSummaryProps {
  invoice: InvoiceModel;
  currencyFormat: Intl.NumberFormat;
  dateOptions: Intl.DateTimeFormatOptions;
}

const InvoiceFeesSummary: FunctionComponent<IInvoiceFeesSummaryProps> = observer((props: IInvoiceFeesSummaryProps) => {
  const { invoice, currencyFormat, dateOptions } = props;
  const { t } = useTranslation('accounting');
  const invoiceDetailsClasses = useDocumentDetailsStyles({ document: props.invoice });
  const subTotalClasses = classnames({
    [invoiceDetailsClasses.subTotal]: true,
    [invoiceDetailsClasses.inheritFontWeight]: props.invoice.invoiceType !== 'STANDARD',
  });

  const excludingTaxesAmount: number = invoice.feesAmountHT;
  const vatAmount: number = invoice.feesTotalVat;
  const includingTaxesAmount: number = invoice.feesAmountTTC;
  const invoiceDate: Date = new Date(props.invoice.invoiceDate);

  return (
    <>
      <div className={invoiceDetailsClasses.title2}>
        {t('invoiceDetails.subscriptionTitle',
          {
            count: props.invoice.subscriptionsBeneficiaryCount,
            month: invoiceDate.toLocaleDateString('fr-FR', dateOptions),
          })}
      </div>
      <div className={invoiceDetailsClasses.secondBlock}>
        <p className={invoiceDetailsClasses.subscription}>{t('invoiceDetails.subscription')}</p>
        <p className={invoiceDetailsClasses.amount}>
          {currencyFormat.format(excludingTaxesAmount)} HT
        </p>
      </div>
      <p className={invoiceDetailsClasses.transaction}>
        {t('invoiceDetails.transaction', { count: props.invoice.subscriptionsBeneficiaryCount })}
      </p>
      <hr/>
      <div className={invoiceDetailsClasses.paymentBlock}>
        <div className={invoiceDetailsClasses.excl}>
          <p>{t('invoiceDetails.totalExcl')}</p>
          <p className={invoiceDetailsClasses.amount}>
            {currencyFormat.format(excludingTaxesAmount)} HT
          </p>
        </div>
        <div className={invoiceDetailsClasses.vat}>
          <p>{t('invoiceDetails.vat')} {props.invoice.subscriptionVatRate.toFixed(2)}%
          </p>
          <p className={invoiceDetailsClasses.amount}>
            {currencyFormat.format(vatAmount)} HT
          </p>
        </div>
        <div className={subTotalClasses}>
          <p>{t(`invoiceDetails.totalFees_${invoice.invoiceType}`)}</p>
          <p className={invoiceDetailsClasses.amount}>
            {currencyFormat.format(includingTaxesAmount)} TTC
          </p>
        </div>
      </div>
    </>
  );
});

export default InvoiceFeesSummary;