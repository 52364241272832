import { style } from 'typestyle';
import { COLORS, FONT } from '../Style/Style';
import { makeStyles } from '@material-ui/core';

export const container = style({
  minHeight: '850px',
});

export const agencyTitle = style({
  color: COLORS.textPrimary,
  marginBottom: '40px',
  letterSpacing: '2px',
  marginTop: '42px',
});

export const agencyInputContainer = style({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  paddingBottom: '1rem',
});

export const agencyInputCol4 = style({
  flex: '0 0 33%',
  maxWidth: 'calc(33% - 10px)',
});

export const agencyInputCol6 = style({
  flex: '0 0 50%',
  maxWidth: 'calc(50% - 10px)',
});

export const agencyInputCol12 = style({
  flex: '0 0 100%',
  maxWidth: '100%',
});

export const agencyClassificationBlock = style({
  backgroundColor: COLORS.backgroundBlock,
  padding: '1rem 2rem',
  marginBottom: '1rem',
  borderRadius: '20px',
});

export const agencyClassificationTitle = style({
  fontWeight: '600',
  marginBottom: '1rem',
  color: 'black',
});

export const root = style({
  opacity: 0.6,
  pointerEvents: 'none',
});

export const input = style({
  width: '50%',
  margin: '1rem',
});

export const restitutionMethodContainer = style({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '180px',
  height: '100%',
});

export const restitutionMethodContainerButtons = style({
  margin: '1rem',
  width: '100px',
});

export const radioLabel = style({
  marginTop: '1rem',
  opacity: 1,
  color: 'rgba(46,64,82,1)',
  fontFamily: FONT.primary,
  fontSize: '14px !important',
  fontWeight: '400',
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'justify',
});

export const formHelper = style({
  color: COLORS.grey,
  fontFamily: 'Inter-Regular',
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
});
