import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../Style/Style';

export const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '22px',
    backgroundColor: COLORS.backgroundBlock,
    borderRadius: '20px',
    width: '300px',
    height: '175px',
  },
  number: {
    fontSize: '44px',
    fontWeight: 700,
    color: COLORS.textPrimary,
  },
  label: {
    fontSize: '14px',
    color: COLORS.textPrimary,
    fontWeight: 700,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.primary,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    '&:hover': {
      cursor : 'pointer',
    }
  },
  arrow: {
    color: '#fff',
  },
  footerCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
});