/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgencyProvisionListArrayRequestResult } from '../models/AgencyProvisionListArrayRequestResult';
import type { BooleanRequestResult } from '../models/BooleanRequestResult';
import type { GetAgencyInvoiceOutputDtoArrayRequestResult } from '../models/GetAgencyInvoiceOutputDtoArrayRequestResult';
import type { GuidArrayRequestResult } from '../models/GuidArrayRequestResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AgencyInvoiceService {

    /**
     * Get invoices of agency from date
     * @param agencyId
     * @param from
     * @returns GetAgencyInvoiceOutputDtoArrayRequestResult Success
     * @throws ApiError
     */
    public static getInvoicesByAgencyId(
        agencyId?: string,
        from?: string,
    ): CancelablePromise<GetAgencyInvoiceOutputDtoArrayRequestResult> {
        return __request({
            method: 'GET',
            path: `/api/agencyinvoice/invoices/agency`,
            query: {
                'AgencyId': agencyId,
                'From': from,
            },
        });
    }

    /**
     * Agency has order
     * @param agencyId
     * @returns BooleanRequestResult Success
     * @throws ApiError
     */
    public static getIsAnyInvoicesByAgencyId(
        agencyId?: string,
    ): CancelablePromise<BooleanRequestResult> {
        return __request({
            method: 'GET',
            path: `/api/agencyinvoice/invoices/agency/any`,
            query: {
                'AgencyId': agencyId,
            },
        });
    }

    /**
     * Get subscription list of beneficiary by date
     * @param agencyId
     * @param invoiceDate
     * @returns GuidArrayRequestResult Success
     * @throws ApiError
     */
    public static getAgencyInvoicesBeneficiariesByDate(
        agencyId?: string,
        invoiceDate?: string,
    ): CancelablePromise<GuidArrayRequestResult> {
        return __request({
            method: 'GET',
            path: `/api/agencyinvoice/invoices/beneficiaries`,
            query: {
                'AgencyId': agencyId,
                'InvoiceDate': invoiceDate,
            },
        });
    }

    /**
     * Get provision list of beneficiary by date
     * @param agencyProvisionId
     * @returns AgencyProvisionListArrayRequestResult Success
     * @throws ApiError
     */
    public static getAgencyProvisionList(
        agencyProvisionId?: string,
    ): CancelablePromise<AgencyProvisionListArrayRequestResult> {
        return __request({
            method: 'GET',
            path: `/api/agencyinvoice/provisions/list`,
            query: {
                'AgencyProvisionId': agencyProvisionId,
            },
        });
    }

    /**
     * Get provision list by beneficiary id
     * @param beneficiaryId
     * @param from
     * @returns AgencyProvisionListArrayRequestResult Success
     * @throws ApiError
     */
    public static getAgencyProvisionListByBeneficiaryId(
        beneficiaryId?: string,
        from?: string,
    ): CancelablePromise<AgencyProvisionListArrayRequestResult> {
        return __request({
            method: 'GET',
            path: `/api/agencyinvoice/provisions/list/beneficiary`,
            query: {
                'BeneficiaryId': beneficiaryId,
                'From': from,
            },
        });
    }

}