import DialogBase from 'Component/Dialog/DialogBase';
import React, { useState } from 'react';
import Button from '../../../Style/MuiStyles/Button';
import {  makeStyles } from '@material-ui/core';
import refreshSvg from './../../../assets/refresh-cw.svg';
import { COLORS, FONT } from '../../../Style/Style';

const RegenQRCodeButton = ({ buttonLabel, onRequestRegenQRCode }) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const styles = RegenQrCodeStyles();

  const handleOpenDialog = () => {
      setIsDialogOpen(true);
  };

  const handleDialogEntry = async (confirm: boolean) => {
    if (confirm) {
      onRequestRegenQRCode(true)
    }
      setIsDialogOpen(false);
  };

  return (
    <div>
      <Button
        imgSrc={refreshSvg}
        onClick={handleOpenDialog}
        type="button"
        variant="contained"
        color="secondary"
      >
        {buttonLabel}
      </Button>

      <DialogBase isActive={isDialogOpen} onClose={() => handleDialogEntry(false)}>
        <p className={styles.title}>
          Renouveler le QR code
        </p>
        <hr/>
        <div className={styles.content}>
          Le renouvellement du QR code du collaborateur rendra
          l’ancien obsolète et inutilisable.<br/><br/>
          Vous confirmer le renouvellement ?
        </div>
        <div className={styles.action}>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => handleDialogEntry(false)}
          >
            Non, annuler
          </Button>
          <Button
            style={{ marginLeft: '2rem' }}
            variant="contained"
            color="primary"
            type="button"
            onClick={() => handleDialogEntry(true)}
          >
           Oui, renouveler le QR code
          </Button>
        </div>
      </DialogBase>
    </div>
  );
};

export const RegenQrCodeStyles = makeStyles(() => ({
  content: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    fontWeight: 'normal',
    fontSize: '16px',
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'normal',
    fontSize: '16px',
  },
  bold: {
    fontWeight: 'bold',
    padding: '1rem 0',
  },
  snackbarContent: {
    background: `linear-gradient(to right, ${COLORS.validated} 4%, white 4%)`,
    borderRadius: '20px',
  },
  snackbarMessage: {
    width: '370px',
    backgroundColor: 'white',
    color: COLORS.textPrimary,
    padding: '1.5rem',
    fontFamily: FONT.primary,
  },
}));
export default RegenQRCodeButton;