import React from 'react';
import { useMandateSignatureStyles } from './MandateSignatureDialogStyles';
import close from '../../../assets/close.svg';
import { observer } from 'mobx-react-lite';
import MandateSignaturePending from './MandateSignaturePending';
import MandateSignatureError from './MandateSignatureError';
import MandateSignatureSuccess from './MandateSignatureSuccess';
import { MandateStore } from '../../../Stores/Mandate.store';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';

export interface IMandateSignatureDialog {
  onClose: () => void;
  isLoading: boolean;
}

const MandateSignatureDialog = observer((props: IMandateSignatureDialog) => {
  const { isSignaturePending, hasSignatureError, isMandateSigned }: MandateStore = useInstance(MandateStore);
  const mandateDialogStyles = useMandateSignatureStyles();
  const { t } = useTranslation('mandate');

  return (
    <div className={mandateDialogStyles.block}>
      <div className={mandateDialogStyles.header}>
        <h1>{t('header')}</h1>
        <img className={mandateDialogStyles.imgClose} alt='close' src={close} onClick={props.onClose}/>
      </div>

      <div>
        <p>{t('subHeader')}</p>

        { isSignaturePending &&
          <MandateSignaturePending {...props} />
        }

        { hasSignatureError &&
          <MandateSignatureError {...props} />
        }

        { isMandateSigned &&
          <MandateSignatureSuccess {...props} />
        }
      </div>
    </div>
  );
});

export default MandateSignatureDialog;
