import React from 'react';
import { COLORS } from '../Style/Style';

interface SvgProps extends React.SVGProps<SVGSVGElement>
{
    color?: string;
}

const Warning = ({ color, ...rest }: SvgProps) => (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g id="POC-Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="02_03_Rechargement" transform="translate(-464.000000, -589.000000)" fill={color || COLORS.error}>
                <g id="Group-5" transform="translate(443.000000, 571.000000)">
                    <g id="Alert" transform="translate(21.000000, 18.000000)">
                        <path d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,1.28571429 C4.73951764,1.28571429 1.28571429,4.73951764 1.28571429,9 C1.28571429,13.2604824 4.73951764,16.7142857 9,16.7142857 C13.2604824,16.7142857 16.7142857,13.2604824 16.7142857,9 C16.7142857,4.73951764 13.2604824,1.28571429 9,1.28571429 Z M9.21428571,12.5625 C9.56932591,12.5625 9.85714286,12.8503169 9.85714286,13.2053571 C9.85714286,13.5603973 9.56932591,13.8482143 9.21428571,13.8482143 C8.85924552,13.8482143 8.57142857,13.5603973 8.57142857,13.2053571 C8.57142857,12.8503169 8.85924552,12.5625 9.21428571,12.5625 Z M9.21428571,4.20535714 C9.56932591,4.20535714 9.85714286,4.49317409 9.85714286,4.84821429 L9.85714286,4.84821429 L9.85714286,10.8482143 C9.85714286,11.2032545 9.56932591,11.4910714 9.21428571,11.4910714 C8.85924552,11.4910714 8.57142857,11.2032545 8.57142857,10.8482143 L8.57142857,10.8482143 L8.57142857,4.84821429 C8.57142857,4.49317409 8.85924552,4.20535714 9.21428571,4.20535714 Z" id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Warning;