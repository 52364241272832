import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AgencyRestitutionReceiptModel } from '@assets/models/accountingDocuments/AgencyAccountingDocument.model';
import { useDocumentDetailsStyles } from '../../DocumentDetailsStyles';
import Button from '../../../../Style/MuiStyles/Button';
import { useTranslation } from 'react-i18next';
import arrow from '../../../../assets/arrow.svg';
import close from '../../../../assets/close.svg';
import { AccountingViewStore } from '../../../../Stores/viewStore/AccountingView.store';



export interface IDeactivationReportDetailsComponentProps {
  receipt: AgencyRestitutionReceiptModel;
  accountingStore : AccountingViewStore;
  onClose: () => void;
}

const DeactivationReportDetailsComponent: FunctionComponent<IDeactivationReportDetailsComponentProps> = observer((props: IDeactivationReportDetailsComponentProps) => {

  const deactivationReportDetailsClasse = useDocumentDetailsStyles({ receipt: props.receipt });
  const { t } = useTranslation('accounting');
  const { receipt, accountingStore, onClose }  = props;

  const handleDownloadReceipt = () => {
    if (!receipt) {
      return;
    }
    accountingStore.downloadDocumentPdfAgencyRestitutionReceipt(receipt);
  }

  return (
    <div className={deactivationReportDetailsClasse.block}>
      <div className={deactivationReportDetailsClasse.header}>
        <h1>{t(`deactivationReportDetails.title`)} {receipt.invoiceReference}</h1>
        <img className={deactivationReportDetailsClasse.imgClose} alt="close" src={close} onClick={onClose}/>
      </div>
      <p className={deactivationReportDetailsClasse.subtitle}>{t('deactivationReportDetails.subtitle')}</p>
      <div className={deactivationReportDetailsClasse.statutDeactivation}>
        <div className={deactivationReportDetailsClasse.statusBlock}>
          <div className={deactivationReportDetailsClasse.deactivationReportCircle}/>
          {t(`deactivationReportDetails.${receipt.paybackSubStatus}`)}
        </div>
        <div>
          {receipt.restitutionMethod === 'DIRECT_DEBIT_IBAN_PAYOUT'
            ? t('deactivationReportDetails.restitution_DIRECT_DEBIT_IBAN_PAYOUT')
            : receipt.restitutionMethod === 'RESTITUTION_IBAN_PAYOUT'
              ? `Compte ${receipt.restitutionIban}`
              : 'Opération non spécifiée'}
        </div>
      </div>
      <div className={deactivationReportDetailsClasse.blockRestitution}>
        <div>
          <p className={deactivationReportDetailsClasse.noConsumRestitution}>{t('deactivationReportDetails.restitutionNoConsum')}</p>
          <p className={deactivationReportDetailsClasse.deactivatedCollab}>{t('deactivationReportDetails.deactivatedCollab')}</p>
          <p className={deactivationReportDetailsClasse.collabInfos}> {`${receipt.beneficiaryFirstName} ${receipt.beneficiaryLastName.toUpperCase()}`}</p>
        </div>
        <div>
          <p> {(receipt.restitutionAmount).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}</p>
        </div>
      </div>
      <hr />
      <div className={deactivationReportDetailsClasse.paymentBlock}>
        <div className={deactivationReportDetailsClasse.totalRestitution}>
          <p>{t('deactivationReportDetails.toPay')}</p>
          <p className={deactivationReportDetailsClasse.amount}>
            {(receipt.restitutionAmount).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
          </p>
        </div>
      </div>


      <div className={deactivationReportDetailsClasse.buttonBlock}>
        { receipt
          ?
            <Button
              onClick={handleDownloadReceipt}
              isFetching={accountingStore.isDownloadingDocumentPdf}
              variant="contained"
              color="secondary">
              <>
                <span
                  className={deactivationReportDetailsClasse.download}>{t(`deactivationReportDetails.download`)}</span>
                <img width={12} src={arrow} alt="down-arrow"/>
              </>
            </Button>
          : <div/>
        }
        <Button
          onClick={onClose}
          variant="contained"
          color="primary">
          {t('invoiceDetails.close')}
        </Button>
      </div>
    </div>
  );
});

export default DeactivationReportDetailsComponent;