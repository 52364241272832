import { makeStyles } from '@material-ui/core';
import { COLORS } from '../Style/Style';

export const useBeneficiariesStyles = makeStyles(() => ({
  subtitle: {
    color: COLORS.primary,
    cursor: 'pointer',
    display: 'inline',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  searchBlock: {
    width: '100%',
    display: 'flex',
    gap: '2rem',
  },
  searchInput: {
    width: '418px',
    marginBottom: '25px',
    marginLeft: 'auto'
  }
}));
