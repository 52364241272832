import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@material-ui/core';

const SpinnerAdornment = () => {
  return (
    <CircularProgress
      color='secondary'
      style={{ marginLeft: '5px', color: 'white' }}
      size={20}
    />
  );
};

  interface ButtonComponentProps extends ButtonProps {
    isFetching?: boolean;
    imgSrc?: string;
  }

const Button = (props:ButtonComponentProps) => {
  const {
    children,
    isFetching,
    imgSrc,
    ...rest
  } = props;
  return (
    <MuiButton {...rest}>
      {imgSrc && <img src={imgSrc} alt="button icon" style={{ marginRight: '10px' }} />}
      {children}
      {isFetching && <SpinnerAdornment />}
    </MuiButton>
  );
};

export default Button;
