import { AppBar, Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import close from '../../../assets/close.svg';
import capitalizeFirstLetter from '../../../Function/CapitalizeFirstLetter';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesDialogViewStore } from '../../../Stores/viewStore/BeneficiariesDialogView.store';
import BeneficiaryAccountInformationsComponent
  from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsComponent';
import BeneficiaryPaybackManagedComponent from '../BeneficiaryPaybackManaged/BeneficiaryPaybackManagedComponent';
import BeneficiaryProvisionsComponent from '../BeneficiaryProvisions/BeneficiaryProvisionsComponent';
import { useBeneficiariesDetailsStyles } from './BeneficiaryDetailsStyles';
import BeneficiaryTakeOutComponent from '../BeneficiaryTakeOut/BeneficiaryTakeOut';
import { AgencyModel } from '../../../assets/models/agencies/Agency.model';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import BeneficiaryActivityComponent from '../BeneficiaryActivity/BeneficiaryActivityComponent';
import BeneficiaryOrganizationComponent from '../BeneficiaryOrganization/BeneficiaryOrganizationComponent';
import BeneficiaryVouchersComponent from '../BeneficiaryVouchers/BeneficiaryVouchersComponent';

type LocalStore = [BeneficiariesDialogViewStore];

interface TabDefinition {
  label: string;
  Component: FunctionComponent<IBeneficiaryDetailsProps>;
  isVisible: (agency?: AgencyModel) => boolean;
}

const tabs: TabDefinition[] = [
  {
    label: 'Compte',
    Component: BeneficiaryAccountInformationsComponent,
    isVisible: (agency?: AgencyModel) => true,
  },
  {
    label: 'Organisation',
    Component: BeneficiaryOrganizationComponent,
    isVisible: (agency?: AgencyModel) => ['PUBLIC_MEAL_SUBSIDY'].includes(agency?.contractType),
  },
  {
    label: 'Activité',
    Component: BeneficiaryActivityComponent,
    isVisible: (agency?: AgencyModel) => true,
  },
  {
    label: 'Droits attribués',
    Component: BeneficiaryProvisionsComponent,
    isVisible: (agency?: AgencyModel) => ['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency?.contractType),
  },
  {
    label: 'Dépenses validées',
    Component: BeneficiaryPaybackManagedComponent,
    isVisible: (agency?: AgencyModel) => ['STANDARD', 'GEOGRAPHIC_RESTRICTION'].includes(agency?.contractType),
  },
  {
    label: 'Dépenses validées',
    Component: BeneficiaryVouchersComponent,
    isVisible: (agency?: AgencyModel) => ['PUBLIC_MEAL_SUBSIDY'].includes(agency?.contractType),
  },
  {
    label: 'Sortie dispositif',
    Component: BeneficiaryTakeOutComponent,
    isVisible: (agency?: AgencyModel) => true,
  },
];

const BeneficiaryDetailsComponent: FunctionComponent<IBeneficiaryDetailsProps> = provider(BeneficiariesDialogViewStore)(observer((props: IBeneficiaryDetailsProps) => {
  const [beneficiariesDialogViewStore]: [BeneficiariesDialogViewStore] = useInstances<LocalStore>(BeneficiariesDialogViewStore);
  const tabNumber = props.beneficiary === null ? 0 : beneficiariesDialogViewStore.tabNumber;
  const beneficiariesDetailsClasses = useBeneficiariesDetailsStyles(props);
  const { t } = useTranslation('beneficiaries');

  const visibleTabs: TabDefinition[] = tabs.filter(tab => tab.isVisible(props.agency));
  const selectedTab = visibleTabs[tabNumber];

  if (!props.beneficiary) {
    return <></>;
  }

  return (
    <>
      <div className={beneficiariesDetailsClasses.block}>
        <div className={beneficiariesDetailsClasses.header}>
          <h1>{`${t('detailsTitle')} ${capitalizeFirstLetter(props.beneficiary?.firstName)} ${props.beneficiary?.lastName.toUpperCase()}`}</h1>
          <img className={beneficiariesDetailsClasses.imgClose} alt="close" src={close} onClick={props.onClose}/>
        </div>
        <span>Vous pouvez consulter ici les données du collaborateur</span>
        <AppBar position="static" elevation={0} className={beneficiariesDetailsClasses.appBar}>
          <Tabs variant="fullWidth" value={tabNumber}
                onChange={(event, tabNumber) => beneficiariesDialogViewStore.setTabNumber(tabNumber)}
                aria-label="détail bénéficiaire">
            { visibleTabs.map(tab => (
              <Tab className={beneficiariesDetailsClasses.tab} label={tab.label} key={tab.label}/>
            ))}
          </Tabs>
        </AppBar>
        <div>
          { selectedTab.isVisible(props.agency) && (
              <selectedTab.Component beneficiary={props.beneficiary} agency={props.agency} onClose={props.onClose}/>
          )}
        </div>
      </div>
    </>
  );
}));

export default BeneficiaryDetailsComponent;
