import React, { FunctionComponent,useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AccountingViewStore } from '../../../Stores/viewStore/AccountingView.store';
import { CircularProgress, Dialog, MenuItem, Select } from '@material-ui/core';
import { StyledSelect } from '../../../Style/MuiStyles/Select/Select';
import Downarrow from '../../../assets/down-arrow';
import { COLORS } from '../../../Style/Style';
import InputComponent from '../../../Component/InputComponent';
import magnifyingGlass from '../../../assets/magnifying-glass.svg';
import { useDocumentsTabStyles } from '../DocumentsTabStyles';
import useDialogStyles from '../../../Style/MuiStyles/Dialog';
import { useTranslation } from 'react-i18next';
import { AgencyRestitutionReceiptModel } from '@assets/models/accountingDocuments/AgencyAccountingDocument.model';
import DeactivationReportCard from '../../../Component/DeactivationReportCard/DeactivationReportCard';
import DeactivationDetailsComponent from './DeactivationsDetails/DeactivationDetailsComponent';

const DeactivationsReportComponent: FunctionComponent<any> = observer(({ accountingStore}: {
  accountingStore: AccountingViewStore,
}) => {
  const documentsTabClasses = useDocumentsTabStyles();
  const dialogClasses = useDialogStyles();
  const { t } = useTranslation('accounting');

  const [currentDeactivationReportKey, setCurrentDeactivationReportKey] = useState<number | undefined>(undefined);
  const [receipts, setReceipts] = useState<AgencyRestitutionReceiptModel[] | void>([]);
  const [sinceDate, setSinceDate] = useState<Date | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClickOpen = (key: number) => {
    setCurrentDeactivationReportKey(key);
    accountingStore.setOpenDialog(true);
  };

  const handleClose = () => {
    accountingStore.setOpenDialog(false);
  };

  const handleInputChangeSearch =(value) => {
    setSearchTerm(value);
  }

  const filteredReceipts = useMemo(() => {
    if (!receipts || !searchTerm) {
      return receipts;
    }

    return receipts.filter(item =>
      item.invoiceReference.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.beneficiaryLastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.beneficiaryFirstName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [receipts, searchTerm]);

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const receiptsResponse = await accountingStore.getRestitutionReceipts(sinceDate);
        if(receiptsResponse) {
          setReceipts(receiptsResponse.filter((item) => item.documentType === "RestitutionReceipt:FOOD_VOUCHER"));
        }
      } catch (error) {
        console.error('Failed to fetch receipts', error);
      }
    };
    fetchReceipts();
  }, [accountingStore.agency.uid, sinceDate]);

  const handleDateChange = (event) => {
    let from = new Date();
    switch (event.target.value) {
      case '0':
        from.setMonth(from.getMonth() - 6);
        break;
      case '1':
        from.setFullYear(from.getFullYear() - 1);
        break;
      case '2':
        from.setFullYear(from.getFullYear() - 2);
        break;
      case '3':
        from = null;
        break;
      default:
        from = null;
    }
    setSinceDate(from);
  };

  const transformDateToValue = (date: Date | null): string => {
    if (!date) {
      return '3';
    }

    const now = new Date();
    if (date.getFullYear() === now.getFullYear() - 2) {
      return '2';
    }
    if (date.getFullYear() === now.getFullYear() - 1) {
      return '1';
    }
    if (date.getMonth() === now.getMonth() - 6 && date.getFullYear() === now.getFullYear()) {
      return '0';
    }

    return '3';
  };

  return (
    <>
      {accountingStore.isLoadingRestitutionReceipts ? (
        <CircularProgress size={64} color={'primary'}/>
      ) : (
        <>
          <div className={documentsTabClasses.block}>
            <Select
              value={transformDateToValue(sinceDate)}
              onChange={handleDateChange}
              labelId="label"
              id="select"
              input={<StyledSelect/>}
              IconComponent={(props) =>
                Downarrow({
                  svgColor: COLORS.white,
                  ...props,
                  className: documentsTabClasses.icon,
                })
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="0">{t('since6months')}</MenuItem>
              <MenuItem value="1">{t('since1year')}</MenuItem>
              <MenuItem value="2">{t('since2years')}</MenuItem>
              <MenuItem value="3">{t('sinceBeginning')}</MenuItem>
            </Select>
            <div className={documentsTabClasses.search}>
              <InputComponent
                placeholder={t('invoiceSearchPlaceholder')}
                icon={magnifyingGlass}
                onChange={handleInputChangeSearch}
                value={searchTerm}
                iconLeft
              />
            </div>
          </div>
          {filteredReceipts &&
              <>
                  <div className={documentsTabClasses.documentList}>
                    {filteredReceipts?.map((receipt, i) => (
                      <DeactivationReportCard
                        key={i}
                        deactivationReportKey={i}
                        deactivationReport={receipt}
                        openDeactivationReport={handleClickOpen}
                      />
                    ))}
                  </div>
                  <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth="md"
                          open={accountingStore.openDialog}
                          onClose={handleClose}>
                      <DeactivationDetailsComponent
                          receipt={filteredReceipts[currentDeactivationReportKey]}
                          onClose={handleClose}
                          accountingStore={accountingStore}
                      />
                  </Dialog>
              </>
          }

        </>
      )}
    </>
  );
});

export default DeactivationsReportComponent;
