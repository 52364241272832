import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BeneficiariesCountResponse } from '../../../assets/responses/beneficiaries/BeneficiariesCount.response';
import { AgencyAdminStore } from '../../../Stores/AgencyAdmin.store';
import { PageBlock } from '../../../Style/Style';
import exclamation from '../../../assets/exclamation.svg';
import forkAndSpoon from '../../../assets/fork-spoon.svg';
import userSvg from '../../../assets/user.svg';
import graph from '../../../assets/graph.svg';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDashboardRecapStyles } from './DashboardRecapStyles';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import { getParticipationPercent } from '../../../Utils/AgencyContractValues.service';
import {
  SendMailsToUnboardedBeneficiariesResponse,
} from '../../../assets/responses/emails/SendMailsToUnboardedBeneficiaries.response';
import SnackErrorComponent from '../../../Component/SnackbarError/SnackErrorComponent';
import SvgIcon from '../../../Component/SvgIcon/SvgIcon';
import { AgencyModel } from '../../../assets/models/agencies/Agency.model';
import useClassificationsParticipation, { ContractPart } from '../../../Hooks/useClassificationsParticipation';

type LocalStore = [AgenciesStore, AgencyAdminStore, BeneficiariesStore];

const DashboardRecapComponent: FunctionComponent = observer(() => {
  const [agenciesStore, agencyAdminStore, beneficiariesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore, AgencyAdminStore, BeneficiariesStore);
  const [activeBeneficiariesCount, setActiveBeneficiariesCount] = useState<number>(0);
  const [unOnboardedBeneficiariesCount, setUnOnboardedBeneficiariesCount] = useState<number>(0);
  const [isFetchingBeneficiariesCount, setIsFetchingBeneficiariesCount] = useState<boolean>(true);
  const [isFetchingSendEmail, setIsFetchingSendEmails] = useState<boolean>(false);
  const [reportMessage, setReportMessage] = useState<string>('');
  const dashboardRecapClasses = useDashboardRecapStyles();
  const { t } = useTranslation('dashboard');
  const {
    contractAgencyPart, contractFacialValue,
  }: ContractPart = useClassificationsParticipation(agenciesStore.currentAgencyBeneficiaryClassification);

  const contractPartReady = !!agenciesStore.currentAgencyBeneficiaryClassification;

  const participationPercent = contractPartReady ? getParticipationPercent(contractAgencyPart, contractFacialValue).toFixed(2) : '';

  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  const successMessage: string = 'Un mail de relance a bien été envoyé à vos collaborateurs concernés';
  const errorMessage: string = 'Nous avons rencontré un problème et nous n’avons pas pu envoyer le mail de relance à vos collaborateurs concernés';

  const { dailyAmountText, contributionText } = useMemo(() => {
    if (!agenciesStore.currentAgency) {
      return { dailyAmountText: '', contributionText: '' };
    }

    const {
      contractType, useBeneficiaryClassification,
    }: Pick<AgencyModel, 'contractType' | 'useBeneficiaryClassification'> = agenciesStore.currentAgency;

    switch (contractType) {
      case 'PUBLIC_MEAL_SUBSIDY':
        return {
          dailyAmountText: t('dailyAmountSubsidy'),
          contributionText: t('contributionSubsidy'),
        };
      default:
        if (useBeneficiaryClassification) {
          return {
            dailyAmountText: t('dailyAmountMultiClass'),
            contributionText: t('contributionMultiClass', { companyQuota: participationPercent }),
          };
        }
        return {
          dailyAmountText: t('dailyAmount'),
          contributionText: t('contribution', { companyQuota: participationPercent }),
        };
    }

  }, [agenciesStore.currentAgency, participationPercent]);

  async function getBeneficiariesCounter(): Promise<void> {
    setIsFetchingBeneficiariesCount(true);

    const beneficiariesCount: BeneficiariesCountResponse = await beneficiariesStore.fetchBeneficiariesCountByAgencyId(agenciesStore.currentAgency.uid);

    setActiveBeneficiariesCount(beneficiariesCount.activeBeneficiariesCount);
    setUnOnboardedBeneficiariesCount(beneficiariesCount.unOnboardedBeneficiariesCount);
    setIsFetchingBeneficiariesCount(false);
  }

  useEffect(() => {
    setReportMessage('');
    if (agenciesStore.currentAgency) {
      (async () => await getBeneficiariesCounter())();
    }
    return setReportMessage('');
  }, [agenciesStore.currentAgency]);

  const sendEmails = async () => {
    setIsFetchingSendEmails(true);
    try {
      const report: SendMailsToUnboardedBeneficiariesResponse = await beneficiariesStore.sendBatchedMailsToUnOnboardedBeneficiaries({ agencyId: agenciesStore.currentAgency.uid });

      if (report.mailsFailed.length) {
        setReportMessage(errorMessage);
      } else if (report.mailsSent.length) {
        setReportMessage(successMessage);
      }
    } catch (e) {
      setReportMessage(errorMessage);
    } finally {
      setIsFetchingSendEmails(false);
    }
  };

  return (
    <div className={dashboardRecapClasses.root}>
      <PageBlock className={dashboardRecapClasses.pageBlock}>
        <div className={dashboardRecapClasses.block}>
          <div className={dashboardRecapClasses.hello}>👋 Hello,</div>
          <div className={dashboardRecapClasses.hello}> {agencyAdminStore.agencyAdminSelected?.firstName}
            <img alt="exclamation logo" src={exclamation}/>
          </div>
        </div>
        <div className={dashboardRecapClasses.block}>
          {isFetchingBeneficiariesCount
            ? <CircularProgress color={'primary'}/>
            : <>
              <div className={dashboardRecapClasses.flex}>
                <SvgIcon icon={userSvg} width={32} height={32}/>
                <span className={dashboardRecapClasses.blockContent}>
                  {!isFetchingBeneficiariesCount && activeBeneficiariesCount}
                </span>
              </div>
              <div>
                {t('beneficiariesPartOne', { count: activeBeneficiariesCount })}
                {unOnboardedBeneficiariesCount !== 0 && <>
                    , 🔥 {t('beneficiariesPartTwo', { count: unOnboardedBeneficiariesCount })}&nbsp;
                  {isFetchingSendEmail
                    ? <CircularProgress color={'primary'}/>
                    : <>  <span onClick={() => sendEmails()} className={dashboardRecapClasses.link}>
                    {t('beneficiariesPartThree', { count: unOnboardedBeneficiariesCount })}
                  </span>&nbsp;?</>}
                </>
                }
              </div>
            </>
          }
        </div>
        <div className={dashboardRecapClasses.block}>
          {!contractPartReady
            ? <CircularProgress color={'primary'}/>
            : <>
              <div className={dashboardRecapClasses.flex}>
                <img width={32} alt="forkAndSpoon logo" src={forkAndSpoon}/>
                <span className={dashboardRecapClasses.blockContent}>
                  {contractFacialValue
                    ? currencyFormat.format(contractFacialValue)
                    : '-'
                  }
                </span>
              </div>
              <div>{dailyAmountText}</div>
            </>
          }
        </div>
        <div className={dashboardRecapClasses.block}>
          {!contractPartReady
            ? <CircularProgress color={'primary'}/>
            : <>
              <div className={dashboardRecapClasses.flex}>
                <img width={32} alt="graph logo" src={graph}/>
                <span className={dashboardRecapClasses.blockContent}>
                  {contractAgencyPart
                    ? currencyFormat.format(contractAgencyPart)
                    : '-'
                  }
                </span>
              </div>
              <div>{contributionText}</div>
            </>
          }
        </div>
      </PageBlock>
      {reportMessage && <SnackErrorComponent message={reportMessage}/>}
    </div>
  );
});

export default DashboardRecapComponent;
