import React, { useCallback, useEffect, useState } from 'react';
import ArrowRight from '../../../assets/cut-right-arrow.svg';
import DownArrow from '../../../assets/cut-bottom-arrow.svg';
import { useStyles } from './ListsStyles';
import { useSortableData } from '../../../Utils/SortGenericList';
import { useInstances } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { AgencyAdminProfileDefinition } from '@assets/models/security/agencyAdmins.model';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { getListOfProfiles } from '../../../assets/utils/security/agencyAdmins.util';

type LocalStore = [AgenciesStore];

const ProfilesList = () => {
  const classes = useStyles();
  const { t } = useTranslation('settings');

  const [agenciesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore);
  const { currentAgency } = agenciesStore;
  const [rawListOfProfiles, setRawListOfProfiles] = useState<AgencyAdminProfileDefinition[]>([]);

  const listOfProfiles = useSortableData(rawListOfProfiles);

  const fetchProfiles = useCallback(async () => {
    if (currentAgency?.uid) {
      const profiles = await getListOfProfiles(currentAgency.uid);
      setRawListOfProfiles(profiles);
    }
  }, [currentAgency?.uid]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const groupCapabilities = (capabilities: string[]) => {
    return capabilities.reduce((acc, capability) => {
      const [category, action] = capability.split('.');
      if (!acc[category]) {
        acc[category] = { read: false, write: false };
      }
      acc[category][action] = true;
      return acc;
    }, {} as Record<string, { read: boolean; write: boolean }>);
  };

  const getSortIcon = (key: string) => {
    if (!listOfProfiles) {
      return null;
    }
    return (
      <img
        src={DownArrow}
        alt={t('sortIcon')}
        className={`${classes.sortIcon} ${listOfProfiles.sortConfig.key === key && listOfProfiles.sortConfig.direction === 'asc' ? classes.sortIconAsc : classes.sortIconDesc}`}
      />
    );
  };

  if (!listOfProfiles || !listOfProfiles.items) {
    return <div>{t('loading')}</div>; // State for loading or error handling
  }

  return (
    <table className={classes.table}>
      <thead>
      <tr>
        <th className={classes.tableHeader} onClick={() => listOfProfiles.requestSort('uid')}>
          {t('identifier')} {getSortIcon('uid')}
        </th>
        <th className={classes.tableHeader} onClick={() => listOfProfiles.requestSort('name')}>
          {t('name')} {getSortIcon('name')}
        </th>
        <th className={classes.tableHeader}>{t('rights')}</th>
        <th className={classes.tableHeader}></th>
      </tr>
      </thead>


      <tbody>
      {listOfProfiles.items.map((profileDefinition, index) => {
        const groupedCapabilities = groupCapabilities(profileDefinition.capabilities);

        return (
          <tr key={index} className={classes.tableRow}>
            <td className={classes.cell}>
              {profileDefinition.code.toString().padStart(3, '0')}
            </td>
            <td className={classes.cell}>{profileDefinition.name}</td>
            <td className={classes.cell}>
              {Object.keys(groupedCapabilities).map((category, idx) => {
                const { write } = groupedCapabilities[category];
                const badgeClass = write ? '' : classes.capabilityReadOnlyBadge;

                return (
                  <span key={idx} className={badgeClass + ' ' + classes.capabilityBadge}>
                    {t(category)}
                  </span>
                );
              })}
            </td>
            <td className={classes.cell}><img src={ArrowRight} alt={t('rightArrow')}/></td>
          </tr>
        );
      })}
      </tbody>
    </table>
  );
};

export default ProfilesList;
