import React, { FunctionComponent, useRef, useState } from 'react';
import { makeStyles, Portal } from '@material-ui/core';
import { COLORS } from '../../Style/Style';
import { MonthYearPicker } from './MonthYearPicker';

interface MonthYearInputProps {
  monthIndex: number;
  fullYear: number;
  onChange: (monthIndex: number, year: number) => void;
}

export const MonthYearInput: FunctionComponent<MonthYearInputProps> = ({ monthIndex, fullYear, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedMonthIndex, setSelectedMonthIndex] = useState<number>(monthIndex);
  const [selectedYear, setSelectedYear] = useState<number>(fullYear);
  const [pickerPosition, setPickerPosition] = useState({ left: 0, top: 0 });
  const monthYearInputRef = useRef<HTMLDivElement>(null);

  const styles = useMonthYearInputStyles({ pickerPosition });

  const handleOpen = () => {
    setIsOpen(true);
    if (monthYearInputRef.current) {
      const rect = monthYearInputRef.current.getBoundingClientRect();
      setPickerPosition({ left: rect.left, top: rect.bottom + 10 });
    }
  };

  const getLabel = () => {
    const date = new Date(selectedYear, selectedMonthIndex, 1);
    return date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
  };

  const handleOnMonthYearSelected = (monthIndex: number, fullYear: number): void => {
    onChange(monthIndex, fullYear);
    setSelectedMonthIndex(monthIndex);
    setSelectedYear(fullYear);
    setIsOpen(false);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.input}
        onClick={handleOpen}
        ref={monthYearInputRef}
      >
        {getLabel()}
      </div>

      {isOpen &&
          <Portal container={() => document.body}>
              <div className={styles.backdrop} onClick={() => setIsOpen(false)}></div>
              <div className={styles.pickerWrapper}>
                  <MonthYearPicker
                      handleOnSelected={handleOnMonthYearSelected}
                      initialMonthIndex={selectedMonthIndex}
                      initialYear={selectedYear}
                  />
              </div>
          </Portal>
      }
    </div>
  );
};

interface UseMonthYearInputStylesProps {
  pickerPosition: { left: number; top: number };
}

const useMonthYearInputStyles = makeStyles((pickerPosition) => ({
  container: {
    position: 'relative',
    display: 'inline-flex',
    width: '100%',
    boxSizing: 'inherit',
    padding: '6px 10px',
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: COLORS.white,
    borderRadius: '20px',
    padding: '0 16px',
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2147483646,
  },
  pickerWrapper: ({ pickerPosition }: UseMonthYearInputStylesProps) => ({
    position: 'fixed',
    left: `${pickerPosition.left}px`,
    top: `${pickerPosition.top}px`,
    zIndex: 2147483647,
  }),
}));
