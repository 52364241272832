import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { useInstances } from 'react-ioc';
import { useOrderCardStyles } from './OrderCardStyles';
import { IInvoiceCard } from '../../Models/Interfaces/IInvoiceCard.model';

type LocalStore = [RechargingViewStore, AgencyInvoiceStore];

const OrderCardComponent = observer((props: IInvoiceCard) => {
  const { orderInvoices } = props;
  const { orderReference, orderPeriod, mainInvoice } = orderInvoices;

  const reloadingClasses = useOrderCardStyles();
  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const invoiceDate = new Date(orderPeriod);
  const { t } = useTranslation('reloading');
  const [
    rechargingViewStore,
    agencyInvoiceStore
  ]: [RechargingViewStore, AgencyInvoiceStore] = useInstances<LocalStore>(RechargingViewStore, AgencyInvoiceStore);

  const provision = mainInvoice.provisionsAmount;

  return (
    <div className={reloadingClasses.block}>
      <div className={reloadingClasses.label}>
        {t('reloadingInvoice.reload')}
      </div>
      <div className={reloadingClasses.value}>
        {invoiceDate.toLocaleDateString('fr-FR', dateOptions)}
      </div>
      <div className={reloadingClasses.label}>
        {t('reloadingInvoice.ref')}
      </div>
      <div className={reloadingClasses.value}>
        {orderReference}
      </div>
      <div className={reloadingClasses.label}>
        {t('amount')}
      </div>
      <div className={reloadingClasses.value}>
        {`${new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR'
        }).format(provision)}`}
      </div>
      <div className={reloadingClasses.label}>
        {t('reloadingInvoice.numberWorker')}
      </div>
      <div className={reloadingClasses.value}>
        {mainInvoice.subscriptionsBeneficiaryCount}
      </div>
      <Button
        variant='contained'
        fullWidth
        onClick={() => {
          agencyInvoiceStore.setSelectedOrder(orderInvoices);
          rechargingViewStore.setOpenOrderDetails(true);
        }}
        color='secondary'>
        {t('reloadingInvoice.reloadDetails')}
      </Button>
    </div>
  );
});

export default OrderCardComponent;
