import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import { usePageLoaderStyles } from './PageLoaderStyles';

const PageLoaderComponent: FunctionComponent = () => {
  const classes = usePageLoaderStyles();
  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress size={100} color={'primary'} />
    </div>
  );
};

export default PageLoaderComponent;
