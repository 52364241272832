import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import Button from '../../../Style/MuiStyles/Button';
import SectorizationFormComponent from '../FormParts/SectorizationFormComponent';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import {
  allowToModifyBeneficiaryDeactivationConfig,
} from '../../../assets/utils/beneficiaries/beneficiaryDeactivation.util';
import {
  useBeneficiaryAccountInformationsStyles,
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, useFormik } from 'formik';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import Yup from '../../../i18n/validation';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import OrganizationFormComponent from '../FormParts/OrganizationFormComponent';

type LocalStore = [AgenciesStore, BeneficiariesStore];
type UpdateBeneficiaryManagementUnitRequest = Pick<BeneficiaryModel, 'agencyManagementUnitId'>
type UpdateBeneficiaryOrganizationInformationsRequest = Pick<BeneficiaryModel, 'organizationAdministrationId' | 'organizationMinistryId' | 'organizationBopId'>
type UpdateBeneficiaryRequest =
  UpdateBeneficiaryManagementUnitRequest
  & UpdateBeneficiaryOrganizationInformationsRequest

function getValidationSchema(agency: AgencyModel, beneficiary?: BeneficiaryModel) {
  const shape: any = {
    ...OrganizationFormComponent.getValidationSchema().fields,
    ...SectorizationFormComponent.getValidationSchema().fields,
  };

  return Yup.object().shape(shape);
}

function getFormInitialValues(agency: AgencyModel, beneficiary?: BeneficiaryModel) {
  const initialValues: any = {
    ...OrganizationFormComponent.getInitialValues(beneficiary),
    ...SectorizationFormComponent.getInitialValues(beneficiary),
    agencyId: agency.uid,
  };

  return initialValues;
}

const BeneficiaryOrganizationComponent: FunctionComponent<IBeneficiaryDetailsProps> = observer(({
                                                                                                  beneficiary, agency,
                                                                                                  onClose,
                                                                                                }) => {
  const [
    agenciesStore,
    beneficiariesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore, BeneficiariesStore);

  const disableModification: boolean = useMemo(() => {
    if (!beneficiary) {
      return false;
    }
    return !allowToModifyBeneficiaryDeactivationConfig(beneficiary);
  }, [beneficiary]);

  const styledClasses = useBeneficiaryAccountInformationsStyles({ disabled: disableModification });
  const { t } = useTranslation('beneficiaries');

  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const validationSchema = getValidationSchema(agency, beneficiary);
  const initialValues: any = getFormInitialValues(agency, beneficiary);

  const updateBeneficiaryOrganisationInfoAndManagementUnit = async (beneficiaryToUpdate: UpdateBeneficiaryRequest): Promise<void> => {
    const { organizationAdministrationId, organizationMinistryId, organizationBopId, agencyManagementUnitId } = beneficiaryToUpdate;

    try {
      await beneficiariesStore.updateBeneficiary(agenciesStore.currentAgency.uid, beneficiary.uid, {
        organizationAdministrationId, organizationMinistryId, organizationBopId, agencyManagementUnitId,
      });

      if (beneficiariesStore.updateBeneficiaryStatus === 'SUCCESS') {
        setShowConfirmationMessage(true);
      } else {
        formik.resetForm();
      }
    } catch (error) {
      setIsServerError(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (beneficiaryFields: UpdateBeneficiaryRequest, {
      setSubmitting,
      setFieldError,
    }: FormikHelpers<UpdateBeneficiaryRequest>) => {
      setSubmitting(true);
      setIsServerError(false);

      await updateBeneficiaryOrganisationInfoAndManagementUnit(beneficiaryFields);

      setSubmitting(false);
    },
  });

  const handleOnSubmit: () => void = useCallback(() => {
    formik.handleSubmit();
  }, []);

  const handleOnCancel: () => void = useCallback(() => {
    formik.resetForm();

    if (!beneficiary?.isActive) {
      onClose();
    }
  }, []);

  return <>
    <form onSubmit={formik.handleSubmit} noValidate className={styledClasses.block}>
      <fieldset disabled={beneficiary?.isActive === false}>
        {/* ------------ ORGANISATION ------------ */}
        <OrganizationFormComponent isEditMode={true} disableModification={disableModification}
                                   beneficiary={beneficiary} formik={formik}/>
        {/* ---------------------------------- */}

        {/* ------------ SECTORISATION ------------ */}
        <SectorizationFormComponent isEditMode={true} disableModification={disableModification}
                                    beneficiary={beneficiary} formik={formik}/>
        {/* ---------------------------------- */}

        {isServerError && (
          <span style={{ color: 'red' }}>Erreur serveur veuillez réessayer plus tard !</span>
        )}
      </fieldset>

      <div className={styledClasses.buttonGroup}>
        <Button
          onClick={handleOnCancel}
          disabled={!formik.dirty || formik.isSubmitting || disableModification}
          type="button"
          variant="contained"
          color={beneficiary?.isActive !== false ? 'secondary' : 'primary'}
        >
          {beneficiary?.isActive !== false ? t('cancel') : t('close')}
        </Button>
        {beneficiary?.isActive !== false &&
            <Button
                style={{ marginLeft: '30px' }}
                onClick={handleOnSubmit}
                isFetching={formik.isSubmitting}
                disabled={!formik.dirty || formik.isSubmitting || disableModification}
                variant="contained"
                color="primary"
            >
              {t('modify')}
            </Button>
        }
      </div>
    </form>

    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={showConfirmationMessage}
      autoHideDuration={3000}
      onClose={() => setShowConfirmationMessage(false)}
    >
      <SnackbarContent
        className={styledClasses.snackbarContent}
        message={
          <div className={styledClasses.snackbarMessage}>
            {t('confirmationSnackBar')}
          </div>
        }/>
    </Snackbar>
  </>;
});

export default BeneficiaryOrganizationComponent;