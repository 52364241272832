import { makeStyles } from '@material-ui/core';
import background from '../../assets/background-login.png';
import { COLORS, FONT } from '../../Style/Style';

export const useResetPasswordStyles = makeStyles(() => ({
  block: {
    display: 'flex',
    flexDirection: 'row',
  },
  blockLeft: {
    width: '50%',
    height: '100vh',
    backgroundSize: '200%',
    backgroundImage: `url(${background})`,
    color: 'white',
    fontSize: '40px',
    paddingLeft: '2.25rem',
    paddingRight: '5rem',
    paddingTop: '30vh',
    fontFamily: FONT.secondary,
  },
  blockRight: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  blockRightContent: {
    width: '418px',
  },
  blockLogo: {
    textAlign: 'center',
  },
  logo: {
    marginBottom: '60px',
    cursor: 'pointer',
  },
  subtitle: {
    fontSize: '14px',
    color: COLORS.textPrimary,
    marginBottom: '2.5rem',
    textAlign: 'center',
  },
  input: {
    marginBottom: '20px',
  },
  passwordInformations: {
    backgroundColor: COLORS.footerBlock,
    borderRadius: '20px',
    padding: '1.5rem',
    marginBottom: '2.5rem',
  },
  snackbar: {
    background: `linear-gradient(to right, ${COLORS.error} 4%, white 4%)`,
    borderRadius: '20px',
  },
  snackbarMessage: {
    width: '370px',
    backgroundColor: 'white',
    color: COLORS.textPrimary,
    padding: '1.5rem',
    fontFamily: FONT.primary,
  },
}));