import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';

export const useUploadComponentStyles = makeStyles(() => ({
  mainTitle: {
    textAlign: 'center',
    paddingTop: '5.5rem',
    paddingBottom: '1rem',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  title: {
    textAlign: 'center',
    paddingTop: '5.5rem',
    paddingBottom: '1rem',
    fontSize: '20px',
  },
  subTitle: {
    color: COLORS.textSecondary,
    textAlign: 'center',
    paddingBottom: '2rem',
    display: 'block',
    fontSize: '14px',
  },
  dropzoneArea: {
    backgroundColor: COLORS.white,
    border: `2px dashed ${COLORS.borderBlock}`,
    cursor: 'pointer',
    borderRadius: '10px',
    height: '242px',
    width: '683px',
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '2.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorContainer: {
    margin: '0 auto',
    marginTop: '1rem',
    width: '683px',
    display: 'flex',
    flexDirection: 'column',
  },
  errorLine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.lightError,
    color: COLORS.error,
    marginTop: '0.5rem',
    borderRadius: '10px',
    padding: '0.5rem',
  },
  errorLineTitleBlock: {
    display: 'flex',
  },
  errorLineTitle: {
    color: COLORS.error,
    fontSize: '14px',
    marginLeft: '1rem',
  },
  errorLineMessage: {
    fontSize: '14px',
    padding: '0 0 0.5rem 4rem',
  },
  snackbarButton: {
    alignSelf: 'flex-end',
    width: '20px',
    padding: 0,
    '&:hover': {},
  },
  snackbarButtonSvg: {
    width: '10px',
  },
  snackbarText: {
    color: COLORS.textPrimary,
    padding: '1rem',
  },
  blockButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 30,
  },
  confirmButton: {
    marginLeft: 20,
    marginRight: 20,
  },
  confirmButtonSvg: {
    marginLeft: 10,
  },
}));
