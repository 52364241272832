import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../Style/Style';

export const useUploadManagerStyles = makeStyles(() => {
  return ({
    block: {
      display: 'flex',
      justifyContent: 'center',
    },
    accordionBlock: {
      backgroundColor: COLORS.success,
      borderRadius: 5,
      boxShadow: 'none',
      marginTop: 5,
      '&:before': {
        height: 0,
      },
      paddingBottom: 20,
    },
    accordionBlockFailed: {
      backgroundColor: COLORS.failed,
      borderRadius: 5,
      boxShadow: 'none',
      marginTop: 5,
      '&:before': {
        height: 0,
      },
      paddingBottom: 20,
    },
    accordion: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      padding: '5px 16px',
      border: 'none',
    },
    accordionEmpty: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      padding: '16px 0 0 16px',
      border: 'none',
    },
    typography: {
      fontSize: '14px',
      display: 'flex',
      justifyContent: 'center',
    },
    typographyEmpty: {
      fontSize: '14px',
      display: 'flex',
    },
    titleText: {
      color: COLORS.textPrimary,
      paddingLeft: 10,
      fontWeight: 'bold',
    },
    text: {
      color: COLORS.textPrimary,
      paddingLeft: 100,
      fontWeight: 'normal',
    },
    uploadResultBlock: {
      width: '700px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5rem 0',
    },
    warningIcon: {
      width: '1.1rem',
      objectFit: 'contain',
    },
    successIcon: {
      objectFit: 'contain',
      width: '6rem',
    },
    drawer: {
      height: '100vh',
      color: COLORS.textPrimary,
      padding: '40px'
    },
    drawerHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: '15px'
    },
    imgClose: {
      cursor: 'pointer'
    },
  });
});