import { makeStyles } from '@material-ui/core';
import { GetAgencyInvoiceOutputDto } from '../../app/api/mapper';
import { COLORS } from '../../Style/Style';
import { AcceptedRestitutionPaybackSubStatus } from '@assets/models/paybacks/PaybackSubStatus.model';

export const useDeactivationReportCardStyles = makeStyles(() => ({
  block: {
    backgroundColor: COLORS.backgroundBlock,
    border: `1px solid ${COLORS.borderBlock}`,
    borderRadius: '20px',
    minHeight: '380px',
    padding: '20px 12.5px 12.5px 12.5px',
    width: '218px',
    maxWidth: '218px',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  statusBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.5rem'
  },
  status: (props: any) => ({
    width: '8px',
    height: '8px',
    backgroundColor: getBackgroundColor(props.deactivationReport.paybackSubStatus),
    borderRadius: '8px',
    alignSelf: 'center',
    marginRight: '5px'
  }),
  label: {
    fontSize: '14px'
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1.25rem',
    wordBreak: 'break-all',
  },
  date: {
    textTransform: 'capitalize'
  }
}));

const getBackgroundColor = (status : AcceptedRestitutionPaybackSubStatus) => {
  switch (status) {
    case 'ACCEPTED_RESTITUTION_ON_WALLET':
      return COLORS.succeededStatus;
    case 'ACCEPTED_RESTITUTION_PAYOUT_CREATED':
      return COLORS.primary;
    case 'ACCEPTED_RESTITUTION_PAYOUT_SUCCESS':
      return COLORS.succeededStatus;
    case 'ACCEPTED_RESTITUTION_PAYOUT_FAILED':
      return COLORS.failedStatus;
    default:
      return COLORS.primary;
  }
};


