import { makeStyles } from '@material-ui/core';

const useAccordionStyles = makeStyles(() => ({
  accordion: {
    '&.MuiPaper-root.MuiAccordion-root.Mui-disabled': {
      backgroundColor: 'inherit'
    },
  },
  accordionSummary: {
    '&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-disabled.Mui-disabled': {
      opacity: 1,
    },
  }
}));

export default useAccordionStyles;
