import { makeStyles } from '@material-ui/core';

export const useAddNewRechargingStyles = makeStyles(() => ({
  mainTitle: {
    textAlign: 'center',
    paddingTop: '5.5rem',
    paddingBottom: '1rem',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  reload: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%'
  },
  stepper: {
    display: 'flex',
    alignSelf: 'center',
    marginTop: '64px'
  },
  buttonMargin: {
    marginRight: '40px'
  }
}));
