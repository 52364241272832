import { COLORS } from '../../Style/Style';
import { makeStyles } from '@material-ui/core';

export const useOrderPaymentStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  paymentMethod: {
    flex: 1,
    marginRight: 10
  },
  OrderDetails: {
    width: '30%'
  },
  paymentOrder: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.backgroundBlock,
    marginBottom: 20,
    padding: 20,
    fontSize: 14
  },
  orderBlock: {
    borderBottom: '1px solid',
    borderBottomColor: COLORS.borderBlock,
    padding: '10px 0'
  },
  orderBlockTotalLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14
  },
  totalEnd: {
    fontWeight: 'bold',
    fontSize: 18
  },
  title: {
    fontSize: 14
  },
  text: {
    color: COLORS.borderBlock
  },
  totalHT: {
    textAlign: 'right'
  },
  orderButtonSubmit: {
    width: '100%'
  },
  dialogPaper: {
    '& .MuiDialog-paper': {
      margin: 0
    },
    '& .MuiDialog-scrollPaper': {
      justifyContent: 'flex-end !important'
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100vh !important'

    }
  }
}));
