import { makeStyles } from '@material-ui/core';
import background from '../../assets/background-login.png';
import { FONT } from '../../Style/Style';

export const useLoginComponentStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  leftBlock: {
    padding: '1rem',
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    color: 'white',
    fontSize: '40px',
    fontFamily: FONT.secondary,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      padding: '30vh 0 0 2.25rem',
      height: '100vh',
      flex: '0 0 50%',
      display: 'block',
    },
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
    },
  },
  content: {
    width: '100%',
    padding: '1rem 1rem',
    [theme.breakpoints.up('md')]: {
      width: '450px',
    },
  },
  logo: {
    marginBottom: '32px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '60px',
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  imgContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
