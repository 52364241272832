import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import downArrowIcon from '../../../assets/downArrow.svg';
import warningIcon from '../../../assets/warningGreen.svg';
import { COLORS } from '../../../Style/Style';
import { BeneficiaryRowTestResult } from '../../../Utils/Tester/BeneficiaryTester.service';

export const useAddBeneficiariesFromCsvSuccessStyles = makeStyles(() => {
  return ({
    block: {
      width: '683px'
    },
    accordionBlock: {
      backgroundColor: COLORS.success,
      borderRadius: 5,
      boxShadow: 'none',
      marginTop: 5,
      '&:before': {
        height: 0
      },
      paddingBottom: 20
    },
    accordion: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      padding: '5px 16px',
      border: 'none'
    },
    accordionEmpty: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      padding: '16px 0 0 16px',
      border: 'none'
    },
    typography: {
      fontSize: '14px',
      display: 'flex',
      justifyContent: 'center'
    },
    typographyEmpty: {
      fontSize: '14px',
      display: 'flex',
    },
    titleText: {
      color: COLORS.textPrimary,
      paddingLeft: 10,
      fontWeight: 'bold'
    },
    text: {
      color: COLORS.textPrimary,
      paddingLeft: 100,
      fontWeight: 'normal'
    }
  });
});

interface IAddBeneficiariesFromCsvResult {
  fileTestResult: BeneficiaryRowTestResult[];
}

const AddBeneficiariesFromCsvSuccess: FunctionComponent<IAddBeneficiariesFromCsvResult> = (props: IAddBeneficiariesFromCsvResult) => {
  const { t } = useTranslation('beneficiariesfromcsv');
  const analysisManagerStyles = useAddBeneficiariesFromCsvSuccessStyles();

  const beneficiariesToCreate: BeneficiaryRowTestResult[] = useMemo(() => {
    return props.fileTestResult.filter(row => row.userValidity === 'USER_UNKNOWN');
  }, [props.fileTestResult]);

  const beneficiariesToUpdate: BeneficiaryRowTestResult[] = useMemo(() => {
    return props.fileTestResult.filter(row => row.userValidity === 'USER_EXISTS');
  }, [props.fileTestResult]);

  const beneficiariesToCreateCount: number = beneficiariesToCreate?.length;
  const beneficiariesToUpdateCount: number = beneficiariesToUpdate?.length;

  return (
    <>
      {beneficiariesToCreateCount === 0
        ? <div className={analysisManagerStyles.accordionBlock}>
            <div className={analysisManagerStyles.accordionEmpty}>
              <Typography className={analysisManagerStyles.typographyEmpty}>
                <img src={warningIcon} alt='warning'/>
                <span className={analysisManagerStyles.titleText}>
                  {t('titleAddedAnalyse', { count: beneficiariesToCreateCount })}
                </span>
              </Typography>
            </div>
          </div>
        : <Accordion className={analysisManagerStyles.accordionBlock}>
            <AccordionSummary
              expandIcon={<img src={downArrowIcon} alt='downArrow'/>}
              aria-controls='panel1a-content'
              id='panel1a-header'
              className={analysisManagerStyles.accordion}
            >
              <Typography className={analysisManagerStyles.typography}>
                <img src={warningIcon} alt='warning'/>
                <span className={analysisManagerStyles.titleText}>
                  {t('titleAddedAnalyse', { count: beneficiariesToCreateCount })}
                </span>
              </Typography>
            </AccordionSummary>
            {
              beneficiariesToCreate?.map((row, index) => {
                return ((row.firstName && row.lastName)
                  && <AccordionDetails key={index} className={analysisManagerStyles.accordion}>
                    <Typography className={analysisManagerStyles.typography}>
                        <span className={analysisManagerStyles.text}>
                          {row.firstName} {row.lastName}
                        </span>
                    </Typography>
                  </AccordionDetails>
                );
              })
            }
          </Accordion>}
      {beneficiariesToUpdateCount === 0
        ? <div className={analysisManagerStyles.accordionBlock}>
            <div className={analysisManagerStyles.accordionEmpty}>
              <Typography className={analysisManagerStyles.typographyEmpty}>
                <img src={warningIcon} alt='warning'/>
                <span className={analysisManagerStyles.titleText}>{t('titleUpdateAnalyse', { count: beneficiariesToUpdateCount })}</span>
              </Typography>
            </div>
          </div>
        : <Accordion className={analysisManagerStyles.accordionBlock}>
            <AccordionSummary
              expandIcon={<img src={downArrowIcon} alt='downArrow'/>}
              aria-controls='panel1a-content'
              id='panel1a-header'
              className={analysisManagerStyles.accordion}
            >
              <Typography className={analysisManagerStyles.typography}>
                <img src={warningIcon} alt='warning'/>
                <span className={analysisManagerStyles.titleText}>{t('titleUpdateAnalyse', { count: beneficiariesToUpdateCount })}</span>
              </Typography>
            </AccordionSummary>
            {
              beneficiariesToUpdate?.map((row, index) => {
                return (
                  <AccordionDetails key={index} className={analysisManagerStyles.accordion}>
                    <Typography className={analysisManagerStyles.typography}>
                      <span className={analysisManagerStyles.text}>
                        {row.firstName} {row.lastName}
                      </span>
                    </Typography>
                  </AccordionDetails>
                );
              })
            }
          </Accordion>}
    </>
  );
};

export default AddBeneficiariesFromCsvSuccess;
