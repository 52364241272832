import { action, makeAutoObservable } from 'mobx';

export class ConnectedViewStore {
  mobileOpen: boolean = false;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setMobileOpen (isOpen: boolean) {
    this.mobileOpen = isOpen;
  }
};
