import React, { FunctionComponent } from 'react';
import { agencyTitle, agencyInputContainer, agencyInputCol6, root } from '../AgencyStyle';
import { useTranslation } from 'react-i18next';
import StyledTextField from '../../Style/MuiStyles/TextField';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgencyDetailsStore } from '../../Stores/Agencies.store';

const AgencyInformationComponent: FunctionComponent = observer(() => {
  const { t } = useTranslation(['agency', 'country']);
  const agencyDetailsStore: AgencyDetailsStore = useInstance<AgencyDetailsStore>(AgencyDetailsStore);
  const agencyDetails = agencyDetailsStore.agencyDetails;
  return (
    <div className={root}>
      <div className={agencyTitle}>{t('administrativeDataTitle')}</div>
      <div className={agencyInputContainer}>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('ref')}
            value={agencyDetails.ref}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('agencyName')}
            value={agencyDetails.agencyName}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('address')}
            value={agencyDetails?.streetAddress}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('additionalAddress1')}
            value={agencyDetails?.additionalAddress || t('notSpecified')}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('zipcode')}
            value={agencyDetails?.postalCode}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('city')}
            value={agencyDetails?.city}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('registrationNumber')}
            value={agencyDetails.registrationNumber}
            fullWidth />
        </div>
      </div>
      <div className={agencyTitle}>{t('billingContact')}</div>
      <div className={agencyInputContainer}>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('lastName')}
            value={agencyDetails?.billingContactLastName.toLocaleUpperCase()}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('firstName')}
            value={agencyDetails?.billingContactFirstName}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('email')}
            value={agencyDetails?.billingContactEmail}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('mobilephone')}
            value={agencyDetails?.billingContactMobilePhone}
            fullWidth />
        </div>
      </div>
      <div className={agencyTitle}>{t('billingAddress')}</div>
      <div className={agencyInputContainer}>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('address')}
            value={agencyDetails?.billingStreetAddress}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('additionalAddress1')}
            value={agencyDetails?.billingAdditionalAddress || t('notSpecified')}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('zipcode')}
            value={agencyDetails?.billingZipCode}
            fullWidth />
        </div>
        <div className={agencyInputCol6}>
          <StyledTextField
            color='secondary'
            label={t('city')}
            value={agencyDetails?.billingCity}
            fullWidth />
        </div>
      </div>
    </div>
  );
});

export default AgencyInformationComponent;
