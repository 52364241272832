import React, { FunctionComponent, useEffect, useState } from 'react';
import { CircularProgress, Dialog } from '@material-ui/core';
import { AutonomousService, PaymentRequest, PaymentRequestArrayRequestResult } from '../../app/api/mapper';
import classnames from 'classnames';
import { PageBlock, PageSubtitle } from '../../Style/Style';
import Button from '../../Style/MuiStyles/Button';
import moment from 'moment';
import addIcon from '../../assets/add.svg';
import downloadIcon from '../../assets/download.svg';
import useDialogStyles from '../../Style/MuiStyles/Dialog';
import NewPaymentRequestComponent from '../NewPaymentRequest/NewPaymentRequestComponent';
import { usePaymentRequestBlockStyles, usePaymentRequestStyles } from './PaymentRequestStyles';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';

export interface IPaymentRequestComponentProps {
}

type LocalStore = [AgenciesStore];

const PaymentRequestComponent: FunctionComponent<IPaymentRequestComponentProps> = observer(() => {
  const [agenciesStore]: [AgenciesStore] = useInstances<LocalStore>(AgenciesStore);
  const paymentRequestClasses = usePaymentRequestStyles();
  const [isFetchingGetListPaymentRequest, setIsFetchingGetListPaymentRequest] = useState(true);
  const [listPaymentRequest, setListPaymentRequest] = useState<PaymentRequest[]>();
  const dialogClasses = useDialogStyles();
  const from = moment().subtract(6, 'months');
  const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const [open, setOpen] = React.useState(false);

  const getListPaymentRequest = () => {
    if (!agenciesStore.currentAgency) {
      return;
    }
    setIsFetchingGetListPaymentRequest(true);
    AutonomousService.getAllRequestPaymentByAgencyId(agenciesStore.currentAgency.uid, moment(from).format('YYYY-MM-DD'))
      .then((GetAgenciesListBeneficiaryPaybackOutputDto: PaymentRequestArrayRequestResult) => {
        setListPaymentRequest(GetAgenciesListBeneficiaryPaybackOutputDto.result);
      })
      .catch(e => console.error(e))
      .finally(() => setIsFetchingGetListPaymentRequest(false));
  };

  const downloadCsvRequestPayment = (paymentRequest: any) => {
    if (!paymentRequest) {
      return;
    }

    const result = [['Date début', 'Date fin', 'Matricule', 'Montant', 'Email', 'Prénom', 'Nom']];
    paymentRequest.paymentRequestsList.reduce(function (res, value) {
      if (!res[value.beneficiaryId]) {
        res[value.beneficiaryId] = [
          moment(paymentRequest.from).format('DD/MM/YYYY'),
          moment(paymentRequest.to).format('DD/MM/YYYY'),
          value.beneficiary.registrationNumber,
          0,
          value.beneficiary.email,
          value.beneficiary.firstname,
          value.beneficiary.lastname];
        result.push(res[value.beneficiaryId]);
      }
      res[value.beneficiaryId][3] += value.amount;
      return res;
    }, {});

    result.forEach(r => {
      const num = Number(r[0]);
      if (!isNaN(num)) {
        r[0] = currencyFormat.format(num);
      }
    });

    const csvContent = 'data:text/csv;charset=utf-8,' +
      result.map(e => e.join(';')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'remboursements.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  useEffect(() => {
    if (!open) {
      getListPaymentRequest();
    }
  }, [agenciesStore.currentAgency, open]);

  interface IPaymentRequestBlock {
    paymentRequestKey: number;
    paymentRequest: PaymentRequest;
  }

  const PaymentRequestBlock = (props: IPaymentRequestBlock) => {
    const paymentRequestBlockClasses = usePaymentRequestBlockStyles(props.paymentRequest);

    return (
      <div className={paymentRequestClasses.block}>
        <div className={paymentRequestBlockClasses.statusBlock}>
          <div>
            <div className={paymentRequestBlockClasses.label}>
              Période concernée
            </div>
            <div className={classnames(paymentRequestBlockClasses.value, paymentRequestBlockClasses.date)}>
              Du {moment(props.paymentRequest.from).format('DD/MM/YYYY')}<br/>
              au {moment(props.paymentRequest.to).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className={paymentRequestBlockClasses.download}
            onClick={() => downloadCsvRequestPayment(props.paymentRequest)}>
            <img width={18} alt={'Télécharger le remboursement'} src={downloadIcon}/>
          </div>
        </div>
        <div className={paymentRequestBlockClasses.label}>
          Montant
        </div>
        <div className={paymentRequestBlockClasses.value}>
          {`${new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          }).format(props.paymentRequest.amount)}`}
        </div>
      </div>
    );
  };

  return (
    <PageBlock>
      <h1>Remboursements</h1>
      <PageSubtitle>
        Liste de tous vos remboursements
      </PageSubtitle>
      {isFetchingGetListPaymentRequest
        ? <CircularProgress size={64} color={'primary'}/>
        : <>
          {listPaymentRequest &&
              <>
                <div className={paymentRequestClasses.paymentRequests}>
                  <div
                    className={classnames(paymentRequestClasses.block, paymentRequestClasses.newPaymentRequests)}
                    onClick={() => setOpen(true)}>
                    <div style={{ textAlign: 'center', margin: '54px 0px 36px 0px' }}>
                      <img style={{ marginBottom: '7px' }} width={43} alt={'Nouveau remboursement'}
                        src={addIcon}/>
                    </div>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      type='button'>
                              Nouveau remboursement
                    </Button>
                  </div>
                  {listPaymentRequest?.map((paymentRequest, i) =>
                    <PaymentRequestBlock
                      key={i}
                      paymentRequestKey={i}
                      paymentRequest={paymentRequest}/>,
                  )
                  }
                </div>
              </>
          }
        </>
      }
      <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth='md' open={open} onClose={() => setOpen(false)}>
        <NewPaymentRequestComponent
          onClose={() => setOpen(false)} downloadCsvRequestPayment={downloadCsvRequestPayment}
          lastDate={listPaymentRequest?.length ? listPaymentRequest[0].createdOn : new Date(agenciesStore.currentAgency?.contractStartDate).toLocaleDateString()}/>
      </Dialog>
    </PageBlock>
  );
});

export default PaymentRequestComponent;
