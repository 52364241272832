import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IBeneficiaryDetailsProps } from '../../../Models/Interfaces/IBeneficiaryDetailsProps.model';
import ActivityFormComponent_Linear from '../FormParts/ActivityFormComponent_Linear';
import { allowToModifyBeneficiaryDeactivationConfig } from '../../../assets/utils/beneficiaries/beneficiaryDeactivation.util';
import { FormikHelpers, useFormik } from 'formik';
import { ContractType } from '@assets/models/agencies/Agency.model';
import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import {
  useBeneficiaryAccountInformationsStyles
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import Button from '../../../Style/MuiStyles/Button';
import { useTranslation } from 'react-i18next';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import ActivityFormComponent_Discrete from '../FormParts/ActivityFormComponent_Discrete';
import { RightsReductionPeriod } from './RightsReductionPeriod';
import { UpdateBeneficiaryActivityRequest } from '@assets/requests/beneficiaries/UpdateBeneficiaryActivityRequest';
import { Snackbar, SnackbarContent } from '@material-ui/core';

type LocalStore = [BeneficiariesStore];



function getValidationSchema(contractType: ContractType) {
  let shape: any = {};

  if (contractType === 'PUBLIC_MEAL_SUBSIDY') {
    shape = {
      ...shape,
      ...ActivityFormComponent_Discrete.getValidationSchema().fields,
    }
  } else {
    shape = {
      ...shape,
      ...ActivityFormComponent_Linear.getValidationSchema().fields,
    }
  }

  return Yup.object().shape(shape);
}

function getFormInitialValues(contractType: ContractType, beneficiary?: BeneficiaryModel) {
  let initialValues: any = {};

  if (contractType === 'PUBLIC_MEAL_SUBSIDY') {
    initialValues = {
      ...initialValues,
      ...ActivityFormComponent_Discrete.getInitialValues(beneficiary),
    }
  } else {
    initialValues = {
      ...initialValues,
      ...ActivityFormComponent_Linear.getInitialValues(beneficiary),
    }
  }

  return initialValues;
}

const BeneficiaryActivityComponent: FunctionComponent<IBeneficiaryDetailsProps> = observer(({ beneficiary, agency, onClose }) => {
  const [beneficiariesStore]: LocalStore = useInstances<LocalStore>(BeneficiariesStore);

  const disableModification: boolean = useMemo(() => {
    if (!beneficiary) {
      return false;
    }
    return !allowToModifyBeneficiaryDeactivationConfig(beneficiary);
  }, [beneficiary]);

  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });
  const { t } = useTranslation('beneficiaries');

  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const validationSchema = getValidationSchema(agency.contractType);
  const initialValues: any = getFormInitialValues(agency.contractType, beneficiary);

  const updateBeneficiary = async (
    beneficiaryToUpdate: UpdateBeneficiaryActivityRequest,
    setFieldError: (field: string, errorMsg: string) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const { firstRightDate, ...request } = beneficiaryToUpdate;

    try {
      await beneficiariesStore.updateBeneficiaryActivity(beneficiary.uid, request);
      setShowConfirmationMessage(true);

    } catch (error) {
      setIsServerError(true);
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (beneficiaryFields: UpdateBeneficiaryActivityRequest, {
      setSubmitting,
      setFieldError,
    }: FormikHelpers<UpdateBeneficiaryActivityRequest>) => {
      setSubmitting(true);
      setIsServerError(false);

      await updateBeneficiary(beneficiaryFields, setFieldError, setSubmitting);

      setSubmitting(false);
    },
  });

  const handleOnSubmitButtonClicked: () => void = useCallback(() => {
    formik.handleSubmit();
  }, []);

  const handleOnCancelButtonClicked: () => void = useCallback(() => {
    formik.resetForm();

    if (!beneficiary?.isActive) {
      onClose();
    }
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate className={beneficiariesAccountInformationsClasses.block}>
        <fieldset disabled={beneficiary?.isActive === false}>
          {/* ------------ ACTIVITÉ ------------ */}
          {agency.contractType === 'PUBLIC_MEAL_SUBSIDY'
            ? (
              <ActivityFormComponent_Discrete isEditMode={true} disableModification={disableModification}
                                              beneficiary={beneficiary} formik={formik}/>
            )
            : (
              <ActivityFormComponent_Linear isEditMode={true} disableModification={disableModification}
                                            beneficiary={beneficiary} formik={formik}/>
            )
          }
          {/* ---------------------------------- */}

          {isServerError && (
            <span style={{ color: 'red' }}>Erreur serveur veuillez réessayer plus tard !</span>
          )}
        </fieldset>

        <div className={beneficiariesAccountInformationsClasses.buttonGroup}>
          <Button
            onClick={handleOnCancelButtonClicked}
          disabled={!formik.dirty || formik.isSubmitting || disableModification}
            type="button"
            variant="contained"
            color={beneficiary?.isActive !== false ? 'secondary' : 'primary'}
          >
            {beneficiary?.isActive !== false ? t('cancel') : t('close')}
          </Button>
          {beneficiary?.isActive !== false &&
              <Button
                  style={{ marginLeft: '30px' }}
                  onClick={handleOnSubmitButtonClicked}
                  isFetching={formik.isSubmitting}
                  disabled={!formik.dirty || formik.isSubmitting || disableModification}
                variant="contained"
                color="primary"
            >
              {t('modify')}
            </Button>
        }
      </div>
    </form>

    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={showConfirmationMessage}
      autoHideDuration={3000}
      onClose={() => setShowConfirmationMessage(false)}
    >
      <SnackbarContent
        className={beneficiariesAccountInformationsClasses.snackbarContent}
        message={
          <div className={beneficiariesAccountInformationsClasses.snackbarMessage}>
            {t('confirmationSnackBar')}
          </div>
        }/>
    </Snackbar>

    {agency.contractType === 'PUBLIC_MEAL_SUBSIDY' && <RightsReductionPeriod beneficiary={beneficiary}/>}
    </>
  )
});

export default BeneficiaryActivityComponent;