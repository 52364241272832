import { InvoiceModel } from '@assets/models/invoices/invoice.model';
import moment from 'moment/moment';
import classnames from 'classnames';
import { Button } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { useInstances } from 'react-ioc';
import { useInvoiceCardStyles } from './InvoiceCardStyles';

interface IInvoiceCard {
  invoiceKey: number;
  invoice: InvoiceModel;
  openInvoice: (invoiceKey: number) => void;
}

const InvoiceCard = (props: IInvoiceCard) => {
  const [
    agenciesStore,
  ]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);
  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const { t } = useTranslation('accounting');

  const invoiceCardClasses = useInvoiceCardStyles(props);
  const invoiceDate: Date = new Date(props.invoice.invoiceDate);
  const statusDate: Date = new Date(props.invoice.statusDate);

  const totalAmount: number = useMemo(() => {
    if (agenciesStore.currentAgency?.isManagedPaymentMode === false) {
      return props.invoice.feesAmountTTC;
    }

    return props.invoice.netAmount;
  }, [props.invoice, agenciesStore.currentAgency?.isManagedPaymentMode]);

  return (
    <div className={invoiceCardClasses.block}>
      <div>
        <div className={invoiceCardClasses.statusBlock}>
          <div className={invoiceCardClasses.status}/>
          <div className={invoiceCardClasses.label}>
            {props.invoice.status === 'SUCCEEDED'
              ? `${props.invoice.paymentMethod === 'DIRECT_DEBIT'
                ? t('paidDirectDebit')
                : t('paidBankTransfer')} ${statusDate.toLocaleDateString()}`
              : t('toPay', { mode: props.invoice.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement' })}
          </div>
        </div>
        <div className={invoiceCardClasses.label}>
          {t(`creationDateLabel_${props.invoice.invoiceType}`)}
        </div>
        <div className={invoiceCardClasses.value}>
          {moment(props.invoice.creationDate).format('DD/MM/YYYY')}
        </div>
        <div className={invoiceCardClasses.label}>
          {t(`invoiceTitle_${props.invoice.invoiceType}`)}
        </div>
        <div className={invoiceCardClasses.value}>
          {props.invoice.invoiceReference}
        </div>
        <div className={classnames(invoiceCardClasses.value, invoiceCardClasses.date)}>
          {invoiceDate.toLocaleDateString('fr-FR', dateOptions)}
        </div>
        <div className={invoiceCardClasses.label}>
          {t(`amount_${props.invoice.invoiceType}`)}
        </div>
        <div className={invoiceCardClasses.value}>
          {`${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalAmount)}`}
        </div>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => props.openInvoice(props.invoiceKey)}>
        {t(`seeDetails_${props.invoice.invoiceType}`)}
      </Button>
    </div>
  );
};

export default InvoiceCard;