import React, { useCallback, useState } from 'react';
import mandateSvg from '../../../assets/mandateSignature.svg';
import combinedShape from '../../../assets/combinedShape.svg';
import ButtonComponent from '../../../Style/MuiStyles/Button';
import { useMandateSignatureStyles } from './MandateSignatureDialogStyles';
import { IMandateSignatureDialog } from './MandateSignatureDialog';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { MandateStore } from '../../../Stores/Mandate.store';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { useTranslation } from 'react-i18next';

const MandateSignaturePending = observer((props: IMandateSignatureDialog) => {
  const [mandateStore, agenciesStore] = useInstances(MandateStore, AgenciesStore);
  const mandateDialogStyles = useMandateSignatureStyles();
  const { t } = useTranslation('mandate');
  const [isCreating, setIsCreating] = useState(false);

  const addMandate = useCallback(() => {
    setIsCreating(true);
    mandateStore.createMandate(agenciesStore.currentAgency?.uid);
    setIsCreating(false);
  }, []);

  return (
    <>
      <div className={mandateDialogStyles.blockSvg}>
        <img className={mandateDialogStyles.mandateSvg} src={mandateSvg} alt={'mandate signature'}/>

        <p className={mandateDialogStyles.subtitleSvg}>
          {t('description')}<br/>
          {t('description2')}
        </p>
      </div>

      <div className={mandateDialogStyles.blockButtons}>
        <ButtonComponent
          variant='contained'
          color='secondary'
          className={mandateDialogStyles.confirmButton}
          onClick={props.onClose}
          isFetching={isCreating}
          type='button'>
          {t('cancelBtn')}
        </ButtonComponent>

        <ButtonComponent
          variant='contained'
          color='primary'
          className={mandateDialogStyles.confirmButton}
          onClick={addMandate}
          isFetching={isCreating}
          type='button'>
          {t('submitBtn')}
          <img className={mandateDialogStyles.confirmButtonSvg} src={combinedShape} alt={'combined shape'}/>
        </ButtonComponent>
      </div>
    </>
  );
});

export default MandateSignaturePending;
