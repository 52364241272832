import { makeStyles } from '@material-ui/core';
import { COLORS, FONT } from '../../../Style/Style';

export const useBeneficiaryActivityStyles = makeStyles(() => ({
  title: {
    margin: '0',
    fontWeight: 'normal',
    fontSize: '1rem',
    letterSpacing: '2px',
    padding: '40px 50px 40px 0px',
    color: COLORS.textPrimary,
    textTransform: 'uppercase',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '25px',
    marginRight: '10px',
    height: '40px',
  },
  search: {
    width: '418px',
    margin: '30px 30px 25px 0px',
  },
  icon: {
    width: 'inherit',
    height: 'inherit',
  },
  block: {
    color: COLORS.textPrimary,
    padding: '1rem 0',
  },
  error: {
    color: COLORS.error,
    fontSize: '0.8rem',
  },
}));

export const useDataGridStyles = makeStyles({
  root: {
    border: 'none !important',
    '& .MuiDataGrid-cell': {
      display: 'flex',
      border: 'none !important',
      color: COLORS.textPrimary,
      boxShadow: 'none !important',
      backgroundColor: 'transparent !important',
    },
    '& .MuiDataGrid-dataContainer': {
      height: (props: any) => `${170 + 100 * (props.numberOfRowToDisplay || 1)}px`,
    },
    '& .MuiDataGrid-cell--editing': {
      display: 'flex',
      border: 'none !important',
      color: COLORS.textPrimary,
      boxShadow: 'none !important',
      borderRadius: '20px',
    },
    '& .MuiDataGrid-editInputCell': {
      padding: '6px 10px',
    },
    '& .MuiDataGrid-row': {
      backgroundColor: COLORS.backgroundBlock,
      borderRadius: '20px',
      marginBottom: '20px',
      width: '100%',
      cursor: 'pointer',
    },
    '& .row-editing': {
      backgroundColor: 'rgba(134, 208, 239, 0.5) !important',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: COLORS.backgroundBlock,
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: COLORS.backgroundBlock,
    },
    '& .MuiDataGrid-editInputCell input': {
      backgroundColor: 'white',
      borderRadius: '20px',
    },
    '& .MuiDataGrid-colCellTitle': {
      color: COLORS.textPrimary,
      fontFamily: FONT.primary,
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-colCell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none !important',
    },
    '& .MuiDataGrid-columnsContainer': {
      border: 'none !important',
    },
    '& .MuiSvgIcon-root': {
      color: COLORS.primary,
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: '100% !important',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      visibility: 'hidden',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '36px',
    },
    '& .MuiTablePagination-spacer': {
      display: 'none !important',
    },
  },
});