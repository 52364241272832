import React from 'react';
import mandateSvg from '../../../assets/mandateSignedSuccess.svg';
import ButtonComponent from '../../../Style/MuiStyles/Button';
import { useMandateSignatureStyles } from './MandateSignatureDialogStyles';
import { IMandateSignatureDialog } from './MandateSignatureDialog';
import { useTranslation } from 'react-i18next';

const MandateSignatureSuccess = (props: IMandateSignatureDialog) => {
  const mandateDialogStyles = useMandateSignatureStyles();
  const { t } = useTranslation('mandate');

  return (
    <>
      <p>{t('success.title')}</p>
      <div className={mandateDialogStyles.blockSvg}>
        <img className={mandateDialogStyles.mandateSvg} src={mandateSvg} alt={'mandate signature'}/>
        <p className={mandateDialogStyles.subtitleSvg}>
          {t('success.description')}
        </p>
      </div>
      <div className={mandateDialogStyles.blockButtons}>
        <ButtonComponent
          variant='contained'
          color='primary'
          className={mandateDialogStyles.confirmButton}
          onClick={props.onClose}
          type='button'>
          {t('success.backBtn')}
        </ButtonComponent>
      </div>
    </>
  );
};

export default MandateSignatureSuccess;
