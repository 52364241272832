import {
  useBeneficiaryActivityStyles
} from '../../../Beneficiaries/BeneficiaryDetails/BeneficiaryActivity/BeneficiaryActivity.styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import Button from '../../../Style/MuiStyles/Button';
import React from 'react';
import { DeleteVoucherRestrictionRequest } from '@assets/requests/discreteVouchers/DeleteVoucherRestrictionRequest';

interface PopupProps {
  open: boolean;
  close: () => void;
  remove: (request) => void;
  request: DeleteVoucherRestrictionRequest;
  loading: boolean;
}

export const DeleteVoucherConfirmationDialog = ({open, close, remove, request, loading}: PopupProps) => {
  const beneficiaryActivityStyles = useBeneficiaryActivityStyles();
  const styles = useStyles();

  return (

    <Dialog
      open={open}
      onClose={close}
      className={styles.root}
    >
      <DialogTitle>Confirmer la suppression</DialogTitle>
      <DialogContent><p>Supprimer cette période</p></DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Button
            onClick={close}
            type="button"
            variant="contained"
            disabled={loading}
            color={'secondary'}
            style={{marginRight: '1rem'}}
            className={beneficiaryActivityStyles.buttonContainer}
          >
            Non, annuler
          </Button>
          <Button
            onClick={() => remove(request)}
            type="button"
            variant="contained"
            color={'primary'}
            isFetching={loading}
            disabled={loading}
            className={beneficiaryActivityStyles.buttonContainer}
          >
            oui, supprimer
          </Button>
        </div>
      </DialogActions>
    </Dialog>)
}

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded ': {
      borderRadius: '25px',
      padding: '2rem'
    },
    '& .MuiDialogContent-root': {
      width: '400px',
      padding: '3rem 1.5rem',
    }
  }
});

