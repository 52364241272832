import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import Button from '../../../../Style/MuiStyles/Button';
import { ExpandMore } from '@material-ui/icons';
import { useMillesimeInformationStyles } from '../MillesimeInformationStyles';
import { RestitutionMethod, RightsReconductionType } from '../../../../assets/models/agencies/Agency.model';
import moment from 'moment/moment';
import useAccordionStyles from '../../../../Style/MuiStyles/Accordion';
import classnames from 'classnames';

interface DefinedRightsReconductionTypePanelProps {
  endingMillesimeYear: number;
  selectedRightsReconductionType: RightsReconductionType;
  restitutionMethod: RestitutionMethod;
  restitutionIban?: string;
  deadlineDate: Date;
  allowToUpdate: boolean;
  isUpdating: boolean;
  onModifyButtonClicked: () => void;
}

const DefinedRightsReconductionTypePanel: FunctionComponent<DefinedRightsReconductionTypePanelProps> = (props) => {
  const millesimeClasses = useMillesimeInformationStyles();
  const accordionClasses = useAccordionStyles();

  const customAccordionStyle = classnames({
    [millesimeClasses.millesimeAccordion]: true,
    [accordionClasses.accordion]: true,
  });

  const { t } = useTranslation('millesime');

  const {
    endingMillesimeYear,
    selectedRightsReconductionType,
    restitutionMethod,
    restitutionIban,
    deadlineDate,
    allowToUpdate,
    isUpdating,
    onModifyButtonClicked,
  } = props;

  const formattedDate = moment(deadlineDate).format('DD MMMM YYYY');
  const shouldShowRestitutionIban = (selectedRightsReconductionType === 'DROP' && restitutionMethod === 'RESTITUTION_IBAN_PAYOUT');

  const getAccordionSummaryText = (selectedRightsReconductionType: RightsReconductionType, restitutionMethod: RestitutionMethod) => {
    return selectedRightsReconductionType === 'KEEP'
      ? t('definedRightsReconductionTypePanel.definedTypeDescription.KEEP')
      : t(`definedRightsReconductionTypePanel.definedTypeDescription.DROP.${restitutionMethod}`);
  };

  return (
    <div className={millesimeClasses.panelContainer}>
      <div className={millesimeClasses.panelHeader}>
        <Typography>
          {t('definedRightsReconductionTypePanel.title', { endingMillesimeYear })}
        </Typography>
      </div>
      <div className={millesimeClasses.panelBody}>
        <Accordion
          className={customAccordionStyle}
          defaultExpanded={allowToUpdate}
          disabled={!allowToUpdate}
        >
          <AccordionSummary
            expandIcon={allowToUpdate && <ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={millesimeClasses.millesimeAccordionSummary}>
              <div>
                <Typography variant="inherit" component="div">
                  {getAccordionSummaryText(selectedRightsReconductionType, restitutionMethod)}
                </Typography>
              </div>
              <div>
                {shouldShowRestitutionIban &&
                  <div className="restitutionIbanContainer">
                    <Typography variant="inherit" component="div" className={millesimeClasses.secondaryLabel}>
                      {t('definedRightsReconductionTypePanel.restitutionIbanLabel')}
                    </Typography>
                    <Typography variant="inherit" component="div">
                      {restitutionIban || '-'}
                    </Typography>
                  </div>
                }
              </div>
            </div>

          </AccordionSummary>
          <AccordionDetails className={millesimeClasses.accordionDetails}>
            {allowToUpdate
            ? <>
                <Typography variant={'inherit'} className={millesimeClasses.infoMessage}>
                  {t('definedRightsReconductionTypePanel.infoMessage', { deadlineDate: formattedDate })}
                </Typography>
                <Button
                  onClick={onModifyButtonClicked}
                  type="button"
                  disabled={isUpdating}
                  isFetching={isUpdating}
                  variant="contained"
                  color="primary"
                >
                  {t('definedRightsReconductionTypePanel.submitButtonLabel')}
                </Button>
              </>
            : <></>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default DefinedRightsReconductionTypePanel;
