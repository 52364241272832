import { AppBar, Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { provider, useInstance, useInstances } from 'react-ioc';
import backgroundBlockDashboard from '../assets/background-block-dashboard.svg';
import { AgencyModel } from '../assets/models/agencies/Agency.model';
import getVintageDate from '../Function/GetVintageDate';
import { AgenciesStore } from '../Stores/Agencies.store';
import { AgencyInvoiceStore } from '../Stores/AgencyInvoice.store';
import { DashboardViewStore } from '../Stores/viewStore/DashboardView.store';
import DashboardRecapComponent from './Dashboard/DashboardRecap/DashboardRecapComponent';
import DashboardTabsComponent from './Dashboard/DashboardTabsComponent';
import MillesimeBannerComponent from './Dashboard/MillesimeBanner/MillesimeBannerComponent';

type LocalStore = [AgenciesStore, AgencyInvoiceStore];

const DashboardComponent: FunctionComponent = provider(
  DashboardViewStore,
)(observer(() => {
  const [agenciesStore, invoiceStore]: LocalStore = useInstances<LocalStore>(AgenciesStore, AgencyInvoiceStore);
  const store: DashboardViewStore = useInstance<DashboardViewStore>(DashboardViewStore);
  const currentAgency: AgencyModel = agenciesStore.currentAgency;
  const vintageDate: moment.Moment = getVintageDate();

  useEffect(() => {
    if (currentAgency) {
      store.setIsAnyProvisions(false);

      invoiceStore.fetchAgencyInvoices(currentAgency.uid)
        .then(() => {
          if (invoiceStore.invoices.length > 0) {
            store.setIsAnyProvisions(true);
          }
        })
        .catch(e => console.error(e));
    }
  }, [currentAgency]);

  return (
    <div style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <MillesimeBannerComponent/>
      <DashboardRecapComponent/>
      {store.isAnyProvisions && agenciesStore.currentAgency &&
          <div style={{ flex: '1 0 auto', marginTop: '-10px' }}>
              <div style={{
                backgroundColor: 'white',
                borderRadius: '20px',
                margin: '20px 20px 0 20px',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
              }}>
                  <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: `url(${backgroundBlockDashboard}) no-repeat`,
                        backgroundPosition: 'calc(100% + 18em) -25rem',
                        padding: '30px',
                        borderTopRightRadius: '20px',
                      }}>
                      <AppBar
                          position="static"
                          elevation={0}
                          color="transparent">
                          <Tabs
                              value={store.tabNumber}
                              onChange={(event, newTabNumber) => store.setTabNumber(newTabNumber)}>
                              <Tab label="Depuis le début de l’année"/>
                              <Tab label="Depuis un an"/>
                              <Tab label="Sur le millésime"/>
                          </Tabs>
                      </AppBar>
                    {store.tabNumber === 0 &&
                        <DashboardTabsComponent from={moment().startOf('year').unix() * 1000}/>
                    }
                    {store.tabNumber === 1 &&
                        <DashboardTabsComponent from={moment().subtract(1, 'year').unix() * 1000}/>
                    }
                    {store.tabNumber === 2 &&
                        <DashboardTabsComponent from={vintageDate.unix() * 1000}/>
                    }
                  </div>
              </div>
          </div>
      }
    </div>
  );
}));

export default DashboardComponent;
