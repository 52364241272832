import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';

export const useOrderValidationCompStyles = makeStyles(() => ({
  amount: {
    margin: 0,
    color: COLORS.textPrimary,
  },
  title2: {
    fontWeight: 'bold',
    marginBottom: '18px',
  },
  secondBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  thirdBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  subscription: {
    marginBottom: '5px',
  },
  transaction: {
    color: COLORS.grey,
    margin: '10px 0',
  },
  paymentBlock: {
    width: '100%',
    display: 'flex',
    placeItems: 'flex-end',
    flexDirection: 'column',
    margin: '34px 0px 76px 0px',
  },
  excl: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  vat: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  incl: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  total: {
    width: '620px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  input: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '400px',
  },
  inputFullWidth: {
    marginTop: '1rem',
    marginBottom: '1rem',
    minWidth: '60vw',
    width: '100%',
  },
  boxInformation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  start: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  marginTowMetadata: {
    marginLeft: '2rem',
  },
  dissociatedPaymentReferencesArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));
