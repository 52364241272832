import moment from 'moment/moment';
import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReceiptCardStyles } from './ReceiptCardStyles';
import { MillesimeReceiptModel } from '../../assets/models/millesime/MillesimeReceipt.model';
import { RestitutionReceiptType } from '../../assets/models/accountingDocuments/RestitutionReceipt.model';

interface ReceiptCardProps {
  receiptType: RestitutionReceiptType;
  receipt: MillesimeReceiptModel;
  openReceiptDetails: (receiptId: string) => void;
}

const ReceiptCard = (props: ReceiptCardProps) => {
  const { t } = useTranslation('accounting');
  const styledClasses = useReceiptCardStyles(props);
  // TODO manage another receipt type
  const receiptDescription = props.receiptType === 'MILLESIME'
    ? `${t('restitutionReceiptCard.receiptDescription_MILLESIME')} ${props.receipt.year}`
    : '';

  return (
    <div className={styledClasses.block}>
      <div>
        <div className={styledClasses.statusBlock}>
          <div className={styledClasses.status}/>
          <div className={styledClasses.label}>
            {t(`restitutionReceiptCard.statusLabel_${props.receipt.status}`)}
          </div>
        </div>

        <div className={styledClasses.label}>
          {t('restitutionReceiptCard.restitutionDateLabel')}
        </div>
        <div className={styledClasses.value}>
          {moment(props.receipt.restitutionDate).format('DD/MM/YYYY')}
        </div>

        <div className={styledClasses.label}>
          {t('restitutionReceiptCard.receiptNumberLabel')}
        </div>
        <div className={styledClasses.value}>
          {props.receipt.receiptReference}
        </div>

        <div className={styledClasses.label}>
          {t(`restitutionReceiptCard.receiptDescription_${props.receiptType}`)}
        </div>
        <div className={styledClasses.value}>
          {receiptDescription}
        </div>

        <div className={styledClasses.label}>
          {t('restitutionReceiptCard.restitutionAmountLabel')}
        </div>
        <div className={styledClasses.value}>
          {`${new Intl.NumberFormat('fr-FR', {
            style: 'currency', currency: 'EUR',
          }).format(props.receipt.restitutionAmount)}`}
        </div>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => props.openReceiptDetails(props.receipt.uid)}>
        {t('restitutionReceiptCard.actionButtonLabel')}
      </Button>
    </div>
  );
};

export default ReceiptCard;