import { createTheme } from '@material-ui/core/styles';
import { frFR } from '@material-ui/data-grid';
import { COLORS, FONT } from './Style';
import { frFR as coreFrFR } from '@material-ui/core/locale';

export const themeMui = createTheme({
  palette: {
    primary: {
      main: COLORS.white,
    },
  },
  typography: {
    fontFamily: FONT.primary,
  },
  overrides: {
    MuiChip: {
      outlined: {
        border: 'none',
        fontFamily: FONT.primary,
        fontSize: '20px',
        marginLeft: '20px',
        marginBottom: '26px',
        marginTop: '20px',
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      indicator: {
        backgroundColor: COLORS.textPrimary,
        height: '3px',
      },
    },
    MuiTab: {
      root: {
        fontSize: '14px',
        textTransform: 'none',
        height: '60px',
        '&$selected': {
          fontWeight: 600,
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
      textColorInherit: {
        color: COLORS.textPrimary,
        opacity: 1,
        fontWeight: 400,
        // color: COLORS.black,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: COLORS.primary,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: COLORS.footerBlock,
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: 'white',
        backgroundColor: COLORS.primary,
        borderRadius: '20px',
        fontWeight: 'bold',
        height: '50px',
        padding: '0px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: COLORS.textSecondary,
          '@media (hover: none)': {
            backgroundColor: COLORS.textSecondary,
          },
        },
        '&:disabled': {
          backgroundColor: COLORS.textSecondary,
          color: 'white',
          opacity: '0.6',
        },
      },
      containedSecondary: {
        color: COLORS.textPrimary,
        backgroundColor: COLORS.footerBlock,
        borderRadius: '20px',
        fontWeight: 'bold',
        height: '50px',
        padding: '0px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'lightgrey',
        },
        '&:disabled': {
          backgroundColor: COLORS.footerBlock,
          color: COLORS.textPrimary,
          opacity: '0.6',
        },
      },
    },
    MuiSelect: {
      icon: {
        top: 'inherit',
        right: '15px',
      },
    },
    MuiTablePagination: {
      selectIcon: {
        right: '0',
      }
    },
    MuiDrawer: {
      root: {
        height: '100%',
      },
      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: COLORS.textPrimary,
        color: COLORS.white,
        borderRadius: '20px',
        padding: '1rem',
        fontSize: '14px',
        top: '-30px',
        left: '3rem',
      },
    },
  },
}, frFR, coreFrFR);
