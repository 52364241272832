import { makeStyles } from '@material-ui/core';

export const useDocumentsTabStyles = makeStyles(() => ({
  block: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  search: {
    width: '418px',
    margin: '30px 30px 25px 0px'
  },
  documentList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    marginTop: '50px'
  },
  icon: {
    width: 'inherit',
    height: 'inherit'
  },
}));

