import React from 'react';
import { Link } from 'react-router-dom';
import arrowLeft from '../../assets/arrow-left.svg';
import { COLORS } from '../../Style/Style';

interface BackButtonProps {
  linkTo?: string;
  onClick?: () => void;
  className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ linkTo, onClick, className }) => {
  const buttonContent = (
    <img
      src={arrowLeft}
      alt="Back"
      style={{
        width: '20px',
        height: '20px'
      }}
    />
  );

  return linkTo ? (
    <Link
      to={linkTo}
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: '#E0E0E0',
        textDecoration: 'none',
        color: 'black',
        marginRight: '10px'
      }}
    >
      {buttonContent}
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: COLORS.backgroundBlock,
        border: 'none',
        cursor: 'pointer',
      }}
    >
      {buttonContent}
    </button>
  );
};

export default BackButton;