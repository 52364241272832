import {makeStyles} from '@material-ui/core';

export const useYourInformationStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2rem 0',
    },
    block: {
        width: '50%',
    },
    title: {
        textAlign: 'center',
        paddingBottom: '2rem'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'right',
        marginBottom: '40px',
    },
    input: {
        marginTop: '1rem',
        marginBottom: '1rem',
    }
}));
