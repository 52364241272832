import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  useBeneficiaryAccountInformationsStyles
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import BeneficiaryTextField from './BeneficiaryTextFieldComponent';
import Calendar from '../../../assets/calendar';
import StyledSwitch from '../../../Style/MuiStyles/Switch';
import { FunctionFormComponent } from './FunctionFormComponent.model';

export const FIRST_RIGHT_DATE = 'firstRightDate';
export const DAILY_VOUCHER_USAGE_LIMIT = 'dailyVoucherUsageLimit';
export const MONTHLY_VOUCHER_USAGE_LIMIT = 'monthlyVoucherUsageLimit';
export const ACTIVE_SATURDAYS = 'activeSaturdays';
export const ACTIVE_SUNDAYS_AND_HOLIDAYS = 'activeSundaysAndHolidays';

export interface FormValues {
  [FIRST_RIGHT_DATE]: number;
  [DAILY_VOUCHER_USAGE_LIMIT]: number;
  [MONTHLY_VOUCHER_USAGE_LIMIT]: number;
  [ACTIVE_SUNDAYS_AND_HOLIDAYS]: boolean;
  [ACTIVE_SATURDAYS]: boolean;
}

const ActivityFormComponent_Discrete: FunctionFormComponent = observer(({ formik, beneficiary, isEditMode = false, disableModification = false }) => {
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });
  const { t } = useTranslation('beneficiaries');

  return <>
    <p className={beneficiariesAccountInformationsClasses.title}>
      ACTIVITÉ
    </p>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '100%', marginRight: '20px' }}>
        <BeneficiaryTextField
          id={FIRST_RIGHT_DATE}
          type="date"
          InputProps={{ endAdornment: <Calendar style={{ marginTop: '20px' }}/> }}
          formik={formik}
          disabled={isEditMode || disableModification}
          required
        />
      </div>

      <div style={{ width: '100%', marginRight: '20px' }}>
        <BeneficiaryTextField
          id={DAILY_VOUCHER_USAGE_LIMIT}
          type="number"
          formik={formik}
          disabled={disableModification}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            formik.setFieldValue(DAILY_VOUCHER_USAGE_LIMIT, Number(event.target.value));
          }}
          required
        />
      </div>

      <div style={{ width: '100%', marginRight: '20px' }}>
        <BeneficiaryTextField
          id={MONTHLY_VOUCHER_USAGE_LIMIT}
          type="number"
          formik={formik}
          disabled={disableModification}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            formik.setFieldValue(MONTHLY_VOUCHER_USAGE_LIMIT, Number(event.target.value));
          }}
          required
        />
      </div>
    </div>


    <div style={{ minWidth: '350px' }}>
      Travaille les samedis
      <StyledSwitch
        checked={formik.values[ACTIVE_SATURDAYS] ?? false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ACTIVE_SATURDAYS, event.target.checked) }
        name="checkedA"
        color="secondary"
        disabled={disableModification}
        className={beneficiariesAccountInformationsClasses.switchOpacity}
      />
    </div>

    <div style={{ minWidth: '350px' }}>
      Travaille les dimanches et jours fériés
      <StyledSwitch
        checked={formik.values[ACTIVE_SUNDAYS_AND_HOLIDAYS] ?? false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ACTIVE_SUNDAYS_AND_HOLIDAYS, event.target.checked) }
        name="checkedA"
        color="secondary"
        disabled={disableModification}
        className={beneficiariesAccountInformationsClasses.switchOpacity}
      />
    </div>
  </>
}) as FunctionFormComponent;

ActivityFormComponent_Discrete.getValidationSchema = () => {
  return Yup.object().shape({
    [DAILY_VOUCHER_USAGE_LIMIT]: Yup.number()
      .min(1, 'Ce nombre doit être compris entre 1 et 3')
      .max(3, 'Ce nombre doit être compris entre 1 et 3')
      .required(),
    [MONTHLY_VOUCHER_USAGE_LIMIT]: Yup.number()
      .min(1, 'Ce nombre doit être compris entre 1 et 23')
      .max(23, 'Ce nombre doit être compris entre 1 et 23')
      .required(),
  });
};

ActivityFormComponent_Discrete.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [FIRST_RIGHT_DATE]: beneficiary?.firstRightDate
      ? moment(beneficiary.firstRightDate).format('YYYY-MM-DD')
      : moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
    [DAILY_VOUCHER_USAGE_LIMIT]: beneficiary?.dailyVoucherUsageLimit ?? 1,
    [MONTHLY_VOUCHER_USAGE_LIMIT]: beneficiary?.monthlyVoucherUsageLimit ?? 23,
    [ACTIVE_SATURDAYS]: beneficiary?.activeSaturdays ?? false,
    [ACTIVE_SUNDAYS_AND_HOLIDAYS]: beneficiary?.activeSundaysAndHolidays ?? false,
  }
}

export default ActivityFormComponent_Discrete;
