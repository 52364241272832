import React from 'react';
import { TextField, makeStyles, StandardTextFieldProps, CircularProgress } from '@material-ui/core';
import { COLORS, FONT_WEIGHT } from '../Style';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const useTextFieldStyles = makeStyles(() => ({
  textfield: {
    width: '100%',
  },
  root: (props: StyledTextFieldProps) => ({
    borderRadius: '20px',
    backgroundColor: props.color === 'primary' ? COLORS.backgroundBlock : 'white',
    color: COLORS.textPrimary,
    '& .MuiOutlinedInput-root legend': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root fieldset': {
      top: '0px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      '&.Mui-focused fieldset': {
        borderColor: `${COLORS.borderBlock} !important`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: props.color === 'primary' ? 'transparent' : `solid 1px ${COLORS.footerBlock}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.borderBlock,
      },
      '& .MuiOutlinedInput-input': {
        padding: '33px 0px 8px 16px',
        lineHeight: '16px',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error fieldset': {
      border: `1px solid ${COLORS.error} !important`,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(16px, 8px) scale(1)',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '0px 0px 12px 0px',
    },
  }),
  placeholder: {
    transform: 'translate(16px, 32px) scale(1)',
    zIndex: 1,
    position: 'absolute',
    fontWeight: FONT_WEIGHT.semiBold,
    color: COLORS.grey,
    fontSize: '14px',
    '-moz-user-select': 'none',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  inputLabel: {
    color: COLORS.textPrimary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'calc(100% - 30px)',
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.semiBold,
    '&.Mui-focused': {
      color: COLORS.textPrimary,
    },
    '&.Mui-error': {
      color: COLORS.textPrimary,
    },
  },
  inputProps: (props: StyledTextFieldProps) => ({
    color: COLORS.textPrimary,
    fontWeight: FONT_WEIGHT.semiBold,
    fontSize: '14px',
    height: `calc((${props.rows} * 16px) + 48px)`,
  }),
  required: (props: StyledTextFieldProps) => ({
    color: props.error ? COLORS.error : COLORS.grey,
  }),
}));

export interface StyledTextFieldProps extends StandardTextFieldProps {
  errormessage?: string;
  loading?: boolean;
}

// todo bug height multiline
const StyledTextField = ({ className, loading, ...props }: StyledTextFieldProps) => {
  const { ...otherProps } = props;
  const textFieldClasses = useTextFieldStyles(props);
  const { t } = useTranslation('common');

  return (
    <div className={classnames(textFieldClasses.textfield, className)}>
      <TextField
        {...otherProps}
        placeholder={props.placeholder}
        className={textFieldClasses.root}
        label={
          <>
            <div>{props.label}</div>
            {loading && <CircularProgress style={{ marginLeft: 15 }} size={25} color={'primary'}/> }
            {props.required && <div className={textFieldClasses.required}>{t('required')}</div>}
          </>
        }
        InputLabelProps={{
          shrink: true,
          required: false,
          className: textFieldClasses.inputLabel,
        }}
        variant="outlined"
        classes={{
          root: textFieldClasses.root,
        }}
      />
      {props.error &&
          <div style={{ color: COLORS.error, margin: '16px 0 0 16px', fontSize: 14 }}>{props.errormessage}</div>
      }
    </div>
  );
};

StyledTextField.defaultProps = {
  color: 'primary',
};

export default StyledTextField;
