import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';

export const useAppUnavailableStyles = makeStyles({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '20px',
  },
  openEatLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',

    '& *': {
      margin: '5px',
    },
  },
  messageBox: {
    backgroundColor: COLORS.primary,
    maxWidth: '600px',
    lineHeight: '1.4rem',
    padding: '20px',
    color: 'white',
    margin: '50px auto',
    borderRadius: '20px',
  },
  message: {
    marginBottom: '20px',
  }
});
