import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../Style/Style';

export const useBeneficiaryPaybackComponentStyles = makeStyles(() => ({
  title: {
    margin: '0',
    letterSpacing: '2px',
    padding: '40px 50px 40px 0px',
    color: COLORS.textPrimary
  },
  search: {
    width: '418px',
    margin: '30px 30px 25px 0px'
  },
  icon: {
    width: 'inherit',
    height: 'inherit'
  },
  block: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));
