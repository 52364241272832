import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../Style/Style';

export const useMandateSignatureStyles = makeStyles(() => ({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px'
  },
  imgClose: {
    cursor: 'pointer'
  },
  blockSvg: {
    width: '100%',
    padding: '50px',
    paddingTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mandateSvg: {
    width: '125px'
  },
  subtitleSvg: {
    fontSize: '14px',
    paddingTop: 30,
    width: '100%',
    textAlign: 'center',
  },
  blockButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 30
  },
  confirmButton: {
    marginLeft: 20,
    marginRight: 20
  },
  confirmButtonSvg: {
    marginLeft: 10
  }
}));
