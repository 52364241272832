import { makeStyles } from '@material-ui/core';

export const usePageLoaderStyles = makeStyles({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
});
