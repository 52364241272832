import PrintQRCodeButton from '../BeneficiaryAccountInformations/PrintQRCodeButton';
import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import {
  useBeneficiaryAccountInformationsStyles,
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import RegenQRCodeButton from '../BeneficiaryAccountInformations/RegenQRCodeButton';

export interface IQrCodeSectionProps {
  qrcodeString: string;
  isLoading: boolean;
  onRequestRegenQRCode: (confirm: boolean) => Promise<void>;
}

const QrCodeSectionComponent: FunctionComponent<IQrCodeSectionProps> = ({ qrcodeString, isLoading, onRequestRegenQRCode }) => {
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: false,
  });

  const { t } = useTranslation('beneficiaries');

  return <>
    <div className={beneficiariesAccountInformationsClasses.sectionContainer}>
      <p className={beneficiariesAccountInformationsClasses.title}>
        QR CODE
      </p>
      {isLoading
        ? (
          <Skeleton variant="rect" width={190} height={50} style={{ borderRadius: '20px' }}/>
        )
        : (
          <div className={beneficiariesAccountInformationsClasses.qrCodeButtonContainer}>
              <RegenQRCodeButton
                buttonLabel={t('qrCodeRegenButton')}
                onRequestRegenQRCode={onRequestRegenQRCode}
              />
            <PrintQRCodeButton
              title=""
              qrCodeString={qrcodeString}
              buttonLabel={t('qrCodeButtonLabel')}
            />
          </div>
        )
      }
    </div>
  </>;
};

export default QrCodeSectionComponent;