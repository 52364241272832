import { CircularProgress } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { MandateStatus } from '../../../../assets/models/mandates/mandate.model';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { MandateStore } from '../../../../Stores/Mandate.store';
import { OrderPaymentViewStore } from '../../../../Stores/viewStore/OrderPaymentView.store';
import { StepperRechargingStore } from '../../../../Stores/viewStore/StepperRecharging.store';
import ButtonComponent from '../../../../Style/MuiStyles/Button';
import { useMethodDirectDebitStyles } from './MethodDirectDebitStyles';

type LocalStore = [OrderPaymentViewStore, StepperRechargingStore, MandateStore, AgenciesStore];
type LocalStyles = 'container' | 'title' | 'text' | 'blockText' | 'confirmButton' | 'blockButtons';

const MethodDirectDebit = observer(() => {
  const [
    orderPaymentViewStore,
    stepperRechargingStore,
    mandateStore,
    agenciesStore
  ]: LocalStore = useInstances<LocalStore>(OrderPaymentViewStore, StepperRechargingStore, MandateStore, AgenciesStore);
  const { t } = useTranslation('payment');
  const methodDirectStyles: ClassNameMap<LocalStyles> = useMethodDirectDebitStyles();

  const mandateStatus: MandateStatus = mandateStore.mandateStatus;
  const mandateSignedLoading: boolean = mandateStore.isFindingMandate;
  const totalTTC: string = orderPaymentViewStore.totalTTC;
  const today: string = new Date().toLocaleDateString();
  const iban: string = agenciesStore.currentAgency.iban;

  return (
    <>
      { mandateSignedLoading
        ? <CircularProgress size={64} color={'primary'}/>
        : <div className={methodDirectStyles.container}>
          {mandateStatus === 'ACTIVE' &&
              <>
                <h2 className={methodDirectStyles.title}>
                  {t('directDebit.title', { amount: totalTTC })}
                </h2>
                <div className={methodDirectStyles.blockText}>
                  <p>{t('directDebit.titleAccount')}</p>
                  <p className={methodDirectStyles.text}>
                    {t('directDebit.accountNumber', { accountNumber: iban })}
                  </p>
                </div>
                <div className={methodDirectStyles.blockText}>
                  <p>{t('directDebit.titleDateDebit')}</p>
                  <p className={methodDirectStyles.text}>
                    {t('directDebit.dateDebit', { date: today })}
                  </p>
                </div>
              </>
          }
          { mandateStatus !== 'ACTIVE' &&
              <>
                <h2 className={methodDirectStyles.title}>
                  {t('directDebit.noteMandate.title')}
                </h2>
                <p>
                  {t('directDebit.noteMandate.text')}
                </p>
                <div className={methodDirectStyles.blockButtons}>
                  <ButtonComponent
                    variant='contained'
                    color='secondary'
                    className={methodDirectStyles.confirmButton}
                    // disabled={payingServiceStore.createPayingLoading}
                    // isFetching={payingServiceStore.createPayingLoading}
                    onClick={() => stepperRechargingStore.goToPreviousStep()}
                    type='button'>
                    {t('directDebit.noteMandate.btnCancel')}
                  </ButtonComponent>
                  <ButtonComponent
                    variant='contained'
                    color='primary'
                    className={methodDirectStyles.confirmButton}
                    // disabled={payingServiceStore.createPayingLoading}
                    // isFetching={payingServiceStore.createPayingLoading}
                    onClick={() => orderPaymentViewStore.setOpenMandateDialog(true)}
                    type='button'>
                    {t('directDebit.noteMandate.btnValid')}
                  </ButtonComponent>
                </div>
              </>
          }
        </div>}
    </>
  );
});

export default MethodDirectDebit;
