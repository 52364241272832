import { CircularProgress } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import AllocatedRightChartComponent from './ChartComponents/AllocatedRightChart/AllocatedRightChartComponent';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { PayinStore } from '../../Stores/Payin.store';
import { PayinReportModel } from '../../assets/models/payins/PayinReport.model';

export interface IDashboardTabsComponentProps {
  from: number;
}

type LocalStore = [AgenciesStore, PayinStore];

const DashboardTabsComponent:FunctionComponent<IDashboardTabsComponentProps> = observer((props: IDashboardTabsComponentProps) => {
  const [agenciesStore, payinStore]: [AgenciesStore, PayinStore] = useInstances<LocalStore>(AgenciesStore, PayinStore);
  const [isFetching, setIsFetching] = useState(true);
  const [payinReport, setPayinReport] = useState<PayinReportModel[]>([]);

  function filterPayinReport(report) {
    setPayinReport(report.filter(monthlyData => monthlyData.totalAmountOfPayins !== 0));
  }

  useEffect(() => {
    if (agenciesStore.currentAgency) {
      setIsFetching(true);
      payinStore.fetchAgencyPayinReports(agenciesStore.currentAgency.uid, props.from)
        .then(() => filterPayinReport(payinStore.agencyPayinReport))
        .catch(e => console.error(e))
        .finally(() => setIsFetching(false));
    }
  }, [agenciesStore.currentAgency]);

  return (
    <>
      <CircularProgress style={{ display: isFetching ? 'inherit' : 'none', margin: 36 }} size={64} color={'primary'} />
      <div style={{ display: isFetching ? 'none' : 'initial' }}>
        {!isFetching &&
            <AllocatedRightChartComponent from={props.from} payinReport={payinReport} />
        }
      </div>
    </>
  );
});

export default DashboardTabsComponent;
