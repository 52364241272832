import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../Style/Style';

export const useMethodBankTransferStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.backgroundBlock,
    padding: '30px',
    fontSize: 14
  },
  title: {
    fontSize: 16
  },
  subTitle: {
    paddingBottom: '15px'
  },
  iban: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: COLORS.textPrimary,
    borderRadius: '10px',
    padding: '12px 18px',
    marginBottom: 20
  },
  ibanNumber: {
    fontWeight: "bold",
    padding: '10px 0'
  }
}));
