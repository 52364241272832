import React from 'react';
import { makeStyles, Snackbar, SnackbarContent, SnackbarProps } from '@material-ui/core';
import { COLORS, FONT } from '../Style';

const useSnackbarStyles = makeStyles(() => ({
  snackbarContent: (props: SnackbarCustomProps) => ({
    background: props.type === 'success'
      ? `linear-gradient(to right, ${COLORS.succeededStatus} 4%, white 4%)`
      : `linear-gradient(to right, ${COLORS.error} 4%, white 4%)`,
    borderRadius: '20px'
  }),
  snackbarMessage: (props: SnackbarCustomProps) => ({
    height: props.type === 'error' ? '120px' : 'auto',
    width: props.type === 'error' ? '455px' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: COLORS.textPrimary,
    marginLeft: '1rem',
    fontFamily: FONT.primary
  }),
}));

interface SnackbarCustomProps extends SnackbarProps{
    isOpen: boolean;
    autoHideDuration?: number;
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
    onClose?: () => void;
    type?: 'error' | 'success';
}

const OpenSnackbar = (props: SnackbarCustomProps) => {
  const snackbarStyles = useSnackbarStyles(props);
  const { children } = props;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: props.horizontal
      }}
      open={props.isOpen}
      onClose={props.onClose}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 1000}
    >
      <SnackbarContent
        className={snackbarStyles.snackbarContent}
        message={
          <div className={snackbarStyles.snackbarMessage}>
            {children}
          </div>
        } />
    </Snackbar>
  );
};

export default OpenSnackbar;
