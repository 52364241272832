import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export const COLORS = {
  primary: '#86D0EF',
  textPrimary: '#1D1D1B',
  textSecondary: '#79BEDB',
  textMuted: '#A3AAB2',
  error: '#FC7753',
  backgroundBlock: '#F4F4F4',
  footerBlock: '#EDEFF0',
  borderBlock: '#D5D9DC',
  greyRgb247: '#E8E8E8',
  white: '#FFFFFF',
  black: '#000000',
  validated: '#82E0AA',
  success: 'rgba(140,216,103,0.2)',
  lightError: 'rgba(233,75,50,0.21)',
  active: '#8CD867',
  failed: '#FFC689',
  blackLight: '#515A5A',
  veryLightGrey: '#EDEFF0',
  lightGrey: '#E6E1E3',
  grey: '#8E8E8D',
  darkGrey: '#1D1D1B',
  orangeLight: '#FFC15E',

  pendingStatus: '#FFC15E',
  succeededStatus: '#8CD867',
  failedStatus: '#E94B32',
};

export const HOVER = {
  blockShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
};

export const FONT = {
  primary: '"Montserrat", sans-serif',
  secondary: '"Montserrat", sans-serif',
};

export const FONT_WEIGHT = {
  normal: 400,
  semiBold: 500,
  bold: 600,
};

// eslint-disable-next-line react/prop-types
export const PageBlock = ({ children, className = undefined }) => {
  return (
    <div
      className={className}
      style={{
        backgroundColor: 'white',
        margin: '20px',
        height: 'calc(100% - 40px)',
        borderRadius: '20px',
        padding: '30px',
        fontFamily: FONT.primary,
        color: COLORS.textPrimary,
      }}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/prop-types
export const PageSubtitle = ({ children }) => {
  return (
    <div style={{
      marginTop: '1rem',
      marginBottom: '16px',
      color: COLORS.textPrimary,
      fontSize: '14px',
    }}>
      {children}
    </div>
  );
};

export const useAppBarStyles = makeStyles(() => ({
  appBar: {
    borderBottom: `1px solid ${COLORS.borderBlock}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${COLORS.borderBlock}`,
  },
  tab: {
    minWidth: 300,
  },
}));
