import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import arrow from '../../../../assets/arrow.svg';
import close from '../../../../assets/close.svg';
import { InvoiceModel } from '../../../../assets/models/invoices/invoice.model';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import Button from '../../../../Style/MuiStyles/Button';
import { useDocumentDetailsStyles } from '../../DocumentDetailsStyles';
import InvoiceFeesSummary from './InvoiceFeesSummary';
import InvoiceProvisionsSummary from './InvoiceProvisionsSummary';
import CopyButton from '../../../../Component/CopyButton/CopyButton';

export interface IInvoiceDetailsComponentProps {
  invoice: InvoiceModel;
  onClose: () => void;
}

const InvoiceDetailsComponent: FunctionComponent<IInvoiceDetailsComponentProps> = observer((props: IInvoiceDetailsComponentProps) => {
  const agenciesStore: AgenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const invoiceDetailsClasses = useDocumentDetailsStyles({ document: props.invoice, invoiceType: props.invoice.invoiceType });
  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const statusDate: Date = new Date(props.invoice.statusDate);
  const { t } = useTranslation('accounting');
  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  const netAmountPayable: number = props.invoice.netAmount;

  const displayBankWireDescriptor: boolean = ['BANK_TRANSFER', 'DISSOCIATED_BANK_TRANSFER'].includes(props.invoice.paymentMethod);
  const displayFees: boolean = ['STANDARD', 'DISSOCIATED_FEES'].includes(props.invoice.invoiceType);
  const displayProvisions: boolean = ['STANDARD', 'DISSOCIATED_PROVISIONS'].includes(props.invoice.invoiceType) && agenciesStore.currentAgency?.isManagedPaymentMode;
  const displayTotal: boolean = props.invoice.invoiceType === 'STANDARD' && agenciesStore.currentAgency?.isManagedPaymentMode;

  return (
    <div className={invoiceDetailsClasses.block}>
      <div className={invoiceDetailsClasses.header}>
        <h1>{t(`invoiceDetails.title_${props.invoice.invoiceType}`, {
          reference: props.invoice.invoiceReference, date: moment(props.invoice.creationDate).format('DD/MM/YYYY'),
        })}</h1>
        <img className={invoiceDetailsClasses.imgClose} alt="close" src={close} onClick={props.onClose}/>
      </div>
      <p className={invoiceDetailsClasses.subtitle}>{t('invoiceDetails.subtitle')}</p>
      <div className={invoiceDetailsClasses.status} >
        <div className={invoiceDetailsClasses.statusBlock}>
          <div className={invoiceDetailsClasses.circle}/>
          <span>
          {props.invoice.status === 'SUCCEEDED'
            ? `${props.invoice.paymentMethod === 'DIRECT_DEBIT'
              ? t('invoiceDetails.statusPaidDirectDebit')
              : t('invoiceDetails.statusPaidBankTransfer')} ${statusDate.toLocaleDateString('fr-FR')}`
            : t(`invoiceDetails.statusToPay_${props.invoice.invoiceType}`, {mode: props.invoice.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement'})}
        </span>
        </div>

        { displayBankWireDescriptor &&
            <div className={invoiceDetailsClasses.statusBlock}>
                <span>{t('invoiceDetails.expectedDescriptorLabel')}</span>
                <div className={invoiceDetailsClasses.statusBlock}>
                    <span className={invoiceDetailsClasses.expectedDescriptor}>{props.invoice.bankWireReference}</span>
                    <CopyButton value={props.invoice.bankWireReference} />
                </div>
            </div>
        }
      </div>

      { displayFees &&
          <InvoiceFeesSummary
              invoice={props.invoice}
              currencyFormat={currencyFormat}
              dateOptions={dateOptions} />
      }

      { displayProvisions &&
          <InvoiceProvisionsSummary
              invoice={props.invoice}
              currencyFormat={currencyFormat}
              dateOptions={dateOptions} />
      }

      { displayTotal &&
          <div className={invoiceDetailsClasses.paymentBlock}>
              <div className={invoiceDetailsClasses.total}>
                  <p>{t('invoiceDetails.total')}</p>
                  <p className={invoiceDetailsClasses.amount}>
                    {currencyFormat.format(netAmountPayable)} TTC
                  </p>
              </div>
          </div>
      }

      <div className={invoiceDetailsClasses.buttonBlock}>
        {props.invoice.invoiceUrl
          ? <a href={props.invoice.invoiceUrl} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="secondary">
              <>
                <span className={invoiceDetailsClasses.download}>{t(`invoiceDetails.download_${props.invoice.invoiceType}`)}</span>
                <img width={12} src={arrow} alt="down-arrow"/>
              </>
            </Button>
          </a>
          : <div/>
        }
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary">
          {t('invoiceDetails.close')}
        </Button>
      </div>
    </div>
  );
});

export default InvoiceDetailsComponent;
