import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  useBeneficiaryAccountInformationsStyles
} from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsStyles';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import BeneficiaryTextField from './BeneficiaryTextFieldComponent';
import Calendar from '../../../assets/calendar';
import StyledSwitch from '../../../Style/MuiStyles/Switch';
import { FunctionFormComponent } from './FunctionFormComponent.model';

export const NUMBER_OF_WORKING_DAYS = 'numberOfWorkingDays';
export const FIRST_RIGHT_DATE = 'firstRightDate';
export const ACTIVE_SUNDAYS_AND_HOLIDAYS = 'activeSundaysAndHolidays';

export interface FormValues {
  [NUMBER_OF_WORKING_DAYS]: number;
  [FIRST_RIGHT_DATE]: number;
  [ACTIVE_SUNDAYS_AND_HOLIDAYS]: boolean;
}

const ActivityFormComponent_Linear: FunctionFormComponent = observer(({ formik, beneficiary, isEditMode = false, disableModification = false }) => {
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
    disabled: disableModification,
  });
  const { t } = useTranslation('beneficiaries');

  return <>
    <p className={beneficiariesAccountInformationsClasses.title}>
      ACTIVITÉ
    </p>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ minWidth: '260px', marginRight: '20px' }}>
        <BeneficiaryTextField
          id={FIRST_RIGHT_DATE}
          type="date"
          InputProps={{ endAdornment: <Calendar style={{ marginTop: '20px' }}/> }}
          formik={formik}
          disabled={isEditMode || disableModification}
          required
        />
      </div>

      <div style={{ minWidth: '220px', marginRight: '20px' }}>
        <BeneficiaryTextField
          id={NUMBER_OF_WORKING_DAYS}
          type="number"
          formik={formik}
          disabled={disableModification}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            formik.setFieldValue(NUMBER_OF_WORKING_DAYS, Number(event.target.value));
          }}
          required
        />
      </div>

      <div style={{ minWidth: '350px' }}>
        Travaille le dimanche et les jours fériés
        <StyledSwitch
          checked={formik.values[ACTIVE_SUNDAYS_AND_HOLIDAYS]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ACTIVE_SUNDAYS_AND_HOLIDAYS, event.target.checked)}
          name="checkedA"
          color="secondary"
          disabled={disableModification}
          className={beneficiariesAccountInformationsClasses.switchOpacity}
        />
      </div>


    </div>
  </>
}) as FunctionFormComponent;

ActivityFormComponent_Linear.getValidationSchema = () => {
  return Yup.object().shape({
    [NUMBER_OF_WORKING_DAYS]: Yup.number()
      .min(0, 'Ce nombre doit être compris entre 0 et 31')
      .max(31, 'Ce nombre doit être compris entre 0 et 31')
      .required(),
  });
}

ActivityFormComponent_Linear.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [NUMBER_OF_WORKING_DAYS]: beneficiary?.numberOfWorkingDays ?? 0,
    [FIRST_RIGHT_DATE]: beneficiary?.firstRightDate
      ? moment(beneficiary.firstRightDate).format('YYYY-MM-DD')
      : moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
    [ACTIVE_SUNDAYS_AND_HOLIDAYS]: beneficiary?.activeSundaysAndHolidays ?? false,
  }
}

export default ActivityFormComponent_Linear;
