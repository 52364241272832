import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

interface MonthYearPickerProps {
  handleOnSelected: (monthIndex: number, fullYear: number) => void;
  initialMonthIndex?: number,
  initialYear?: number,
  minYear?: number;
  maxYear?: number;
}

export const MonthYearPicker = (props: MonthYearPickerProps) => {
  const styles = useMonthYearPickerStyles();
  const { t } = useTranslation('common');

  const now = new Date();
  const currentYear = now.getFullYear();
  const {
    handleOnSelected,
    initialMonthIndex = null,
    initialYear = currentYear,
    minYear = currentYear,
    maxYear = currentYear + 10,
  } = props;

  const months: Record<number, string> = {
    0: t('januaryLabel') || 'January',
    1: t('februaryLabel') || 'February',
    2: t('marchLabel') || 'March',
    3: t('aprilLabel') || 'April',
    4: t('mayLabel') || 'May',
    5: t('juneLabel') || 'June',
    6: t('julyLabel') || 'July',
    7: t('augustLabel') || 'August',
    8: t('septemberLabel') || 'September',
    9: t('octoberLabel') || 'October',
    10: t('novemberLabel') || 'November',
    11: t('decemberLabel') || 'December',
  };

  const [selectedYear, setSelectedYear] = useState<number>(initialYear);

  const handleMonthChange = (monthIndex: number) => {
    handleOnSelected(monthIndex, selectedYear);
  };

  const handleYearChange = (offset: number) => {
    setSelectedYear(selectedYear + offset);
  };

  return (
    <div className={styles.container}>

      <div className={styles.yearPicker}>
        <button
          className={styles.arrowButton}
          onClick={() => handleYearChange(-1)}
          disabled={selectedYear <= minYear}
        >
          &lt;
        </button>

        <span className={styles.year}>{selectedYear}</span>

        <button
          className={styles.arrowButton}
          onClick={() => handleYearChange(1)}
          disabled={selectedYear >= maxYear}
        >
          &gt;
        </button>
      </div>

      <div className={styles.monthGrid}>
        {Object.keys(months).map((monthIndex) => (
          <button
            key={monthIndex}
            className={classnames(styles.monthButton, { [styles.monthButtonSelected]: Number(monthIndex) === initialMonthIndex })}
            onClick={() => handleMonthChange(Number(monthIndex))}
          >
            {months[monthIndex]}
          </button>
        ))}
      </div>
    </div>
  );
};

const useMonthYearPickerStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.backgroundBlock,
    padding: '1rem',
    borderRadius: '10px',
    boxShadow: `5px 5px 5px ${COLORS.borderBlock}`,
    fontSize: '12px',
    textAlign: 'center',
  },
  yearPicker: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  arrowButton: {
    padding: '5px 10px',
    cursor: 'pointer',
    border: `1px solid ${COLORS.borderBlock}`,
    borderRadius: '4px',
    margin: '0 10px',
    '&:hover': {
      fontWeight: 'bolder',
    },
  },
  year: {
    fontWeight: 'bold',
  },
  monthGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '10px',
  },
  monthButton: {
    padding: '5px',
    fontFamily: 'inherit',
    cursor: 'pointer',
    border: `1px solid ${COLORS.borderBlock}`,
    borderRadius: '4px',
    '&:hover': {
      color: COLORS.white,
      fontWeight: 'bolder',
      backgroundColor: COLORS.grey,
      border: 'none',
    },
  },
  monthButtonSelected: {
    color: COLORS.white,
    fontWeight: 'bolder',
    backgroundColor: COLORS.blackLight,
    border: 'none',
  },
}));
