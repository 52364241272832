import React from 'react';
import mandateSvg from '../../../assets/mandateSignedError.svg';
import ButtonComponent from '../../../Style/MuiStyles/Button';
import { useMandateSignatureStyles } from './MandateSignatureDialogStyles';
import { IMandateSignatureDialog } from './MandateSignatureDialog';
import { useInstances } from 'react-ioc';
import { MandateStore } from '../../../Stores/Mandate.store';
import { AgenciesStore } from '../../../Stores/Agencies.store';

const MandateSignatureError = (props: IMandateSignatureDialog) => {
  const [mandateStore, agenciesStore] = useInstances(MandateStore, AgenciesStore);
  const mandateDialogStyles = useMandateSignatureStyles();
  const addMandate = () => mandateStore.createMandate(agenciesStore.currentAgency?.uid);

  return (
    <>
      <div className={mandateDialogStyles.blockSvg}>
        <img className={mandateDialogStyles.mandateSvg} src={mandateSvg} alt={'mandate signature'}/>
        <p className={mandateDialogStyles.subtitleSvg}>
          Nous sommes désolés, nous avons rencontré un problème technique, merci de relancer la procédure de signature.
        </p>
      </div>
      <div className={mandateDialogStyles.blockButtons}>
        <ButtonComponent
          variant='contained'
          color='secondary'
          className={mandateDialogStyles.confirmButton}
          onClick={props.onClose}
          type='button'>
          {'Annuler'}
        </ButtonComponent>
        <ButtonComponent
          variant='contained'
          color='primary'
          className={mandateDialogStyles.confirmButton}
          onClick={addMandate}
          type='button'>
          {'Relancer la procédure de signature'}
        </ButtonComponent>
      </div>
    </>
  );
};

export default MandateSignatureError;
