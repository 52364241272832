import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';

const blockMargin = '37px';

export const useCreateOrderResponseStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  header: {
    marginTop: '58px',
    marginBottom: '28px'
  },
  importantText: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  image: {
    marginBottom: blockMargin
  },
  bankTransferSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderRadius: 10,
    backgroundColor: COLORS.backgroundBlock,
    padding: '30px',
    marginTop: blockMargin
  },
  descriptionBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    marginLeft: '20px',
  },
  icon: {
    margin: '0 10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: blockMargin
  },
  dissociatedBankTransferSection: {
    display: 'flex',
    borderRadius: 10,
    backgroundColor: COLORS.backgroundBlock,
    padding: '30px',
    marginTop: blockMargin
  },
  descriptionDissBlock: {
    display: 'flex',
    paddingTop: '2rem'
  },
  dissociatedDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '2rem',
  },
  dissociatedDescriptionText: {
    paddingBottom: '1rem',
  },
  dissociatedDescriptionTextLine: {
   textDecoration: 'underline'
  }
}));
