export function isSunday(date: Date): boolean {
  return date.getDay() === 0;
}

export function isSaturday(date: Date): boolean {
  return date.getDay() === 6;
}

export function getYear(date: Date): number {
  return date.getFullYear();
}

export function getHumanMonth(date: Date): number {
  return date.getMonth() + 1;
}

export function getDayOfTheMonth(date: Date): number {
  return date.getDate();
}

export function getIndexDayOfTheWeekStartAtMonday(date: Date): number {
  const indexDayOfTheWeek = date.getDay();
  return (indexDayOfTheWeek - 1 + 7) % 7;
}

export function getMondayOfTheWeek(date: Date): Date {
  const dayOfTheMonth = date.getDate();
  const indexDayOfTheWeekStartAtMonday = getIndexDayOfTheWeekStartAtMonday(date);
  const diff = dayOfTheMonth - indexDayOfTheWeekStartAtMonday;
  const dateClone: Date = new Date(date);

  return new Date(dateClone.setDate(diff));
}

export function getSundayOfTheWeek(date: Date): Date {
  const monday: Date = getMondayOfTheWeek(date);
  const diff = monday.getDate() + 6;
  const mondayClone: Date = new Date(monday);

  return new Date(mondayClone.setDate(diff));
}

export function toISOStringDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}

export function getFormattedStringMonth(date: Date): string {
  return String(getHumanMonth(date)).padStart(2, '0');
}

export function getFormattedStringDay(date: Date): string {
  return String(getDayOfTheMonth(date)).padStart(2, '0');
}

export function getTimestampFromUnixEpoch(epochTimestamp: number): number {
  return epochTimestamp * 1000;
}

export const FRENCH_MONTHS: string[] = ['JANVIER', 'FÉVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOÛT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DÉCEMBRE'];
