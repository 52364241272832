import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { GridColDef, GridRowModel, GridSortItem, GridSortModel, GridValueGetterParams } from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../Style/Style';
import Button from '../Style/MuiStyles/Button';
import StyledDataGrid from '../Style/MuiStyles/Datagrid';
import close from '../assets/close.svg';
import arrow from '../assets/arrow.svg';
import { formatCurrencyToEuro, formatDateToLocaleString } from '../Utils/format.utils';
import { OrderInvoices } from '../Stores/AgencyInvoice.store';
import { getAgencyOrderProvision, getAgencyOrderProvisionSummary } from '../Services/ordersAPI.service';
import { BeneficiaryCreditModel } from '@assets/models/orders/Order.model';
import { isDefaultClassification } from '../assets/utils/agencies/beneficiaryClassification.util';
import {
  BeneficiaryClassificationProvisionSummary
} from '@assets/models/beneficiaries/BeneficiaryClassification.model';

const useStyles = makeStyles({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px',
  },
  classificationsWrapper: {
    display: 'flex',
    gap: '24px',
    marginTop: '30px',
  },
  classificationCard: {
    backgroundColor: COLORS.backgroundBlock,
    borderRadius: '20px',
    padding: '1rem 1.5rem',
  },
  classificationCardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  classificationCardBody: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
  },
  classificationCardFieldRow: {

  },
  classificationCardFieldLabel: {
    fontSize: '14px',
    marginBottom: '5px',
  },
  classificationCardFieldValue: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  amount: {
    fontSize: '20px',
    margin: 0,
    color: COLORS.textPrimary,
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px',
  },
  title: {
    margin: '0',
    letterSpacing: '2px',
    padding: '40px 50px 40px 0px',
    color: COLORS.textPrimary,
  },
  imgClose: {
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '1rem',
  },
  downloadsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    gap: '1rem',
  },
  download: {
    marginRight: '1rem',
    textDecoration: 'none'
  },
});

export interface IReloadingDetailsComponentProps {
  orderInvoices: OrderInvoices;
  onClose: () => void;
}

const dataGridColumnsDefinition: GridColDef[] = [
  {
    field: 'beneficiaryName',
    headerName: 'Bénéficiaire',
    flex: 1,
  },
  {
    field: 'numberOfRechargingDays',
    headerName: 'Jours travaillés',
    flex: 1,
  },
  {
    field: 'contractFacialValue',
    headerName: 'Droit quotidien',
    flex: 1,
    valueFormatter: (params: GridValueGetterParams) => formatCurrencyToEuro(params.row.contractAgencyPart),
  },
  {
    field: 'provisionAmount',
    headerName: 'Montant total',
    flex: 1,
    valueFormatter: ({ row }: { row: GridRowModel }) => formatCurrencyToEuro(row.provisionAmount),
  },
];

const OrderDetailsComponent: FunctionComponent<IReloadingDetailsComponentProps> = (props: IReloadingDetailsComponentProps) => {
  const { orderInvoices: { agencyUid, orderUid, paymentMethod, orderReference, creationDate, mainInvoice, feesInvoice }, onClose } = props;
  const classes = useStyles();
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState(true);
  const [beneficiaryProvisionList, setBeneficiaryProvisionList] = useState([]);
  const { t } = useTranslation(['reloading', 'common']);

  const [classifications, setClassifications] = useState<BeneficiaryClassificationProvisionSummary[]>([]);

  const defaultSort: GridSortItem = {field: 'beneficiaryName', sort: 'asc'};
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState<GridSortItem>(defaultSort);

  useEffect(() => {
    setIsLoadingSummary(true);
    (async () => {
      const response = await getAgencyOrderProvisionSummary(agencyUid, orderUid);

      setClassifications(response.classifications);
      setIsLoadingSummary(false);
    })();
  }, []);

  useEffect(() => {
    const isDateAfterThreshold: boolean = creationDate >= new Date('2022/09/14').getTime();
    setIsDataAvailable(isDateAfterThreshold);
    setIsLoading(isDateAfterThreshold);

    if (isDateAfterThreshold) {
      setBeneficiaryProvisionList([]);
      (async () => {
        const response = await getAgencyOrderProvision(agencyUid, orderUid, {
          requiredPage: page,
          pageSize,
          sort: sort.field as keyof BeneficiaryCreditModel,
          direction: sort.sort,
        });

        setBeneficiaryProvisionList(response.items);
        setCount(response.count);

        setIsLoading(false);
      })();
    }
  }, [creationDate, agencyUid, orderUid, page, pageSize, sort]);

  const updateSort = useCallback((sortModel: GridSortModel) => {
    const [sort] = sortModel;

    setSort(sort ?? defaultSort);
  }, []);

  return (
    <div className={classes.block}>

      <div className={classes.header}>
        <h1>{`Détail du rechargement N°${orderReference} du ${formatDateToLocaleString(creationDate)}`}</h1>
        <img className={classes.imgClose} alt="close" src={close} onClick={onClose}/>
      </div>

      <span>Vous pouvez consulter, ici, la liste des rechargements effectués sur chacun des bénéficiaires concernés</span>

      <div className={classes.classificationsWrapper}>
        {
          isLoadingSummary && (
            <CircularProgress size={32} color={'primary'}/>
          )
        }
        {
          classifications.map(classification => (
            <div key={classification.uid} className={classes.classificationCard}>
              {
                !(classifications.length === 1 && isDefaultClassification(classification)) && (
                  <div className={classes.classificationCardTitle}>
                    {classification.name}
                  </div>
                )
              }
              <div className={classes.classificationCardBody}>
                <div className={classes.classificationCardFieldRow}>
                  <p className={classes.classificationCardFieldLabel}>Total jours travaillés</p>
                  <span className={classes.classificationCardFieldValue}>{classification.numberOfWorkingDays}</span>
                </div>
                <div className={classes.classificationCardFieldRow}>
                  <p className={classes.classificationCardFieldLabel}>Total droits</p>
                  <span className={classes.classificationCardFieldValue}>{formatCurrencyToEuro(classification.provisionAmount)}</span>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <p className={classes.title}>
        LISTE DES DROITS ATTRIBUÉS
      </p>

      {isDataAvailable &&
          <StyledDataGrid
              rows={beneficiaryProvisionList}
              columns={dataGridColumnsDefinition}
              showColumnRightBorder={false}
              showCellRightBorder={false}
              disableSelectionOnClick
              loading={isLoading}
              rowCount={count}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              onSortModelChange={updateSort}
              paginationMode={'server'}
          />
      }

      {!isDataAvailable &&
          <Box textAlign="center" fontSize="body2.fontSize" marginTop={30}>
              Nous sommes désolés, nous ne sommes pas en mesure d'afficher<br/>
              le détail des rechargements effectués avant le 14/09/2022.
          </Box>
      }

      <div className={classes.downloadsContainer}>
        { paymentMethod === 'DISSOCIATED_BANK_TRANSFER' && feesInvoice?.invoiceUrl
          ? <a href={feesInvoice.invoiceUrl} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="secondary">
              <>
                <span className={classes.download}>Facture abonnement</span>
                <img width={12} src={arrow} alt="down-arrow"/>
              </>
            </Button>
          </a>
          : null
        }

        {mainInvoice.invoiceUrl
          ? <a href={mainInvoice.invoiceUrl} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="secondary">
              <>
                <span className={classes.download}>
                  {paymentMethod === 'DISSOCIATED_BANK_TRANSFER'
                    ? 'Relevé de provision'
                    : 'Télécharger la facture'
                  }
                </span>
                <img width={12} src={arrow} alt="down-arrow"/>
              </>
            </Button>
          </a>
          : null
        }
      </div>

      <div className={classes.buttonContainer}>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
        >
          {t('common:close')}
        </Button>
      </div>

    </div>
  );
};

export default OrderDetailsComponent;
